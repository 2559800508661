import { Component, OnInit, Input } from "@angular/core";

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
    @Input() public spin: Subscription;
    @Input() public spinIf: boolean = false;
    @Input() public position: string;

    constructor() {}
    ngOnInit() {} // eslint-disable-line
}