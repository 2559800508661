import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";

import { UserService } from 'app/services/user.service';
import { AnyBankComponent } from './modals/any-bank/any-bank.component';
import { BankBranchComponent } from './modals/bank-branch/bank-branch.component';
import { CrdbComponent } from './modals/CRDB/crdb.component';
import { KazangComponent } from './modals/kazang/kazang.component';
import { PayRwComponent } from './modals/pay-rw/pay-rw.component';
import { SpennAgentComponent } from './modals/spenn-agent/spenn-agent.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.scss']
})
export class TopupComponent implements OnInit {

  public pageTitle: string = "Top up";
  currencyId: number;

  constructor(private dialog: MatDialog, 
    private userService: UserService,
    private router: Router) { }

  ngOnInit(): void {
    let user = this.userService.getUserFromLocalStorage();
    this.currencyId = user.SelectedBusiness.Currency.CurrencyId;
  }

  showCrdbModal(type: string) {
    this.dialog.open(CrdbComponent, { 
      data: { 
        crdbType: type
      }
    });
  }

  showBankBranchModal(bankName: string) {
   this.dialog.open(BankBranchComponent, { 
     data : { 
       bankName: bankName
      } 
    });
  }

  showSpennAgentModal() {
    this.dialog.open(SpennAgentComponent, {});
  }

  showRwandaAnyBankModal() {
    this.dialog.open(AnyBankComponent, {});
  }

  showPayRwModal() {
    this.dialog.open(PayRwComponent, {});
  }

  showKazangModal() {
    this.dialog.open(KazangComponent, {});
  }

  showNfsModal(){
    this.router.navigate(["/Bank&MOMO"]);
  }
}





