import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Observable, throwError } from "rxjs";

import { Configuration } from "../app.constants";
import { AirtimeInformation } from 'app/models/AirtimeInformation';
import { GetRequestMoneyCommand, RequestMoneyList, RequestMoneyView, RequestReverseCommand, SBHandleRequestView, SbRequestMoneyList } from 'app/models/requestMoney';
import { ReceiverInfo } from '../models/receiverInfo';
import { Country } from "app/models/business";

@Injectable()
export class ExternalService implements OnInit {

    constructor(private http: HttpClient) { }

    ngOnInit() { }  // eslint-disable-line  

    checkPhoneAirtimeEligibility(businessId: number, phoneNumber: string, agentNumber: string): Observable<AirtimeInformation> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('phoneNumber', phoneNumber);
        httpParams = httpParams.append('agentNumber', agentNumber);
        httpParams = httpParams.append('businessId', businessId + '');
        return this.http.get(Configuration.AirtimeEligibilityCheck, { params: httpParams })
            .map(data => { return new AirtimeInformation(data) })
            .catch(err => { return throwError(err) });
    }

    getReceiverInfoForSpennTrx(businessId: number, phone: string, reward: string, agentNUmber: number, isSalary: boolean, paymentListType: number = 0): Observable<ReceiverInfo> {
        var httpParams = new HttpParams();
        httpParams = httpParams.append('phoneNumber', phone);
        httpParams = httpParams.append('rewardCode', reward);
        httpParams = httpParams.append('businessId', businessId + '');
        httpParams = httpParams.append('agentNUmber', agentNUmber + '');
        httpParams = httpParams.append('isSalary', isSalary + '');
        httpParams = httpParams.append('paymentListType', paymentListType);
        
        return this.http.get(Configuration.GetReceiverInfoForSpennTrx, { params: httpParams })
            .map(data => { return new ReceiverInfo(data) })
            .catch(err => { return throwError(err) });
    }

    public GetMoneyRequests(fromDate: Date, toDate: Date, page: number = 1, pageSize: number = 10): Observable<RequestMoneyList> {
        var command = new GetRequestMoneyCommand();
        command.FromDate = fromDate;
        command.ToDate = toDate;
        command.Page = page;
        command.PageSize = pageSize;
        command.Sort = null;

        return this.http.post(`${Configuration.GetAllRequests}`, command)
            .map(data => { return new RequestMoneyList(data); })
            .catch(err => { return throwError(err) });
    }

    public RequestReverse(RequestGuid: string): Observable<RequestMoneyView> {
        var command = new RequestReverseCommand();
        command.RequestMoneyGuid = RequestGuid;

        return this.http.post(`${Configuration.RequestReverse}`, command)
            .map(data => { return new RequestMoneyView(data); })
            .catch(err => { return throwError(err) });
    }

    public getCountries(): Observable<Country[]> {
        return this.http.get(Configuration.GetCountriesUri)
            .map((data: Country[]) => data)
            .catch(err => { return throwError(err) });
    }

    public createNewPaymentRequest(phoneNumber: string, amount: string, message: string): Observable<RequestMoneyView> {

        var requestData = {
            PhoneNumber: phoneNumber,
            Amount: amount,
            Message: message
        };

        return this.http.post(`${Configuration.NewPaymentRequest}`, requestData)
            .map(data => { return new RequestMoneyView(data); })
            .catch(err => { return throwError(err) });
    }

    public SbGetMoneyRequests(page: number = 1, pageSize: number = 10): Observable<SbRequestMoneyList> {
        var command = new GetRequestMoneyCommand();
        command.Page = page;
        command.PageSize = pageSize;
        command.Sort = null;

        return this.http.post(`${Configuration.SBWebGetAllRequests}`, command)
            .map(data => { return new SbRequestMoneyList(data); })
            .catch(err => { return throwError(err) });
    }

    public SbInitiatorRequestList(page: number = 1, pageSize: number = 10): Observable<SbRequestMoneyList> {
        var command = new GetRequestMoneyCommand();
        command.Page = page;
        command.PageSize = pageSize;
        command.Sort = null;

        return this.http.post(`${Configuration.SBWebInitiatorGetAllRequests}`, command)
            .map(data => { return new SbRequestMoneyList(data); })
            .catch(err => { return throwError(err) });
    }

    public SBRequestReverse(RequestGuid: string): Observable<RequestMoneyView> {
        var command = new RequestReverseCommand();
        command.RequestMoneyGuid = RequestGuid;

        return this.http.post(`${Configuration.SBWebRequestDecline}`, command)
            .map(data => { return new RequestMoneyView(data); })
            .catch(err => { return throwError(err) });
    }



    public SBRequestSendOtp(): Observable<any> {
        return this.http.get(Configuration.SBWebRequestSendOtp)
            .catch(err => { return throwError(err) });
    }


    public SBRequestApprove(reequestGuid: string, otp: string): Observable<SBHandleRequestView> {
        var command = {
            RequestMoneyGuid: reequestGuid,
            Otp: otp
        };
        return this.http.post(`${Configuration.SBWebRequestMoneyConfirm}`, command)
            .map(data => { return new SBHandleRequestView(data); })
            .catch(err => { return throwError(err) });
    }


    public SBInitiatorRequestCancel(reequestGuid: string): Observable<boolean> {
        var command = {
            RequestMoneyGuid: reequestGuid,
        };
        return this.http.post(`${Configuration.SBWebInitiatorCancelRequests}`, command)
            .map(data => { return Boolean(data); })
            .catch(err => { return throwError(err) });
    }





}