import { formatDate } from "@angular/common";

export class WithdrawRequest {
  Title: string;
  Amount: number;
  TransferFee: number;
  Message: string;
  Authorizer: string;
  CreateDate: Date;
  WithdrawRequestStatus: string;
  CurrencyCode: string;
  WithdrawRequestShortCode: string;
  WithdrawRequestGuid: string;
  OtherBankAccount: OtherBankAccount;

  constructor(data: any) {
    (this.Title = data?.title), (this.Message = data?.message);
    this.Amount = data?.amount;
    this.TransferFee = data?.transferFee;
    this.Authorizer = data?.authorizer;
    this.CreateDate = new Date(data?.createDate);
    this.WithdrawRequestGuid = data?.withdrawRequestGuid;
    this.WithdrawRequestShortCode = data?.withdrawRequestShortCode;
    this.WithdrawRequestStatus = data?.withdrawRequestStatusForBusiness; //WithdrawRequestStatus[data?.withdrawRequestStatusId];
    this.OtherBankAccount = new OtherBankAccount(data?.otherBankAccount);
  }
}

export class WithdrawRequestList {
  public List: Array<WithdrawRequest>;
  public Total: number;

  public constructor(data: any = {}) {
    this.List = new Array<WithdrawRequest>();
    this.Total = data.total || 0;
    if (data.data) {
      data.data.forEach((item) => {
        this.List.push(new WithdrawRequest(item));
      });
    }
  }
  public sortByCreateDate() {
    this.List = this.List.sort((x, y) => {
      if (new Date(x.CreateDate) < new Date(y.CreateDate)) {
        return 1;
      } else return -1;
    });
  }
}

export class BankTransferRequest {
  BankName: string;
  AccountName: string;
  AccountNumber: number;
  Amount: number;
  BankTransferFees: number;
  TotalAmount: number;
  ReferenceNumber: string;
  CreateDate: string;
  Message: string;

  constructor(data: any) {
    (this.BankName = data?.bankName), (this.AccountName = data?.accountName);
    this.AccountNumber = data?.accountNumber;
    this.Amount = data?.amount;
    this.BankTransferFees = data?.bankTransferFees;
    this.TotalAmount = data?.totalAmount;
    this.ReferenceNumber = data?.referenceNumber;
    this.CreateDate = data["createDate"]
      ? formatDate(data["createDate"], "short", "en-US")
      : "";
    this.Message = data?.message;
  }
}

export class BankTransferAccount {
  BankName: string;
  AccountName: string;
  AccountNumber: number;
  BankLetterFileName: string;
  TransferFee: number;
  OtherBankAccountGuid: string;

  constructor(data: any) {
    (this.BankName = data?.otherBankName),
      (this.AccountName = data?.accountName);
    this.AccountNumber = data?.accountNumber;
    this.TransferFee = data?.transferFee;
    this.BankLetterFileName = data?.bankLetterFileName;
    this.BankLetterFileName = data?.bankLetterFileName;
    this.OtherBankAccountGuid = data?.otherBankAccountGuid;
  }
}

export class OtherBankDetail {
  OtherBankId: number;
  OtherBankCurrencyId: number;
  OtherBankName: string;
  DefaultFee: number;

  constructor(data: any) {
    (this.OtherBankId = data?.otherBankId),
      (this.OtherBankCurrencyId = data?.currencyId);
    this.OtherBankName = data?.name;
    this.DefaultFee = data?.defaultFee;
  }
}

export class GetWithdrawRequestCommand {
  public BusinessAccountGuid: string;
  public StartDate: Date;
  public EndDate: Date;
  public Page: number;
  public PageSize: number;
  public Sort: WithdrawRequestSortDescriptor[];

  public constructor() {
    this.Sort = new Array<WithdrawRequestSortDescriptor>();
    this.Page = 1;
    this.PageSize = 10;
  }
}

export class WithdrawRequestSortDescriptor {
  public Field: string;
  public Dir: string;

  public constructor(field: string = "", dir: string = "") {
    this.Field = field;
    this.Dir = dir;
  }
}

export class OtherBankAccount {
  BankName: string;
  AccountName: string;
  AccountNumber: number;
  TransferFee: number;

  constructor(data: any) {
    if (data) {
      (this.BankName = data?.bankName), (this.AccountName = data?.accountName);
      this.AccountNumber = data?.accountNumber;
      this.TransferFee = data?.transferFee;
    }
  }
}
