<div fxLayout="row" fxLayout.xs="row" fxFlexFill>
    <div fxFlex="60" fxFlexOffset="0" fxFlex.xs="50" fxLayout="column" fxLayoutGap="0" fxLayoutAlign="center start" id="grad1">
        <div fxLayoutAlign="center" >
            <img class="img-logo" src="assets/images/spenn_flow_logo.png">
        </div>
        
    </div>
    <div fxFlex="50" fxFlexOffset="5" fxFlex.xs="50" fxLayout="column" fxLayoutGap="5" fxLayoutAlign="center start">

        <div fxFlex="40" fxLayoutAlign="center end" fxLayout="row">
            <h5 class="heading">Access Denied</h5>
        </div>
        
        <div fxFlex="20" fxLayoutAlign="center start" fxLayout="row">
            <p class="ele">
                This Account has been locked please contact our customer service team for more details.
            </p>
        </div>

        <div fxFlex="20" fxLayoutAlign="center start" fxLayout="row">
            <p class="ele1">
                <span class="ele2">
                    Contact Us:
                </span><br>
                RW: +250 781 288 255, &nbsp; &nbsp; TZ: +255 758 909 999 &nbsp; & &nbsp; ZM: +260 975 944 968
            </p>
        </div>

        <div fxFlex="20"  fxLayoutAlign="center end" fxLayout="row">
            <button mat-flat-button class="btn1" (click)="logout()">Go Back</button>
        </div>
    </div>
</div>
