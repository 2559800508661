import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Observable, throwError } from 'rxjs';

import { Configuration } from 'app/app.constants';
import { ExternalService } from 'app/services/external.service';
import { UserService } from 'app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class TopupService {

  constructor(private http: HttpClient, 
    private santizer: DomSanitizer, 
    private externalService: ExternalService,
    private userService: UserService) { }

  getQrCodeImageBase64(): Observable<SafeUrl> {
    return this.http.get(Configuration.GetUserQrCode, { responseType: 'text'})
      .map(data => { 
        return this.santizer.bypassSecurityTrustUrl(data.toString());
      })
      .catch(err => throwError(err));
  }

  getRewardCode(): Observable<string> {
    var userBusiness = this.userService.getUserFromLocalStorage().SelectedBusiness;
    return this.externalService.getReceiverInfoForSpennTrx(userBusiness.Id, userBusiness.PhoneNumber, '', 0, false)
      .map(result => result.Customer.RewardCode);
  }


}
