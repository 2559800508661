import { Token } from "./token";
import { Business } from "./business";

export class User {
    Email: string;
    TwoFaPhoneNumber: string;
    Token: Token;
    BusinessList: Business[];
    SelectedBusiness: Business;
    IsRequiredPasswordChange: boolean;

    public constructor(data: any = {}) {
        this.Email = data.email || '';
        this.TwoFaPhoneNumber = data.twoFaPhoneNumber || '';
        this.BusinessList = [];
        if (data.connectedBusiness) {
            data.connectedBusiness.forEach(business => {
                this.BusinessList.push(new Business(business));
            });
        }
        this.SelectedBusiness = this.BusinessList[0] || null;
        this.IsRequiredPasswordChange = data.isRequiredPasswordChange == null ? true : data.isRequiredPasswordChange;
    }
}

export class SpennUser {
    Name: string;
    Phone: string;
    RewardCode: string;
    CustomerGuid: string;
}

export class ChangePasswordCommand {
    CurrentPassword: string;
    NewPassword: string;
    ConfirmPassword: string;

    public constructor() {
        this.CurrentPassword = '';
        this.NewPassword = '';
        this.ConfirmPassword = '';
    }

    public validate(): void {
        var strongPasswordPattern = new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])');
        if (this.CurrentPassword == '') {
            throw new Error("Current password is required");
        }
        else if (this.NewPassword.length < 8 || this.NewPassword.length > 64) {
            throw new Error("New password must be between 8 to 64 characters");
        }
        else if (this.CurrentPassword === this.NewPassword) {
            throw new Error("New password is same as current password");
        }
        else if (this.ConfirmPassword !== this.NewPassword) {
            throw new Error("Confirm password does not match with new password");
        }
        else if (!strongPasswordPattern.test(this.NewPassword)) {
            throw new Error("Password must contain at least 1 digit, 1 lowercase and 1 uppercase character");
        }
    }
}


export class ChangePhoneNumberCommand {

    public NewPhoneNumber: string;
    public CountryId: number;
    public OTP: string;

    public constructor(newPhoneNumber: string, countryId: number, otp: string) {
        this.NewPhoneNumber = newPhoneNumber;
        this.CountryId = countryId;
        this.OTP = otp;
    }
}

export class SBWebQrCodeResponse {
    public AccountName: string;
    public PhoneNumber: string;
    public QrText: string;

    public constructor(data: any = {}) {
        this.AccountName = data.accountName || '';
        this.PhoneNumber = data.phoneNumber || '';
        this.QrText = data.qrText;

    }
}