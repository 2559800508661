import { environment } from '../environments/environment';

export class Configuration {
    public static readonly ApiRoot: string = `${environment.server.SpennBusinessApi.domain}`;

    public static readonly GetUserInfo: string = Configuration.ApiRoot + "/api/Users/info";

    public static readonly GetUserQrCode: string = Configuration.ApiRoot + "/api/Users/getqrcode";

    public static readonly PaymentListUri: string = Configuration.ApiRoot + "/api/Payments";

    public static readonly ClonePaymentListUri: string = Configuration.ApiRoot + "/api/Payments/clone";

    public static readonly GetReceiverInfoForSpennTrx: string = Configuration.ApiRoot + "/api/External/getreceiverinfo";

    public static readonly AirtimeEligibilityCheck: string = Configuration.ApiRoot + "/api/External/checkairtimeavailability";

    public static readonly GetAccountBalanceUri: string = Configuration.ApiRoot + "/api/External/accountbalance";
    public static readonly GetAccountBalanceForSavingsUri: string = Configuration.ApiRoot + "/api/External/accountbalanceforsavings";
    public static readonly GetAllAccountsBalanceUri: string = Configuration.ApiRoot + "/api/External/getallaccountsbalance";
    public static readonly CreateSavingsAccountUrl: string = Configuration.ApiRoot + "/api/External/createsavingsaccounts";
    public static readonly SendFundOwnAccountUrl: string = Configuration.ApiRoot + "/api/External/ownaccountfundsend";

    public static readonly GetCountriesUri: string = Configuration.ApiRoot + "/api/External/getcountries";

    public static readonly AllPaymentsUri: string = Configuration.ApiRoot + "/api/Payments/business";

    public static readonly BulkPaymentUri: string = Configuration.ApiRoot + "/api/Payments/bulkpayment";

    public static readonly LogoutUri: string = Configuration.ApiRoot + "/api/Logout";

    public static readonly ChangePasswordUri: string = Configuration.ApiRoot + "/api/Users/password";

    public static readonly InitiateChangeNumberUri: string = Configuration.ApiRoot + "/api/Users/changePhoneNumber/initiate";
    public static readonly ConfirmChangeNumberUri: string = Configuration.ApiRoot + "/api/Users/changePhoneNumber/confirm";
    public static readonly SendOtp: string = Configuration.ApiRoot + "/api/Users/sendotp";

    public static readonly GetAllRequests: string = Configuration.ApiRoot + "/api/Partner/transaction/request/all";

    public static readonly RequestReverse: string = Configuration.ApiRoot + "/api/Partner/transaction/request/reverse";
    public static readonly ApiKeysUri: string = Configuration.ApiRoot + "/api/ApiKeys";

    public static readonly ApiKeyPermissionGroupsUri: string = Configuration.ApiKeysUri + "/permissiongroups";

    /* Bank Push Pull Constants */
    public static readonly BankPushPullUri: string = Configuration.ApiRoot + "/api/BankPushPull";
    public static readonly BankPushPullCustomerBankAccountsUri: string = Configuration.BankPushPullUri + "/GetCustomerBankAccounts"
    public static readonly BankPushPullCustomerBankAccountInfoUri: string = Configuration.BankPushPullUri + "/GetCustomerBankAccountInfo"
    public static readonly WithdrawMoneyToAnyInmAccountUri: string = Configuration.BankPushPullUri + "/SendToInmBankAccount"
    public static readonly BankPushAmount: string = Configuration.BankPushPullUri + "/GetPushAmount"

    public static readonly PaymentSlipDetailsUri: string = Configuration.ApiRoot + "/api/external/getpaymentslipdetails";
    public static readonly PaymentSlipPdfUri: string = Configuration.ApiRoot + "/api/external/getpaymentslip";

    public static readonly GetWithdrawList: string = Configuration.ApiRoot + "/api/withdraw/all"
    public static readonly CreateWithdrawRequest: string = Configuration.ApiRoot + "/api/withdraw/request"
    public static readonly CreateOtherBankWithdrawRequest: string = Configuration.ApiRoot + "/api/withdraw/otherbankrequest"
    public static readonly CheckWithdrawRequestPending: string = Configuration.ApiRoot + "/api/withdraw/request/pending"

    public static readonly AccountStatementUri: string = Configuration.ApiRoot + "/api/AccountStatement";
    public static readonly GenerateAccountStatement: string = Configuration.AccountStatementUri + "/generate";
    public static readonly DownloadAccountStatementPdf: string = Configuration.AccountStatementUri + "/download";
    public static readonly DownloadAccountStatementCsv: string = Configuration.AccountStatementUri + "/DownloadCsv";

    public static readonly NfsUri: string = Configuration.ApiRoot + "/api/Nfs";
    public static readonly NfsCashInLookup: string = Configuration.NfsUri + "/Lookup";
    public static readonly NfsCashInInitiate: string = Configuration.NfsUri + "/Initiate";
    public static readonly NfsCashInSendOtp: string = Configuration.NfsUri + "/SendOtp";
    public static readonly NfsCashInConfirm: string = Configuration.NfsUri + "/Confirm";
    public static readonly NfsPaymentListUri: string = Configuration.NfsUri;
    public static readonly NfsClonePaymentListUri: string = Configuration.NfsUri + "/clone";
    public static readonly NfsPaymentSlipDetailsUri: string = Configuration.NfsUri + "/getpaymentslipdetails";
    public static readonly NfsPaymentSliPdfUri: string = Configuration.NfsUri + "/getpaymentslippdf";

    public static readonly BillPaymentUri: string = Configuration.ApiRoot + "/api/BillPayment";
    public static readonly BillPaymentLookup: string = Configuration.BillPaymentUri + "/BillPaymentProvider";
    public static readonly BillPaymentCalculateFees: string = Configuration.BillPaymentUri + "/CalculateFees";
    public static readonly BillPaymentProcess: string = Configuration.BillPaymentUri + "/Process";
    public static readonly NumofOfItemsInDownload: string = `${environment.maxNumberOfItemForPdfButton}`

    /*  Payment Request */
    public static readonly NewPaymentRequest: string = Configuration.ApiRoot + "/api/BusinessPaymentRequest/transaction/request";
    public static readonly SBWebGetAllRequests: string = Configuration.ApiRoot + "/api/BusinessPaymentRequest/transaction/request/all";
    public static readonly SBWebInitiatorGetAllRequests: string = Configuration.ApiRoot + "/api/BusinessPaymentRequest/transaction/request/initiator/all";
    public static readonly SBWebRequestDecline: string = Configuration.ApiRoot + "/api/BusinessPaymentRequest/transaction/request/decline";
    public static readonly SBWebRequestSendOtp: string = Configuration.ApiRoot + "/api/BusinessPaymentRequest/transaction/request/sendOtp";
    public static readonly SBWebRequestMoneyConfirm: string = Configuration.ApiRoot + "/api/BusinessPaymentRequest/transaction/request/confirm";
    public static readonly SBWebInitiatorCancelRequests: string = Configuration.ApiRoot + "/api/BusinessPaymentRequest/transaction/request/initiator/cancel";

    /*  Qr Code */

    public static readonly SBWebGetQrInformation: string = Configuration.ApiRoot + "/api/BusinessQrCode";
    public static readonly SBWebQrPdfDownload: string = Configuration.ApiRoot + "/api/BusinessQrCode/pdf/download";

    /* Money Trnasfer */
    public static readonly MoneyTransferBaseUrl: string = `${Configuration.ApiRoot}/api/MoneyTransfer`;
    public static readonly MoneyTransferInitialLookup: string= `${Configuration.MoneyTransferBaseUrl}/InitialLookup`;
    public static readonly MoneyTransferLookup: string= `${Configuration.MoneyTransferBaseUrl}/Lookup`;
    public static readonly MoneyTransferCalculateFees: string= `${Configuration.MoneyTransferBaseUrl}/CalculateFees`;
    public static readonly MoneyTransferProcess: string= `${Configuration.MoneyTransferBaseUrl}/Process`;
    public static readonly MoneyTransferAddBulkPayment: string= `${Configuration.MoneyTransferBaseUrl}/AddMoneyTransferPaymentList`;
    public static readonly IFTLookUp: string = `${Configuration.MoneyTransferBaseUrl}/IFTLookup`;
    public static readonly MapVirtualAccount: string = `${Configuration.MoneyTransferBaseUrl}/Mapvirtualaccount`;
    public static readonly MoneyTransferGetPaymentDetail: string = `${Configuration.MoneyTransferBaseUrl}/GetPaymentDetail`;
    public static readonly MoneyTransferClonePaymentListUri: string = Configuration.MoneyTransferBaseUrl + "/clone";

    public static readonly AcceptAgreement: string = `${Configuration.ApiRoot}/api/ServiceAgreement/AcceptAgreement`;
    public static readonly DeclineAgreement: string = `${Configuration.ApiRoot}/api/ServiceAgreement/DeclineAgreement`;
    public static readonly IsEligibleForAgreement: string = `${Configuration.ApiRoot}/api/ServiceAgreement/IsEligibleForAgreement`;
    public static readonly DownloadAgreement: string = `${Configuration.ApiRoot}/api/ServiceAgreement/Download`;
}