import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IFTProviderView, IFTVirtualAccountMappingDto } from 'app/models/ift';
import { IftService } from 'app/services/ift.service';
import { NotificationService } from 'app/services/notification.service';
import { Subscription } from 'rxjs';

declare var CleverPush: any;

@Component({
  selector: 'app-nfs',
  templateUrl: './bankmomo.component.html',
  styleUrls: ['./bankmomo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankMomoComponent implements OnInit {
  instructionsArray: string[];
  public title: string = 'Top up';
  phoneNumber:string;
  busy: Subscription;
  lookup: IFTProviderView;
  virtualAccount: IFTVirtualAccountMappingDto;
  cleverpush: any;
  constructor(
    private iftService: IftService,
    private notif: NotificationService
  ) { }

  ngOnInit(): void {
    this.busy = this.iftService.GetIFTLookUp()
      .subscribe(
        (lookup) => {
          this.lookup = lookup;
          if(lookup.IsVirtualAccountMapped){
            this.mapVirtualAccount();
          }
        },
        (error) => {this.notif.error(error)}
        );
  }

  mapVirtualAccount(){
    this.busy = this.iftService.MapVirtualAccount().subscribe(
      (result) => {this.virtualAccount = result;
        if(!this.lookup.IsVirtualAccountMapped){
        this.notif.success("Account mapped successfully.")
        this.iftService.GetIFTLookUp()
        .subscribe(
          (lookup) => { this.lookup = lookup;},
          (error) => { this.notif.error(error)}
          )}},
      (error) => {this.notif.error(error)}
      );
  }

  showbanner(data: string){
    this.cleverpush = CleverPush.showWebBannerById(data);
    console.log("banner", this.cleverpush);
  }

}
