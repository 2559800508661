import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Rx';
import { Subject } from "rxjs/Subject";

import { environment } from '../../environments/environment';
import { SBWebQrCodeResponse, User } from '../models/user';
import { Token } from '../models/token';


import { UserService } from "./user.service";
import { Configuration } from "../app.constants";
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public token: Token;
  private jwtHelper: JwtHelperService = new JwtHelperService();
  refreshTokenInProgress = false;
  private isRenewTokenCalled = false;
  private currentUserSource = new BehaviorSubject<User>(null);
  private currentUserQrInfo = new BehaviorSubject<SBWebQrCodeResponse>(null);

  currentUser$ = this.currentUserSource.asObservable();
  currentUserQr$ = this.currentUserQrInfo.asObservable();
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  private refreshTokenCall: Observable<Response>;

  // receive token
  private tokenReceivedSource = new Subject();
  tokenRecivedStateChanged$ = this.tokenReceivedSource.asObservable();
  changeTokenReceivedState() {
    this.tokenReceivedSource.next();
  }
  // receive token

  // load permission
  private permissionLoadedSource = new ReplaySubject<void>(1);
  permissionStateChanged$ = this.permissionLoadedSource.asObservable();
  changePermissionState() {
    this.permissionLoadedSource.next();
  }
  // load permission

  readonly tokenEndPoint = `${environment.server.IdentityServer.domain}/token`;

  constructor(private router: Router, private http: HttpClient, private userService: UserService, private notif: NotificationService) {
    this.checkAuthFromLocalStorage();
  }

  login() {
    //console.log("Redirecting to login page");
    const pkceChallenge = require("pkce-challenge").default;
    const challenge = pkceChallenge(44);

    localStorage.setItem('code_verifier', challenge.code_verifier);
    window.location.href = `${environment.server.IdentityServer.domain}/OAuth/Authorize?client_id=${environment.bbAuth.clientID}&redirect_uri=${environment.bbAuth.redirectUrl}&audience=${environment.bbAuth.audience}&response_type=code&code_verifier=${challenge.code_verifier}&code_challenge=${challenge.code_challenge}&code_challenge_method=S256`;
    //  window.location.href = `${environment.server.IdentityServer.domain}/OAuth/Authorize?client_id=${environment.bbAuth.clientID}&redirect_uri=${environment.bbAuth.redirectUrl}&audience=${environment.bbAuth.audience}&response_type=code`;
  }

  public logout = (): void => {
    //console.log("logout....");
    if (this.isAuthenticated()) {
      this.http.post(Configuration.LogoutUri, {})
        .catch(err => {
          this.clearlocalstorageItemsAndRedirectToLogoutPage();
          return throwError(err);
        }).subscribe(
          d => {
            this.clearlocalstorageItemsAndRedirectToLogoutPage();
          }
        );
    }
    else {
      this.clearlocalstorageItemsAndRedirectToLogoutPage();
    }

  }

  private clearlocalstorageItemsAndRedirectToLogoutPage = (): void => {
    localStorage.clear();
    this.token = null;
    this.currentUserSource.next(null);
    this.currentUserQrInfo.next(null);

    window.location.href = `${environment.server.IdentityServer.domain}/api/Account/logout?redirect_uri=${environment.bbAuth.redirectUrl}`;
  }

  public isTokenExpired = (): boolean => {
    return this.jwtHelper.isTokenExpired(this.token.accessToken);
  }

  public isAuthenticated = (): boolean => {
    if (!this.token) return false;
    return !this.jwtHelper.isTokenExpired(this.token.accessToken);
  }

  authCodeFromQp: any
  public checkAuth = (): Observable<any> => {
    var authcodefromLp = this.getQueryParameterByName("codeCheckLp");
    if (authcodefromLp) {
      this.login();
      return;
    }
    var authCodeFromQp = this.getQueryParameterByName("code");
    this.authCodeFromQp = authCodeFromQp;
    if (authCodeFromQp && localStorage.getItem('code_verifier') !== null) {
      let command = new URLSearchParams();
      command.append('grant_type', "authorization_code");
      command.append('client_id', environment.bbAuth.clientID);
      command.append('client_secret', environment.bbAuth.clientSecret);
      command.append('audience', environment.bbAuth.audience);
      command.append('code', authCodeFromQp);
      command.append('redirect_uri', environment.bbAuth.redirectUrl);
      command.append('code_verifier', localStorage.getItem('code_verifier'));
      localStorage.removeItem('code_verifier');
      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      };
      var accessTokenCall = this.http
        .post(this.tokenEndPoint, command.toString(), options)
        .flatMap((token: Response) => {
          this.token = new Token(token);
          this.updateTokenLocalStorage();
          this.updateCurrentUser();
          this.updateQrCode();
          this.changeTokenReceivedState();
          return Observable.of(token);
        });
      return accessTokenCall;

    } else {
      return this.checkAuthFromLocalStorage2();
    }
  }

  public isUserInPermissionGroup = (groupName: string): boolean => {
    var permissionGroups = JSON.parse(localStorage.bb_token).permissionGroups;
    if (!permissionGroups) {
      return false;
    }
    return permissionGroups.indexOf(groupName) >= 0;
  }

  public checkAuthFromLocalStorage2 = (): Observable<Token> => {
    var tokenString = localStorage.getItem('bb_token');
    if (tokenString) {
      this.token = JSON.parse(tokenString);
      if (this.token.permissionGroups) {
        this.changePermissionState();
      }

      var userString = localStorage.getItem('bb_user');
      if (userString) this.currentUserSource.next(JSON.parse(userString));

      var userQrString = localStorage.getItem('bb_user_qr');
      if (userQrString) this.currentUserQrInfo.next(JSON.parse(userQrString));
      //else this.updateCurrentUser();

      return Observable.of(this.token);
    }
    return Observable.of(null);
  }

  public checkAuthFromLocalStorage = (): void => {
    var tokenString = localStorage.getItem('bb_token');
    if (tokenString) {
      this.token = JSON.parse(tokenString);
      if (this.token.permissionGroups) {
        this.changePermissionState();
      }
    }

    var userString = localStorage.getItem('bb_user');
    if (userString) this.currentUserSource.next(JSON.parse(userString));

    var userQrString = localStorage.getItem('bb_user_qr');
    if (userQrString) this.currentUserQrInfo.next(JSON.parse(userQrString));
    //else if(tokenString) this.updateCurrentUser();
  }

  private observableHandleError = (error: any) => {
    console.log(error);
    this.logout();
    return Observable.throw(error);
  }

  public getQueryParameterByName = (name: string): string => {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");

    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  refreshTokenAsync() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.renewToken()
        .do(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        });
    }
  }


  public renewToken = (): Observable<any> => {

    if (this.isRenewTokenCalled) {
      return this.refreshTokenCall;
    }

    let refreshTokenCommand = new URLSearchParams();
    refreshTokenCommand.append('grant_type', "refresh_token");
    refreshTokenCommand.append('client_id', environment.bbAuth.clientID);
    refreshTokenCommand.append('client_secret', environment.bbAuth.clientSecret);
    refreshTokenCommand.append('audience', environment.bbAuth.audience);
    refreshTokenCommand.append('refresh_token', this.token.refreshToken);
    //console.log("renew token with :" + refreshTokenCommand.toString());
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    this.refreshTokenCall = this.http.post(this.tokenEndPoint, refreshTokenCommand.toString(), options).map((token: Response) => {
      //console.log("Got response for access token");
      this.token = new Token(token);
      this.updateTokenLocalStorage();
      this.changeTokenReceivedState();
      return token;
    }).catch(this.observableHandleError)
      .finally(() => {
        this.isRenewTokenCalled = false;
      });

    this.isRenewTokenCalled = true;
    return this.refreshTokenCall;
  }

  public updateCurrentUser(): void {
    this.userService.getUserInfo().subscribe(
      user => {
        this.currentUserSource.next(user);
        this.updateCurrentUserLocalStorage();
      }
    );
  }

  public updateQrCode(): void {
    this.userService.GetQrCode().subscribe(
      res => {
        this.currentUserQrInfo.next(res);
        this.updateCurrentUserQrCodeLocalStorage();
      }
    );
  }



  public updateCurrentUserPasswordChangeStatus() {
    var user = this.getCurrentUserValue();
    user.IsRequiredPasswordChange = false;
    this.currentUserSource.next(user);
    this.updateCurrentUserLocalStorage();
  }

  public updateCurrentUserWithValue(user: User): void {
    this.currentUserSource.next(user);
    this.updateCurrentUserLocalStorage();
  }

  public getCurrentUserValue(): User {

    return this.currentUserSource.getValue();
  }

  // This method will check user is new or have already.

  public isRequiredPasswordChange(): any {
    if (this.getCurrentUserValue() == null) return null;
    return this.getCurrentUserValue().IsRequiredPasswordChange;
  }



  public isPasswordChange(): boolean {
    var req = this.isRequiredPasswordChange();
    if (req == null) {
      this.router.navigate(['']);
    } else if (req == true) {
      this.notif.error("You need to change password first");
      this.router.navigate(['profile']);
      return true;
    }
    return false;
  }

  // This method will check user is new then redirect to change password screen, otherwise on payment page

  public islogedIn(checkpasswordChange): any {

    return this.checkAuth().flatMap(() => {
      if (!this.token) {
        this.router.navigate(['/Landing-page']);
        return Observable.of(false);
      }

      if (this.token && this.isTokenExpired()) {
        return this.refreshTokenAsync()
          .map(() => {
            let isAuth = this.isAuthenticated();
            // AuthGuard check Require PasswordChange
            if (checkpasswordChange) {
              let passwordChange = this.isPasswordChange();
              return Observable.of(isAuth && !passwordChange);
            } else {
              return Observable.of(isAuth);
            }
          })
          .catch(() => {
            this.logout();
            return Observable.empty();
          });
      }
      let isAuth = this.isAuthenticated();
      // AuthGuard check Require PasswordChange
      if (checkpasswordChange) {
        let passwordChange = this.isRequiredPasswordChange();
        return Observable.of(isAuth && !passwordChange);
      } else {
        return Observable.of(isAuth);
      }
    });
  }

  private updateTokenLocalStorage(): void {
    localStorage.setItem('bb_token', JSON.stringify(this.token));
  }

  private removeTokenLocalStorage(): void {
    localStorage.removeItem('bb_token');
  }

  private updateCurrentUserLocalStorage(): void {
    localStorage.setItem('bb_user', JSON.stringify(this.currentUserSource.getValue()));
  }

  private updateCurrentUserQrCodeLocalStorage(): void {
    localStorage.setItem('bb_user_qr', JSON.stringify(this.currentUserQrInfo.getValue()));
  }

  private removeCurrentUserLocalStorage(): void {
    localStorage.removeItem('bb_user');
  }

  getUserPermissions(): Observable<string[]> {
    const userGroupsUrl = `${environment.server.SpennBusinessApi.domain}/api/Users/permissiongroups`;
    return this.http.get<string[]>(userGroupsUrl)
      .map(response => response)
      .catch(err => {
        return Observable.throwError(err);
    });
  }

  setPermissionToToken(permissions: string[]): void {
    this.token.permissionGroups = permissions;
    this.updateTokenLocalStorage();
    this.changePermissionState();
  }
}
