import { Component, Input } from '@angular/core';

@Component({
  selector: 'topup-insturctions',  // eslint-disable-line
  templateUrl: './insturctions.component.html',
  styleUrls: ['./insturctions.component.scss']
})
export class InsturctionsComponent {

  @Input() instructions: string[];
  
  constructor() { }

}
