import { formatDate } from '@angular/common';

export enum AccountType {
    None = 0,
    Spending,
    Savings,
    Business
}



export class Transaction {
    public Amount: number;
    public Currency: string;
    public Direction: string;
    public Receiver: CustomerBasicView;
    public Sender: CustomerBasicView;
    public Status: string;
    public CreateDate: string;
    public TransactionId: string;
    public TransactionType: string;
    public Message: string;

    public constructor(data: any = {}) {
        this.Amount = data.amount || 0;
        this.Currency = data.currency || '';
        this.Direction = data.direction || '';
        this.Status = data.status || '';
        this.TransactionId = data.transactionId || '';
        this.TransactionType = data.transactionType || '';
        this.Message = data.message || '';
        this.CreateDate = data['timestampCreated'] ? formatDate(data['timestampCreated'], 'medium', 'en-US') : '';
        this.Receiver = new CustomerBasicView(data.receiver);
        this.Sender = new CustomerBasicView(data.sender);
    }
}

export class TransactionList {
    public List: Array<Transaction>;
    public Total: number;

    public constructor(data: any = {}) {
        this.List = new Array<Transaction>();
        this.Total = data.total || 0;
        if (data.data) {
            data.data.forEach(item => {
                this.List.push(new Transaction(item));
            });
        }
    }
}

export class SavingsTransaction {
    public Amount: number;
    public Currency: string;
    public TimestampCreated: string;
    public Status: string;
    public IsSavingsInTransaction: boolean;

    public constructor(data: any = {}) {
        this.Amount = data.amount || 0;
        this.Currency = data.currency || '';
        this.Status = data.status || '';
        this.TimestampCreated = data['timestampCreated'] ? formatDate(data['timestampCreated'], 'medium', 'en-US') : '';
        this.IsSavingsInTransaction = data.isSavingsInTransaction
    }
}

export class SavingsTransactionList {
    public List: Array<SavingsTransaction>;
    public Total: number;

    public constructor(data: any = {}) {
        this.List = new Array<SavingsTransaction>();
        this.Total = data.total || 0;
        if (data.data) {
            data.data.forEach(item => {
                this.List.push(new SavingsTransaction(item));
            });
        }
    }
}

export class CustomerBasicView {
    public Name: string;
    public AccountType: AccountType;
    public CustomerGuid: string;
    public Picture: string;

    public constructor(data: any = {}) {
        this.Name = data.name || '';
        this.CustomerGuid = data.customerGuid || '';
        this.Picture = data.picture || '';
        this.AccountType = data.accountType == null ? AccountType.None : AccountType[data.accountType as string];
    }
}

export class GetTransactionCommand {
    public AccountGuid: string;
    public Page: number;
    public PageSize: number;
    public Sort: TransactionSortDescriptor[];

    public constructor() {
        this.Sort = new Array<TransactionSortDescriptor>();
        this.Page = 1;
        this.PageSize = 10;
    }
}

export class TransactionSortDescriptor {
    public Field: string;
    public Dir: string;

    public constructor(field: string = '', dir: string = '') {
        this.Field = field;
        this.Dir = dir;
    }
}

/* new dashboard changes added here */

export class GetTransactionListCommand {
    public AccountGuid: string;
    public FromDate: Date
    public ToDate: Date

    public constructor() {

    }
}


export class TransactionGroupByDateList {
    public List: Array<TransactionGroupByDate>;

    public constructor(data: any = {}) {
        this.List = new Array<TransactionGroupByDate>();
        if (data) {
            data.forEach(item => {
                this.List.push(new TransactionGroupByDate(item));
            });
        }
    }
}

export class TransactionGroupByDate {
    public TStamp: string;
    public MoneyIn: number;
    public MoneyOut: number;

    public constructor(data: any = {}) {
        this.TStamp = data.tStamp || '';
        this.MoneyIn = data.moneyIn || 0;
        this.MoneyOut = data.moneyOut || 0;
    }
}

export class TransactionGroupByTransactionTypeList {
    public List: Array<TransactionGroupByTransactionType>;

    public constructor(data: any = {}) {
        this.List = new Array<TransactionGroupByTransactionType>();
        if (data) {
            data.forEach(item => {
                this.List.push(new TransactionGroupByTransactionType(item));
            });
        }
    }
}

export class TransactionGroupByTransactionType {
    public TransactionType: string;
    public MoneyOut: number;
    public Percentage: number;

    public constructor(data: any = {}) {
        this.TransactionType = data.transactionType || '';
        this.MoneyOut = data.moneyOut || 0;
        this.Percentage = data.percentage || 0;
    }
}