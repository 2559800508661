import { Injectable } from '@angular/core';

import { BehaviorSubject } from "rxjs";

import { FailedPaymentListItem } from 'app/models/paymentList';
import { NfsFailedPaymentListItem } from 'app/models/nfs';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  _failedItemSubscription = new BehaviorSubject<Array<FailedPaymentListItem>>(null);
  _nfsfailedItemSubscription = new BehaviorSubject<Array<NfsFailedPaymentListItem>>(null);
  failedItems: Array<FailedPaymentListItem> = [];
  nfsFailedItems: Array<NfsFailedPaymentListItem> = [];

  addFailedItem(failedItem: FailedPaymentListItem) {
    this.failedItems.push(failedItem);
    this._failedItemSubscription.next(Object.assign([], this.failedItems));
  }

  addNfsFailedItem(failedItem: NfsFailedPaymentListItem) {

    this.nfsFailedItems.push(failedItem);
    this._nfsfailedItemSubscription.next(Object.assign([], this.nfsFailedItems));
  }

  clearNFsFaildItem() {
    this.nfsFailedItems = [];
    this._nfsfailedItemSubscription.unsubscribe;

  }
}
