import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Observable, throwError } from "rxjs";

import { PaymentList, PaymentListStatus, PaymentListDetails, PaymentListItem, PaymentListItemCommand, PaymentListCollection, PaymentSlipPdfView, SalaryPaymentSlipDetailsForBusinessView, PaymentListItemShort, BulkPaymentListItemsCommand, BulkAddPaymentListItemsResponse, FailedReceiverInfoView, PaymentListType, PaymentTypeViewObj } from "../models/paymentList";
import { Configuration } from "../app.constants";
import { CustomerType } from "app/models/enums/customerType";

@Injectable()
export class PaymentListService {
    constructor(private http: HttpClient) {

    }

    public getAllPayments(businessId: number, status: PaymentListStatus, page: number = 1, pageSize: number = 10, isDashboardPage: boolean = false, fromDate: any = null, endDate: any = null,): Observable<PaymentListCollection> {
        let statusParameter = (status == null) ? 'all' : status;
        var params = new HttpParams();
        if (fromDate && endDate) {
            params = params.set('fromDate', fromDate);
            params = params.set('toDate', endDate);
        }

        params = params.set('page', page.toString());
        params = params.set('pageSize', pageSize.toString());
        params = params.set('isDashboardPage', isDashboardPage);
        return this.http.get(Configuration.AllPaymentsUri + '/' + businessId + '/status/' + statusParameter, { params: params })
            .map(data => { return new PaymentListCollection(data) })
            .catch(err => { return throwError(err) });
    }

    public getPaymentList(paymentListId: number): Observable<PaymentListDetails> {
        return this.http.get(Configuration.PaymentListUri + '/' + paymentListId)
            .map(data => { return new PaymentListDetails(data) })
            .catch(err => { return throwError(err) });
    }

    public GetBulkPaymentType(id: number): Observable<PaymentTypeViewObj> {
        return this.http.get(Configuration.PaymentListUri + '/' + id + '/type')
            .map(data => { return new PaymentTypeViewObj(data) }).catch(err => { return throwError(err) });
    }

    public createPaymentList(paymentList: PaymentList): Observable<PaymentList> {
        var command = {
            title: paymentList.Title,
            message: paymentList.Message,
            businessId: paymentList.BusinessId,
            paymentListTypeId: paymentList.PaymentListType,
            RecipientTypeId: paymentList.PaymentListType != PaymentListType.NfsCashInTransaction ? paymentList.RecipientType : CustomerType.Customer,
            isSalary: paymentList.IsSalary
        };
        return this.http.post(Configuration.PaymentListUri, command)
            .map(data => { return new PaymentList(data) })
            .catch(err => { return throwError(err) });
    }

    public updatePaymentList(paymentList: PaymentList): Observable<object> {
        console.log("ids:" + paymentList.Id);
        var command = {
            title: paymentList.Title,
            message: paymentList.Message
        };
        return this.http.put(Configuration.PaymentListUri + '/' + paymentList.Id, command)
            .catch(err => { return throwError(err) });
    }

    public clonePaymentList(paymentList: PaymentList): Observable<object> {
        var command = {
            paymentListId: paymentList.Id,
            title: paymentList.Title,
            message: paymentList.Message
        };
        return this.http.post(Configuration.ClonePaymentListUri, command)
            .catch(err => { return throwError(err) });
    }

    public addPaymentListItem(paymentListId: number, command: PaymentListItemCommand): Observable<PaymentListItem> {
        var item = {
            PhoneNumber: command.PhoneNumber?.trim(),
            Amount: command.Amount,
            AgentNumber: command.AgentNumber,
            Message: command.Message
        };
        return this.http.post(Configuration.PaymentListUri + '/' + paymentListId + '/employee', item)
            .map(data => { return PaymentListItem.GetFromCommand(command, data) })
            .catch(err => { return throwError(err) });
    }

    public addPaymentListItemsInBulk(paymentListId: number, csvItems: any[],
        paymentList: PaymentListDetails): Observable<BulkAddPaymentListItemsResponse> {
        var paymentItems = csvItems.map(a => new PaymentListItemShort(a));
        var command = new BulkPaymentListItemsCommand(paymentItems,
            paymentList.BusinessId, paymentList.IsSalary, paymentList.PaymentListType);

        return this.http.post(Configuration.PaymentListUri + '/' + paymentListId + '/bulkAddRecipients', command)
            .map((data: any) => {
                return new BulkAddPaymentListItemsResponse(
                    data.successfulItems.map(i => new PaymentListItem(i)),
                    data.failedItems.map(f => new FailedReceiverInfoView(f))
                )
            })
            .catch(err => throwError(err));
    }

    public processPayment(paymentListId: number): Observable<any> {
        return this.http.post(Configuration.PaymentListUri + '/' + paymentListId + '/user/otp', {})
            .catch(err => { return throwError(err) });
    }

    public verifyPaymentOtp(paymentListId: number, code: string, hashCode: string): Observable<any> {
        var command = {
            otp: code,
            paymentListId: paymentListId,
            hashCode: hashCode
        };
        return this.http.post(Configuration.BulkPaymentUri, command)
            .map(data => { return data })
            .catch(err => { return throwError(err) });
    }

    public updatePaymentListItem(pid: number, itemId: number, amount: number): Observable<any> {
        var command = { Amount: amount };
        return this.http.put(Configuration.PaymentListUri + '/' + pid + '/employee/' + itemId, command)
            .catch(err => { return throwError(err) });
    }

    public removePaymentListItem(pid: number, itemId: number): Observable<any> {
        return this.http.delete(Configuration.PaymentListUri + '/' + pid + '/employee/' + itemId)
            .catch(err => { return throwError(err) });
    }

    public updatePaymentListStatus(pid: number, status: PaymentListStatus): Observable<any> {
        var command = { PaymentListStatus: status };
        return this.http.put(Configuration.PaymentListUri + '/' + pid + '/status', command)
            .catch(err => { return throwError(err) });
    }

    public deletePaymentList(pid: number): Observable<any> {
        return this.http.delete(Configuration.PaymentListUri + '/' + pid)
            .catch(err => { return throwError(err) });
    }

    public getPaymentSlipDetailsForBusiness(externalTransactionGuid: string): Observable<SalaryPaymentSlipDetailsForBusinessView> {
        var params = new HttpParams();
        params = params.set('externalTransactionGuid', externalTransactionGuid);
        return this.http.get(Configuration.PaymentSlipDetailsUri, { params: params })
            .map(data => { return new SalaryPaymentSlipDetailsForBusinessView(data) })
            .catch(err => { return throwError(err) });
    }

    public getPaymentSlip(externalTransactionGuid: string): Observable<any> {
        var params = new HttpParams();
        params = params.set('externalTransactionGuid', externalTransactionGuid);
        const httpOptions = {
            responseType: 'blob' as 'json',
            observe: 'response' as 'body',
            params: params
        };
        return this.http.get(Configuration.PaymentSlipPdfUri, httpOptions)
            .map((response: any) => {
                if (response.status == 200) {
                    let fileName = `Payment-Slip_${externalTransactionGuid}`;
                    response.body.fileName = fileName;
                    return response.body;
                }
            })
            .catch(err => { return throwError(err) });
    }
}
