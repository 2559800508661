import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";

import { NgxPaginationModule } from "ngx-pagination";

import { MaterialModule } from "app/shared/material";
import { WithdrawService } from "app/services/withdraw.service";
import { SharedModule } from "../shared/shared.module";
import { WithdrawComponent } from "./withdraw.component";
import { CreateWithdrawComponent } from './create-request/create-withdraw.component'; 


@NgModule({
    declarations: [
        WithdrawComponent,
        CreateWithdrawComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        FlexLayoutModule,
        MaterialModule,
        NgxPaginationModule
    ],
    exports: [
        WithdrawComponent
    ],
    providers: [
        WithdrawService
    ],
    entryComponents: [
        CreateWithdrawComponent
    ]
})
export class WithdrawModule { }