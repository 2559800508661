import { Component, Output, Input, EventEmitter } from "@angular/core"; 

import { PaginationConfig } from "../../../models/paginationConfig";

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html'
})

export class PaginationComponent {
    @Input() paginationConfig = new PaginationConfig();
    @Output() pageChangedCallback = new EventEmitter();

   

    pageChanged(pageNumber: number) {
      this.paginationConfig.currentPage = pageNumber;
      this.pageChangedCallback.emit(pageNumber);
    }
}