export class IFTProviderView{
    public DisplayName: string
    public IsActive: boolean
    public IsVirtualAccountMapped: boolean
    public BannerInfo: BannerInfo[]

    constructor(data){
        this.DisplayName = data.displayName,
        this.IsActive = data.isActive,
        this.IsVirtualAccountMapped = data.isVirtualAccountMapped
        this.BannerInfo= [];
        if (data.bannersInfo) {
            data.bannersInfo.forEach(item => {
                this.BannerInfo.push(new BannerInfo(item));
            });
        }
    }
}

export class BannerInfo{
    public Title: string
    public BannerId: string
    public ImageURL: string

    constructor(data){
        this.Title = data.title
        this.BannerId = data.bannerId
        this.ImageURL = data.imageURL
    } 
}

export class IFTVirtualAccountMappingDto{
    public IFTProviderDisplayName: string
    public VirtualAccountNumber: string
    public VirtualAccountName: string

    constructor(data){
        this.IFTProviderDisplayName = data.iftProviderDisplayName,
        this.VirtualAccountNumber = data.virtualAccountNumber,
        this.VirtualAccountName = data.virtualAccountName
    }
}