import { Injectable } from '@angular/core';
import { ApiKeyEventProperties, DashboardEventProperties, PaymentEventProperties, RequestsEventProperties, TransactionEventProperties, TransferEventProperties } from 'app/shared/mixpanel/mixpanel-event-properties';
import { ApiKeyEvents, DashboardEvents, PaymentEvents, RequestEvents, TransactionEvents, TransferEvents } from 'app/shared/mixpanel/mixpanel-events';
import { MixpanelService } from 'app/shared/mixpanel/mixpanel.service';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MixpanelUtilService {


  customerGuid: string;
  constructor(private mixpanelService: MixpanelService,
    private authService: AuthenticationService) {
    this.authService.currentUser$.subscribe(response => {
      this.customerGuid = response.SelectedBusiness.ExternalCustomerGuid;
    });
  }


  /* calling mixpanel service */
  trackEvents(eventName: string, data: any) {
    this.mixpanelService.track(eventName, data);
  }

  /* Dashboard track events */
  tapDashboardEventLog(dashboard: boolean, businessBalance: number) {
    var data = new Array();
    data[DashboardEventProperties.TapDashboardEvent.Dashboard] = dashboard;
    data[DashboardEventProperties.TapDashboardEvent.BusinessBalance] = businessBalance;
    data[DashboardEventProperties.TapDashboardEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(DashboardEvents.TapDashBoard, data);
  }

  recentPaymentsEventLog(pendingTab: boolean, completedTab: boolean, incomingTab: boolean) {
    var data = new Array();
    data[DashboardEventProperties.RecentPaymentsEvent.PendingTab] = pendingTab;
    data[DashboardEventProperties.RecentPaymentsEvent.CompletedTab] = completedTab;
    data[DashboardEventProperties.RecentPaymentsEvent.IncomingTab] = incomingTab;
    data[DashboardEventProperties.RecentPaymentsEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(DashboardEvents.RecentPayments, data);
  }

  helpEventLog(helpButton: boolean, howToTopUpButton: boolean) {
    var data = new Array();
    data[DashboardEventProperties.HelpEvent.HelpButton] = helpButton;
    data[DashboardEventProperties.HelpEvent.HowToTopUpButton] = howToTopUpButton;
    data[DashboardEventProperties.HelpEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(DashboardEvents.Help, data);
  }

  outgoingsEventLog(applyOutgoings: boolean) {
    var data = new Array();
    data[DashboardEventProperties.OutgoingsEvent.ApplyOutgoings] = applyOutgoings;
    data[DashboardEventProperties.OutgoingsEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(DashboardEvents.Outgoings, data);
  }

  /* Dashboard track events end */

  /* Payments track events */

  tapPaymentEventLog(payment: boolean) {
    var data = new Array();
    data[PaymentEventProperties.TapPaymentEvent.Payment] = payment;
    data[PaymentEventProperties.TapPaymentEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(PaymentEvents.TapPayment, data);
  }

  paymentTabsEventLog(allTab: boolean, completedTab: boolean, pendingTab: boolean, draftTab: boolean) {
    var data = new Array();
    data[PaymentEventProperties.PaymentTabsEvent.AllTab] = allTab;
    data[PaymentEventProperties.PaymentTabsEvent.CompletedTab] = completedTab;
    data[PaymentEventProperties.PaymentTabsEvent.PendingTab] = pendingTab;
    data[PaymentEventProperties.PaymentTabsEvent.DraftTab] = draftTab;
    data[PaymentEventProperties.PaymentTabsEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(PaymentEvents.PaymentTabs, data);
  }

  createPaymentEventLog(transactionType: string, recipient: string, createNewPayment: boolean) {
    var data = new Array();
    data[PaymentEventProperties.CreatePaymentEvent.CustomerGuid] = this.customerGuid;
    data[PaymentEventProperties.CreatePaymentEvent.TransactionType] = transactionType;
    data[PaymentEventProperties.CreatePaymentEvent.Recipient] = recipient;
    data[PaymentEventProperties.CreatePaymentEvent.CreateNewPayment] = createNewPayment;
    this.trackEvents(PaymentEvents.CreatePayment, data);
  }

  addRecipientEventLog(phoneNumber: string, amount: number) {
    var data = new Array();
    data[PaymentEventProperties.AddRecipientEvent.CustomerGuid] = this.customerGuid;
    data[PaymentEventProperties.AddRecipientEvent.PhoneNumber] = phoneNumber;
    data[PaymentEventProperties.AddRecipientEvent.Amount] = amount;
    this.trackEvents(PaymentEvents.AddRecipient, data);
  }

  importRecipientEventLog(phoneNumber: any, agentNumber: any, amount: any) {
    var data = new Array();
    data[PaymentEventProperties.ImportRecipientEvent.CustomerGuid] = this.customerGuid;
    if (agentNumber.length > 0)
      data[PaymentEventProperties.ImportRecipientEvent.AgentNumber] = agentNumber;
    else
      data[PaymentEventProperties.ImportRecipientEvent.PhoneNumber] = phoneNumber;
    data[PaymentEventProperties.ImportRecipientEvent.Amount] = amount;
    this.trackEvents(PaymentEvents.ImportRecipient, data);
  }

  finishDraftEventLog(paymentId: number, deleteDraft: boolean, editDraft: boolean, addToPending: boolean, payNow: boolean) {
    var data = new Array();
    data[PaymentEventProperties.FinishDraftEvent.PaymentId] = paymentId;
    data[PaymentEventProperties.FinishDraftEvent.DeleteDraft] = deleteDraft;
    data[PaymentEventProperties.FinishDraftEvent.EditDraft] = editDraft;
    data[PaymentEventProperties.FinishDraftEvent.AddToPending] = addToPending;
    data[PaymentEventProperties.FinishDraftEvent.PayNow] = payNow;
    data[PaymentEventProperties.FinishDraftEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(PaymentEvents.FinishDraft, data);
  }

  completePaymentEventLog(confirmPayment: boolean, noOfRecipients: number, amount: number, typeOfPayment: string) {
    var data = new Array();
    data[PaymentEventProperties.CompletePaymentEvent.ConfirmPayment] = confirmPayment;
    data[PaymentEventProperties.CompletePaymentEvent.NumberOfRecipients] = noOfRecipients;
    data[PaymentEventProperties.CompletePaymentEvent.Amount] = amount;
    data[PaymentEventProperties.CompletePaymentEvent.TypeOfPayment] = typeOfPayment;
    data[PaymentEventProperties.CompletePaymentEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(PaymentEvents.CompletePayment, data);
  }

  exploreCompletedPaymentEventLog(exploreList: boolean, duplicateList: boolean, statement: boolean) {
    var data = new Array();
    data[PaymentEventProperties.ExploreCompletedPaymentEvent.ExploreList] = exploreList;
    data[PaymentEventProperties.ExploreCompletedPaymentEvent.DuplicateList] = duplicateList;
    data[PaymentEventProperties.ExploreCompletedPaymentEvent.Statement] = statement;
    data[PaymentEventProperties.ExploreCompletedPaymentEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(PaymentEvents.ExploreCompletedPayment, data);
  }

  accessPaySlipEventLog(printPaySlip: boolean, downloadPaySlip: boolean) {
    var data = new Array();
    data[PaymentEventProperties.AccessPaySlipEvent.PrintPaySlip] = printPaySlip;
    data[PaymentEventProperties.AccessPaySlipEvent.DownloadPaySlip] = downloadPaySlip;
    data[PaymentEventProperties.AccessPaySlipEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(PaymentEvents.AccessPaySlip, data);
  }

  duplicateListEventLog(duplicateList: boolean, paymentTitle: string, recipientMessage: string) {
    var data = new Array();
    data[PaymentEventProperties.DuplicateListEvent.CustomerGuid] = this.customerGuid;
    data[PaymentEventProperties.DuplicateListEvent.DuplicateList] = duplicateList;
    data[PaymentEventProperties.DuplicateListEvent.PaymentTitle] = paymentTitle;
    data[PaymentEventProperties.DuplicateListEvent.RecipientMessage] = recipientMessage;
    this.trackEvents(PaymentEvents.DuplicateList, data);
  }

  printStatementEventLog(downloadStatement: boolean, viewStatement: boolean) {
    var data = new Array();
    data[PaymentEventProperties.PrintStatementEvent.DownloadStatement] = downloadStatement;
    data[PaymentEventProperties.PrintStatementEvent.ViewStatement] = viewStatement;
    data[PaymentEventProperties.PrintStatementEvent.CustomerGuid] = this.customerGuid
    this.trackEvents(PaymentEvents.PrintStatement, data);
  }
  /* Payments track events end */

  /* Transaction track events */

  tapTransactionEventLog(transaction: boolean) {
    var data = new Array();
    data[TransactionEventProperties.TapTransactionEvent.Transaction] = transaction;
    data[TransactionEventProperties.TapTransactionEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransactionEvents.TapTransaction, data);
  }

  exploreTransactionEventLog(exploreTransaction: boolean) {
    var data = new Array();
    data[TransactionEventProperties.ExploreTransactionEvent.ExploreTransaction] = exploreTransaction;
    data[TransactionEventProperties.ExploreTransactionEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransactionEvents.ExploreTransaction, data);
  }

  generateAccountStatementEventLog(startDate: string, endDate: string, generateStatement: boolean, downloadStatement: boolean) {
    var data = new Array();
    data[TransactionEventProperties.GenerateAccountStatementEvent.StartDate] = startDate;
    data[TransactionEventProperties.GenerateAccountStatementEvent.EndDate] = endDate;
    data[TransactionEventProperties.GenerateAccountStatementEvent.GenerateStatement] = generateStatement;
    data[TransactionEventProperties.GenerateAccountStatementEvent.DownloadStatement] = downloadStatement;
    data[TransactionEventProperties.GenerateAccountStatementEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransactionEvents.GenerateAccountStatement, data);
  }
  /* Transaction track events end */

  /* Transfer track events */

  tapInmTransferEventLog(inmTransfer: boolean) {
    var data = new Array();
    data[TransferEventProperties.TapInmTransferEvent.InmTransfer] = inmTransfer;
    data[TransferEventProperties.TapInmTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.TapInmTransfer, data);
  }

  inmCashOutEventLog(date: string, amount: number, message: string) {
    var data = new Array();
    data[TransferEventProperties.InmCashoutEvent.Date] = date;
    data[TransferEventProperties.InmCashoutEvent.CustomerGuid] = this.customerGuid;
    data[TransferEventProperties.InmCashoutEvent.Amount] = amount;
    data[TransferEventProperties.InmCashoutEvent.Message] = message;
    this.trackEvents(TransferEvents.InmCashout, data);
  }

  otherBankTransferEventLog(otherBankTransfer: boolean) {
    var data = new Array();
    data[TransferEventProperties.OtherBankTransferEvent.OtherBankTransfer] = otherBankTransfer;
    data[TransferEventProperties.OtherBankTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.OtherBankTransfer, data);
  }

  addBankAccountEventLog(date: string, bankName: string, accountName: string, accountNumber: string) {
    var data = new Array();
    data[TransferEventProperties.AddBankAccountEvent.Date] = date;
    data[TransferEventProperties.AddBankAccountEvent.CustomerGuid] = this.customerGuid;
    data[TransferEventProperties.AddBankAccountEvent.SelectBankName] = bankName;
    data[TransferEventProperties.AddBankAccountEvent.AccountName] = accountName;
    data[TransferEventProperties.AddBankAccountEvent.AccountNumber] = accountNumber;
    this.trackEvents(TransferEvents.AddBankAccount, data);
  }

  transferRequestEventLog(date: string) {
    var data = new Array();
    data[TransferEventProperties.TransferRequestEvent.Date] = date;
    data[TransferEventProperties.TransferRequestEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.TransferRequest, data);
  }

  confirmTransactionRequestEventLog(confirmTransaction: boolean) {
    var data = new Array();
    data[TransferEventProperties.ConfirmTransactionRequestEvent.ConfirmTransaction] = confirmTransaction;
    data[TransferEventProperties.ConfirmTransactionRequestEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.ConfirmTransferRequest, data);
  }

  completeTransferRequestEventLog(date: string, amount: number, bankName: string) {
    var data = new Array();
    data[TransferEventProperties.CompleteTransferRequestEvent.Date] = date;
    data[TransferEventProperties.CompleteTransferRequestEvent.CustomerGuid] = this.customerGuid;
    data[TransferEventProperties.CompleteTransferRequestEvent.BankName] = bankName;
    data[TransferEventProperties.CompleteTransferRequestEvent.Amount] = amount;
    this.trackEvents(TransferEvents.CompleteTransferRequest, data);
  }

  nfsBankTransferEventLog(nfsBankTranser: boolean) {
    var data = new Array();
    data[TransferEventProperties.NfsBankTransferEvent.NfsBankTransfer] = nfsBankTranser;
    data[TransferEventProperties.NfsBankTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.NfsBankTransfer, data);
  }

  createNfsBankTransferEventLog(date: string, amount: number) {
    var data = new Array();
    data[TransferEventProperties.CreateNfsBankTransferEvent.Date] = date;
    data[TransferEventProperties.NfsBankTransferEvent.Amount] = amount;
    data[TransferEventProperties.CreateNfsBankTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.CreateNfsBankTransfer, data);
  }

  confirmNfsBankTransferEventLog(confirmTransaction: boolean) {
    var data = new Array();
    data[TransferEventProperties.NfsBankTransferConfirmTransactionEvent.ConfirmTransaction] = confirmTransaction;
    data[TransferEventProperties.NfsBankTransferConfirmTransactionEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.NfsBankTransferConfirmTransaction, data);
  }

  completeNfsBankTransferEventLog(date: string, amount: number, bankName: string) {
    var data = new Array();
    data[TransferEventProperties.CompleteNfsBankTransferEvent.Date] = date;
    data[TransferEventProperties.CompleteNfsBankTransferEvent.CustomerGuid] = this.customerGuid;
    data[TransferEventProperties.CompleteNfsBankTransferEvent.BankName] = bankName;
    data[TransferEventProperties.CompleteNfsBankTransferEvent.Amount] = amount;
    this.trackEvents(TransferEvents.CompleteNfsBankTransfer, data);
  }

  nfsMomoTransferEventLog(nfsMomoTranser: boolean) {
    var data = new Array();
    data[TransferEventProperties.NfsMomoTransferEvent.NfsMomoTransfer] = nfsMomoTranser;
    data[TransferEventProperties.NfsMomoTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.NfsMomoTransfer, data);
  }

  createNfsMomoTransferEventLog(date: string, amount: number) {
    var data = new Array();
    data[TransferEventProperties.CreateNfsMomoTransferEvent.Date] = date;
    data[TransferEventProperties.CreateNfsMomoTransferEvent.Amount] = amount;
    data[TransferEventProperties.CreateNfsMomoTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.CreateNfsMomoTransfer, data);
  }

  confirmNfsMomoTransferEventLog(confirmTransaction: boolean) {
    var data = new Array();
    data[TransferEventProperties.NfsMomoTransferConfirmTransactionEvent.ConfirmTransaction] = confirmTransaction;
    data[TransferEventProperties.NfsMomoTransferConfirmTransactionEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.NfsMomoTransferConfirmTransaction, data);
  }

  completeNfsMomoTransferEventLog(date: string, amount: number, momoName: string) {
    var data = new Array();
    data[TransferEventProperties.CompleteNfsMomoTransferEvent.Date] = date;
    data[TransferEventProperties.CompleteNfsMomoTransferEvent.CustomerGuid] = this.customerGuid;
    data[TransferEventProperties.CompleteNfsMomoTransferEvent.MomoName] = momoName;
    data[TransferEventProperties.CompleteNfsMomoTransferEvent.Amount] = amount;
    this.trackEvents(TransferEvents.CompleteNfsMomoTransfer, data);
  }

  selcomMomoTransferEventLog(selcomMomoTranser: boolean) {
    var data = new Array();
    data[TransferEventProperties.SelcomMomoTransferEvent.SelcomMomoTransfer] = selcomMomoTranser;
    data[TransferEventProperties.SelcomMomoTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.SelcomMomoTransfer, data);
  }

  createSelcomMomoTransferEventLog(date: string, amount: number) {
    var data = new Array();
    data[TransferEventProperties.CreateSelcomMomoTransferEvent.Date] = date;
    data[TransferEventProperties.CreateSelcomMomoTransferEvent.Amount] = amount;
    data[TransferEventProperties.CreateSelcomMomoTransferEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.CreateSelcomMomoTransfer, data);
  }

  confirmSelcomMomoTransferEventLog(confirmTransaction: boolean) {
    var data = new Array();
    data[TransferEventProperties.SelcomMomoTransferConfirmTransactionEvent.ConfirmTransaction] = confirmTransaction;
    data[TransferEventProperties.SelcomMomoTransferConfirmTransactionEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(TransferEvents.SelcomMomoTransferConfirmTransaction, data);
  }

  completeSelcomMomoTransferEventLog(date: string, amount: number, momoName: string) {
    var data = new Array();
    data[TransferEventProperties.CompleteSelcomMomoTransferEvent.Date] = date;
    data[TransferEventProperties.CompleteSelcomMomoTransferEvent.CustomerGuid] = this.customerGuid;
    data[TransferEventProperties.CompleteSelcomMomoTransferEvent.MomoName] = momoName;
    data[TransferEventProperties.CompleteSelcomMomoTransferEvent.Amount] = amount;
    this.trackEvents(TransferEvents.CompleteSelcomMomoTransfer, data);
  }

  /* Transfer track events end */

  /* Requests track events */
  tapRequestsEventLog(requests: boolean) {
    var data = new Array();
    data[RequestsEventProperties.TapRequestEvent.Requests] = requests;
    data[RequestsEventProperties.SearchRequestEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(RequestEvents.TapRequests, data);
  }

  searchRequestsEventLog(date: string, startDate: string, endDate: string, customerGuid: string) {
    var data = new Array();
    data[RequestsEventProperties.SearchRequestEvent.Date] = date;
    data[RequestsEventProperties.SearchRequestEvent.StartDate] = startDate;
    data[RequestsEventProperties.SearchRequestEvent.EndDate] = endDate;
    data[RequestsEventProperties.SearchRequestEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(RequestEvents.SearchRequests, data);
  }

  requestTransferEventLog(amount: number) {
    var data = new Array();
    data[RequestsEventProperties.RequestTransferEvent.CustomerGuid] = this.customerGuid;
    data[RequestsEventProperties.RequestTransferEvent.Amount] = amount;
    this.trackEvents(RequestEvents.RequestTransfer, data);
  }

  approveRequestEventLog(isApproved: boolean, isDeclined: boolean, isCanceled: boolean) {
    var data = new Array();
    data[RequestsEventProperties.RequestTransferEvent.CustomerGuid] = this.customerGuid;


    if (isApproved || isDeclined) {
      data[RequestsEventProperties.ApproveRequestEvent.Approved] = isApproved;
      data[RequestsEventProperties.ApproveRequestEvent.Declined] = isDeclined;
      this.trackEvents(RequestEvents.ApproveRequest, data);
    } else {
      data[RequestsEventProperties.ApproveRequestEvent.Canceled] = isCanceled;
      this.trackEvents(RequestEvents.CancelRequest, data);
    }
  }

  requestRefundEventLog(amount: number) {
    var data = new Array();
    data[RequestsEventProperties.RequestTransferEvent.CustomerGuid] = this.customerGuid;
    data[RequestsEventProperties.RequestTransferEvent.Amount] = amount;
    this.trackEvents(RequestEvents.RefundRequest, data);
  }
  /* Requests track events end */

  /* APIKey track events */

  tapApiKeyEventLog(apiKey: boolean) {
    var data = new Array();
    data[ApiKeyEventProperties.TapApiKeyEvent.ApiKey] = apiKey;
    data[ApiKeyEventProperties.TapApiKeyEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(ApiKeyEvents.TapApiKey, data);
  }

  createApiKeyEventLog(date: string, apiKeyName: string, permissions: any) {
    var data = new Array();
    data[ApiKeyEventProperties.CreateApiEvent.Date] = date;
    data[ApiKeyEventProperties.CreateApiEvent.CustomerGuid] = this.customerGuid;
    data[ApiKeyEventProperties.CreateApiEvent.ApiKeyName] = apiKeyName;
    data[ApiKeyEventProperties.CreateApiEvent.Permissions] = permissions;
    this.trackEvents(ApiKeyEvents.CreateApiKey, data);
  }

  revokingApiKeyEventLog(date: string) {
    var data = new Array();
    data[ApiKeyEventProperties.RevokingApiEvent.Date] = date;
    data[ApiKeyEventProperties.RevokingApiEvent.CustomerGuid] = this.customerGuid;
    this.trackEvents(ApiKeyEvents.RevokingApiKey, data);
  }

  updateApiKeyEventLog(date: string, permissions: any) {
    var data = new Array();
    data[ApiKeyEventProperties.UpdateApiEvent.Date] = date;
    data[ApiKeyEventProperties.UpdateApiEvent.CustomerGuid] = this.customerGuid;
    data[ApiKeyEventProperties.UpdateApiEvent.Permissions] = permissions;
    this.trackEvents(ApiKeyEvents.UpdateApiKey, data);
  }
  /* APIKey track events end */


}
