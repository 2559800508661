<div class="row">
  <div class="col-md-12">
    <app-spinner [spin]="busy"></app-spinner>
    <div class="panel panel-default">
     
      <div class="panel-header">
          Change Password
      </div>
      <div class="panel-body">
          <form (ngSubmit)="onSubmit()" action="">
              <div class="form-group">
                <label for="currentPass">Current Password</label>
                <input type="password" id="currentPass" class="form-control" name="currentPass" 
                [(ngModel)]="changePasswordCommand.CurrentPassword" placeholder="Enter current password" required="required">
              </div>
              <div class="form-group">
                <label for="newPassword">New Password</label>
                <input type="password" id="newPassword" class="form-control" name="newPassword"
                [(ngModel)]="changePasswordCommand.NewPassword" placeholder="Enter new password" required="required">
              </div>
              <div class="form-group">
                  <label for="confirmPassword">Confirm New Password</label>
                  <input type="password" id="confirmPassword" class="form-control" name="confirmPassword"
                  [(ngModel)]="changePasswordCommand.ConfirmPassword" placeholder="Enter new password again" required="required">
              </div>
              <div class="form-group text-end mt-5 mb-3">
                <button type="submit" class="btn spenn-btn btn-default px-7" [disabled]="submitBtnDisabled">Change Password</button>
              </div>
            </form>
      </div>
    </div>
    <div class="panel panel-default" *ngIf="user && !user.IsRequiredPasswordChange">
      <div class="panel-header">
        Change phone number
      </div>
      <div class="panel-body">
        <ng-container *ngIf=!phoneNumberChangeRequested>
          <div class="form-group" >
            Current phone number: {{user.TwoFaPhoneNumber}}
          </div>
          <div class="form-group">
            <label for="newNumber">New phone number</label>
            <input type="text" id="newNumber" class="form-control" name="newNumber"
            [(ngModel)]="newPhoneNumber" placeholder="Enter new phone number" required="required">
          </div>
          <div class="form-group">
            <label for="country">Select country</label>
            <select class="form-control" [(ngModel)]="countryId" id="country" name="country">
              <option [selected]="true" value="null">-- Select --</option>
              <option *ngFor="let country of countries" [value]="country.countryId">{{country.name}}</option>
          </select>
          </div>

          <div class="form-group text-end mt-5 mb-3">
            <button class="btn spenn-btn btn-default px-7" (click)="requestPhoneChange()" >Change number</button>
          </div>            
        </ng-container>


        <ng-container *ngIf=phoneNumberChangeRequested>
          <div class="form-group" >
            A verification code has been sent to {{maskedPhoneNumberAndEmail}}. Please provide the code here and confirm.
          </div>
          <div class="form-group" >
            <label for="otp">Verification code</label>
            <input type="text" id="otp" class="form-control" name="otp"
            [(ngModel)]="otp" placeholder="Verification code" required="required">
          </div>

          <div class="form-group text-end mt-5 mb-3">
            <button class="btn spenn-btn btn-default cancel-btn px-7 mr-2" (click)="cancelPhoneChange()">Cancel</button>
            <button class="btn spenn-btn btn-default px-7" (click)="confirmPhoneChage()">Confirm</button>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>
