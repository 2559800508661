import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-kazang',
  templateUrl: './kazang.component.html',
  styleUrls: ['./kazang.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KazangComponent implements OnInit {

  instructionsArray: string[];

  constructor(private dialogRef: MatDialogRef<KazangComponent>, 
    private userService: UserService) { }

  ngOnInit(): void {
    this.getInstructions();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getInstructions() {
    this.instructionsArray = [
      'Go to any Kazang location',
      'Hand over money and your phone number: ' + this.userService.getUserFromLocalStorage().SelectedBusiness.PhoneNumber,
      'Push message received when top up has been completed'
    ]
  }


}
