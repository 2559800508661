import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SBWebQrCodeResponse } from 'app/models/user';
import { AuthenticationService } from 'app/services/auth.service';
import { BusinessService } from 'app/services/business.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qr-code-spenn',
  templateUrl: './qr-code-spenn.component.html',
  styleUrls: ['./qr-code-spenn.component.scss']
})
export class QrCodeSpennComponent implements OnInit {
  public currentUserQr: SBWebQrCodeResponse;
  public busy: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authenticationService: AuthenticationService,
    private businessService: BusinessService
  ) {
    this.authenticationService.currentUserQr$.subscribe(
      res => {
        this.currentUserQr = res;

      }
    );
  }

  ngOnInit(): void {
  }

  downloadQRCode(): void {
    this.busy = this.businessService.downloadQRCode().subscribe(
      response => {
        var blob = new Blob([response]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${response.fileName}.pdf`;
        link.click();
      },
      error => {

      }
    );
  }

}
