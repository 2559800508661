  <div *ngIf="(spin && !spin.closed) || spinIf" class="loader-wrapper" 
  [class.loader-right]="position=='right'"
  [class.loader-left]="position=='left'">
  <div class="loading">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</div>  
