import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs/Rx';

import { AuthenticationService } from '../services/auth.service';
import { NotificationService } from 'app/services/notification.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService, private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.islogedIn(false)
  }
}

@Injectable({ providedIn: 'root' })
export class AuthGuardWithRequirePasswordChange implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router, private notif: NotificationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.islogedIn(true)
  }
}

