<app-spinner [spin]="sub"></app-spinner>
<div class="top-up-container-branch">
    <div class="title-box" fxLayout="row" fxLayoutAlign="space-between center" >
        <div><h5>{{data.bankName}} Branch</h5></div> 
        <div>
          <button class="btn-close" (click)="closeDialog()"></button>
        </div>
    </div>

    <div class="content-box" fxLayout="column" fxLayoutAlign="space-between center">

        <div>
            <img src="assets/images/up-arrow.png">
        </div>

        <div class="note">Note down your referral code</div>

        <div class="code">
            <h1>{{rewardCode}}</h1>
        </div>

        <div class="btn-box">
            <button class="spenn-btn" (click)="closeDialog()">Ok</button>
        </div>
    </div>

</div>
