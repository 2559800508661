import { SpennCustomer } from './spennCustomer';

export class AirtimeInformation {
  public Phone: string;
  public OperatorName: string;
  public MinimumAmount: number = 0;
  public MaxAmount: number = 0;
  public Interval: number = 0;
  public Customer: SpennCustomer;

  constructor(data: any = {}) {
    this.Phone = data.phoneNumber;
    if(data.operatorInformation) {
      if(data.operatorInformation.operator) 
        this.OperatorName = data.operatorInformation.operator.name || 'Airtime Operator';
      this.MinimumAmount = data.operatorInformation.minimumAmount || 0;
      this.MaxAmount = data.operatorInformation.maxAmount || 0;
      this.Interval = data.operatorInformation.interval || 0;
    }
    if (data.customer) {
      this.Customer = new SpennCustomer(data.customer);
    }
  }
}