<div class="container-fluid">
    <app-spinner [spin]="busy"></app-spinner>
    <div class="statement-wrapper">
        <div class="statement-logo">
            <img src="assets/images/spenn_business_logo_dark.svg" height="84" alt="spenn business logo">
        </div>
        <div class="statement-business-name my-4">
            <h1 *ngIf="currentUser && (paymentList || nfsPaymentDetails || moneyTransferDetails)">{{currentUser.SelectedBusiness?.Name}}</h1>
            <h5 *ngIf="!currentUser.SelectedBusiness || (!paymentList && !nfsPaymentDetails && !moneyTransferDetails)">Permission denied
                {{nfsPaymentDetails}}</h5>
        </div>
        <button class="btn-statement" (click)="print()"><i class="fa fa-print"></i></button>
        <div *ngIf="paymentList" class="statement-details">
            <table class="table statement-table text-default">
                <tbody>
                    <tr>
                        <td>Date:</td>
                        <td class="text-end">{{paymentList.InitiateDate | localDateTime}}</td>
                    </tr>
                    <tr>
                        <td>Phone:</td>
                        <td class="text-end">{{currentUser.SelectedBusiness?.PhoneNumber}}</td>
                    </tr>
                    <tr>
                        <td>Paid by:</td>
                        <td class="text-end">{{paymentList.InitiatedBy}}</td>
                    </tr>
                    <ng-container *ngIf="paymentList.PaymentListType == paymentListType.SpennTransaction 
                                    && !paymentList.IsSalary">
                        <tr>
                            <td>SPENN recipients:</td>
                            <td class="text-end">{{paymentList.CountOfSpennRecipients}}</td>
                        </tr>
                        <tr>
                            <td>Non SPENN recipients:</td>
                            <td class="text-end">{{paymentList.CountOfNonUserRecipients}}</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="!(paymentList.PaymentListType == paymentListType.SpennTransaction 
                                    && !paymentList.IsSalary)">
                        <tr>
                            <td>Recipients:</td>
                            <td class="text-end">{{paymentList.CountOfOk}}</td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td class="py-3"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Amount:</td>
                        <td class="text-end">{{true | formatCurrency}} {{paymentList.SumOfOk | formatMoney}}</td>
                    </tr>
                    <tr>
                        <td>Transfer fee:</td>
                        <td class="text-end">No fee</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td class="text-end">{{true | formatCurrency}} {{paymentList.SumOfOk | formatMoney}}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table statement-ref-table mt-5">
                <thead>
                    <tr>
                        <th>Completed date</th>
                        <th>Reference</th>
                        <th>Paid out</th>
                        <th>Paid in</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{paymentList.InitiateDate | localDateTime}}</td>
                        <td>{{paymentList.Title}}</td>
                        <td>— {{true | formatCurrency}} {{paymentList.SumOfOk | formatMoney}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>



        <div *ngIf="nfsPaymentDetails" class="statement-details">
            <table class="table statement-table text-default">
                <tbody>
                    <tr>
                        <td>Date:</td>
                        <td class="text-end">{{nfsPaymentDetails.CreateDate | localDateTime}}</td>
                    </tr>
                    <tr>
                        <td>Phone:</td>
                        <td class="text-end">{{currentUser.SelectedBusiness?.PhoneNumber}}</td>
                    </tr>
                    <tr>
                        <td>Paid by:</td>
                        <td class="text-end">{{nfsPaymentDetails.CreatedBy}}</td>
                    </tr>


                    <tr>
                        <td class="py-3"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Amount:</td>
                        <td class="text-end"
                            *ngIf="nfsPaymentDetails.PaymentListType != paymentListType.NfsCashInTransaction">{{true |
                            formatCurrency}} {{nfsPaymentDetails.TotalAmount | formatMoney}}
                        <td class="text-end"
                            *ngIf="nfsPaymentDetails.PaymentListType == paymentListType.NfsCashInTransaction">{{true |
                            formatCurrency}} {{totalSuccessNFsAmount | formatMoney}}
                        </td>
                    </tr>
                    <tr>
                        <td>Transfer fee:</td>
                        <td class="text-end"
                            *ngIf="nfsPaymentDetails.PaymentListType != paymentListType.NfsCashInTransaction">{{true |
                            formatCurrency}} {{nfsPaymentDetails.SumOfTotalFee | formatMoney}}</td>
                        <td class="text-end"
                            *ngIf="nfsPaymentDetails.PaymentListType == paymentListType.NfsCashInTransaction">{{true |
                            formatCurrency}} {{totalSuccessNFsFees | formatMoney}}</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td class="text-end"
                            *ngIf="nfsPaymentDetails.PaymentListType != paymentListType.NfsCashInTransaction">{{true |
                            formatCurrency}} {{nfsPaymentDetails.SumOfTotal | formatMoney}}</td>
                        <td class="text-end"
                            *ngIf="nfsPaymentDetails.PaymentListType == paymentListType.NfsCashInTransaction">{{true |
                            formatCurrency}} {{(totalSuccessNFsAmount + totalSuccessNFsFees) | formatMoney}}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table statement-ref-table mt-5">
                <thead>
                    <tr>
                        <th>Completed date</th>
                        <th>Reference</th>
                        <th>Paid out</th>
                        <th>Paid in</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{nfsPaymentDetails.CreateDate | localDateTime}}</td>
                        <td>{{nfsPaymentDetails.Title}}</td>
                        <td *ngIf="nfsPaymentDetails.PaymentListType != paymentListType.NfsCashInTransaction">— {{true |
                            formatCurrency}} {{nfsPaymentDetails.SumOfTotal | formatMoney}}</td>
                        <td *ngIf="nfsPaymentDetails.PaymentListType == paymentListType.NfsCashInTransaction">— {{true |
                            formatCurrency}} {{(totalSuccessNFsAmount + totalSuccessNFsFees) | formatMoney}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
         
        <div *ngIf="moneyTransferDetails" class="statement-details">
            <table class="table statement-table text-default">
                <tbody>
                    <tr>
                        <td>Date:</td>
                        <td class="text-end">{{moneyTransferDetails.CreateDate | localDateTime}}</td>
                    </tr>
                    <tr>
                        <td>Phone:</td>
                        <td class="text-end">{{currentUser.SelectedBusiness?.PhoneNumber}}</td>
                    </tr>
                    <tr>
                        <td>Paid by:</td>
                        <td class="text-end">{{moneyTransferDetails.CreatedBy}}</td>
                    </tr>


                    <tr>
                        <td class="py-3"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Amount:</td>
                        <td class="text-end"
                            *ngIf="moneyTransferDetails.PaymentListType == paymentListType.BankMomoTransaction">{{true |
                            formatCurrency}} {{totalSuccessNFsAmount | formatMoney}}
                        </td>
                    </tr>
                    <tr>
                        <td>Transfer fee:</td>
                        <td class="text-end"
                            *ngIf="moneyTransferDetails.PaymentListType == paymentListType.BankMomoTransaction">{{true |
                            formatCurrency}} {{totalSuccessNFsFees | formatMoney}}
                        </td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td class="text-end"
                            *ngIf="moneyTransferDetails.PaymentListType == paymentListType.BankMomoTransaction">{{true |
                            formatCurrency}} {{(totalSuccessNFsAmount + totalSuccessNFsFees) | formatMoney}}</td>
                    </tr>
                </tbody>
            </table>

            <table class="table statement-ref-table mt-5">
                <thead>
                    <tr>
                        <th>Completed date</th>
                        <th>Reference</th>
                        <th>Paid out</th>
                        <th>Paid in</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{moneyTransferDetails.CreateDate | localDateTime}}</td>
                        <td>{{moneyTransferDetails.Title}}</td>
                        <td *ngIf="moneyTransferDetails.PaymentListType == paymentListType.BankMomoTransaction">— {{true |
                            formatCurrency}} {{(totalSuccessNFsAmount + totalSuccessNFsFees) | formatMoney}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>