<app-spinner [spin]="busy"></app-spinner>
<div class="div-transfer">

  <div class="title-box" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <h5>{{title}}</h5>
    </div>
    <div>
      <button class="btn-close" (click)="closeDialog()"></button>
    </div>
  </div>
  <mat-divider></mat-divider>

  <mat-card>
    <mat-card-content>

  <!-- Create section -->
  <div *ngIf="isCreate()" fxLayout="column">
    <div fxLayout="column" fxLayoutGap="20px" fxFill>
      <div fxLayout="row">
        <p fxFlex="85" class="mat-body">Approximately Bank Transfer request can take upto 48 hours to be processed during week days and upto 72 hours during weekends.</p>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
            <span class="mat-body-2">Amount</span>
            <span class="mat-body-2 text-spenn">(Available: {{true|formatCurrency}} {{this.account?.Balance}})</span>
          </div>
          <div fxLayout="column">            
              <div fxLayout="column">
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Enter amount" pattern="\d{0,10}(?:[.,]\d{0,2})?"  [(ngModel)]="amount">
                </mat-form-field>
              </div>
            <div fxLayout="column">
              <span class="mat-body-2">Message</span>
              <mat-form-field appearance="outline">
                <input matInput placeholder="Enter message" maxlength="325" [(ngModel)]="message">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row-item" fxLayout="row" fxLayoutAlign="end center">
          <button class="button" (click)="closeDialog()">Close</button>
          <button class="button primary" (click)="onCreateContinue()">Continue</button>
        </div>
      </div>
    </div>
  </div>
      <!-- Create section -->

      <!-- Confirm section -->
      <div *ngIf="isConfirm()" fxLayout="column">
        <div fxLayout="column" fxLayoutGap="30px" fxFill>
          <div fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center" class="bank-image-big">
              <img src="assets/images/withdraw.png" alt="withdraw">
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="5px">
            <div *ngIf="accountDetails">
              <div fxLayout="row">
                <span fxFlex class="mat-body text-secondary">Bank Name</span>
                <span fxFlex class="mat-body-2">{{this.accountDetails.BankName}}</span>
              </div>
              <div fxLayout="row">
                <span fxFlex class="mat-body text-secondary">Account Number</span>
                <span fxFlex class="mat-body-2">{{this.accountDetails.AccountNumber}}</span>
                <mat-divider></mat-divider>
              </div>
            </div>
            <div fxLayout="row">
              <span fxFlex class="mat-body text-secondary">Amount</span>
              <span fxFlex class="mat-body-2">{{true|formatCurrency}} {{amount}}</span>
              <mat-divider></mat-divider>
            </div>
            <div fxLayout="row">
              <span fxFlex class="mat-body text-secondary">Bank Transfer Fees</span>
              <span fxFlex class="red-text mat-body-2">{{true|formatCurrency}}
                {{this.accountDetails.TransferFee}}</span>
              <mat-divider></mat-divider>
            </div>
            <div fxLayout="row">
              <span fxFlex class="mat-body text-secondary">Total Amount</span>
              <span fxFlex class="mat-body-2">{{true|formatCurrency}} {{totalAmount}}</span>
              <mat-divider></mat-divider>
            </div>
            <div class="row-item" fxLayout="row" fxLayoutAlign="end center">
              <button class="button" (click)="closeDialog()">Close</button>
              <button class="button primary" (click)="onConfirmContinue()">Continue</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Confirm section -->

      <!-- otp section -->
      <div *ngIf="isOtp()" fxLayout="column">

        <div class="row-item">
          <div class="label text-spenn">OTP</div>
          <div>
            <input class="input-box" placeholder="Enter otp" [(ngModel)]="otp">
          </div>
        </div>

        <div class="row-item">
          <span> A one-time-password (OTP) has been sent to {{getMaskedPhoneNumber()}}.</span>
          <a (click)="sendOtp()">Resend OTP</a>
        </div>

        <div class="row-item" fxLayout="row" fxLayoutAlign="end center">
          <button class="button" (click)="closeDialog()">Close</button>
          <button class="button primary" (click)="onOtpConfirm()">Confirm</button>
        </div>
      </div>
      <!-- otp section -->

      <!-- final section -->
      <div *ngIf="isFinal()">

        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="row-item" fxLayout="row" fxLayoutAlign="center center">
            <div class="image">
              <img src="assets/images/withdraw-success.png" alt="withdraw">
            </div>
          </div>

          <div>
            A successful transfer request of Amount
          </div>

          <div>
            <span class="bold-text"> {{true|formatCurrency}} {{totalAmount}}</span>
          </div>

          <div>
            has been created with
          </div>

          <div>
            a Reference Number <span class="bold-text"> {{withdraw?.WithdrawRequestShortCode}}</span>
          </div>

          <div class="row-item"></div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button class="button primary" (click)="closeDialog()">OK</button>
        </div>

        <div class="row-item"></div>
      </div>
      <!-- Final section -->

      <div *ngIf="errorMsg" class="red-text">
        {{errorMsg}}
      </div>
    </mat-card-content>
  </mat-card>
</div>