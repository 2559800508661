
<div class="top-up-instructions-container">

    <div *ngFor="let ins of instructions; let i = index;" class="instructions" fxLayout="row" fxLayoutAlign="start center">
        <span>{{i + 1}}</span>
        <div>
            <div class="instruction-text" [innerHTML]="ins"></div>
        </div>
    </div>

</div>