import { Injectable } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";
import { AuthenticationService } from "../../services/auth.service";
import { StateService } from "app/services/state.service";


@Component({
    selector: 'app-empty',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss']
  })

@Injectable()
export class EmptyComponent implements OnInit {
    public busy: Subscription;
    public dialogref: any; 
    public constructor(private userService: UserService, 
        private authService: AuthenticationService,
        private router: Router,
        private stateService: StateService) {}

    ngOnInit() {  // eslint-disable-line
        
        if(this.authService.getCurrentUserValue() == null) {
        this.busy = this.userService.getUserInfo().subscribe(
            user => {
                this.authService.updateCurrentUserWithValue(user);
                if(this.authService.isRequiredPasswordChange()) {
                    this.router.navigate(['profile']);
                } else {
                    this.stateService.setShowServiceAgreement(true);
                    this.router.navigate(['payments']);
                }
            },
            err => {
                console.log(err);
            }
          );
        } else if(this.authService.isRequiredPasswordChange() == false) {
            this.stateService.setShowServiceAgreement(true);
            this.router.navigate(['payments']);
        } else {
            this.router.navigate(['profile']);
        }
    }
}