<div>
  <mat-sidenav-container class="bb-sidenav-container" (window:resize)="onResize($event)">
    <mat-sidenav #sidenav mode="side" [opened]="true">
      <app-navigation></app-navigation>
    </mat-sidenav>

    <div class="page-wrapper-position">
      <router-outlet (activate)='onActivate()'></router-outlet>
    </div>
  </mat-sidenav-container>
</div>