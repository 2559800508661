<app-bb-toolbar [title]="pageTitle"></app-bb-toolbar>

<div class="topup-container">

    <h5 class="text-spenn">Top up options</h5>
    <div class="info-text">The following are the options on how you can top up your account</div>

    <div class="topup-item-panel" *ngIf="currencyId == 10">
        <div class="topup-item" (click)="showSpennAgentModal()">
            <h5>SPENN Agent</h5>
            <div>Top up from our SPENN agent centers</div>
        </div>

        <div class="topup-item" (click)="showBankBranchModal('I&M Bank')">
            <h5>I&M Bank Branch</h5>
            <div>Top up from I&M Bank Branches</div>
        </div>

        <div class="topup-item" (click)="showCrdbModal('Agent')">
            <h5>CRDB Bank Agent</h5>
            <div>Top up from CRDB Bank Agent centres</div>
        </div>

        <div class="topup-item" (click)="showCrdbModal('Branch')">
            <h5>CRDB Bank Branch</h5>
            <div>Top up from CRDB Bank Branches</div>
        </div>   
    </div>

    <div class="topup-item-panel" *ngIf="currencyId == 13">
        <div class="topup-item" (click)="showSpennAgentModal()">
            <h5>SPENN Agent</h5>
            <div>Top up from our SPENN agent centers</div>
        </div>

        <div class="topup-item" (click)="showBankBranchModal('Access Bank')">
            <h5>Access Bank Branch</h5>
            <div>Top up from Access Bank Branches</div>
        </div>

        <div class="topup-item" (click)="showKazangModal()">
            <h5>Kazang</h5>
            <div>Top up using Kazang</div>
        </div>

        <div class="topup-item" (click)="showNfsModal()">
            <h5>Bank Account & Mobile Money</h5>
            <div>Top up from Banks & Momos</div>
        </div>
    </div>

    <div class="topup-item-panel" *ngIf="currencyId == 9">
        <div class="topup-item" (click)="showSpennAgentModal()">
            <h5>SPENN Agent</h5>
            <div>Top up from our SPENN agent centers</div>
        </div>

        <div class="topup-item" (click)="showBankBranchModal('I&M Bank')">
            <h5>I&M Bank Branch</h5>
            <div>Top up from I&M Bank Branches</div>
        </div>

        <div class="topup-item" (click)="showRwandaAnyBankModal()">
            <h5>Bank Transfer - Any Bank</h5>
            <div>Top up from Any Bank</div>
        </div>
        
        <div class="topup-item" (click)="showPayRwModal()">
            <h5>Pay.RW - *508# & Card</h5>
            <div>Top up using Pay.rw USSD</div>
        </div>


    </div>


</div>
