import { formatDate } from "@angular/common";
import { NfsStatusCodes, PaymentList } from "./paymentList";

export class NfsPaymentApiView {
    NfsPaymentApiId: number;
    NfsPaymentApiName: string;
    DisplayText: string;
    IconUrl: string;
    IsActive: boolean;
    NfsProductCategoryList: Array<NfsProductCategory>;

    constructor(result: any = {}) {
        if (result.length == 0) {
            return;
        }
        let data = result[0];
        this.NfsPaymentApiId = data.nfsPaymentApiId || 0;
        this.NfsPaymentApiName = data.nfsPaymentApiName || '';
        this.DisplayText = data.displayText || '';
        this.IconUrl = data.iconUrl || '';
        this.IsActive = data.isActive || false;
        this.NfsProductCategoryList = new Array<NfsProductCategory>();
        if (data.nfsProductCategoryList) {
            data.nfsProductCategoryList.forEach(item => {
                this.NfsProductCategoryList.push(new NfsProductCategory(item));
            });
        }
    }
}

export class NfsProductCategory {
    NfsProductCategoryId: number;
    KazangProductCategory: string;
    DisplayText: string;
    IconUrl: string;
    NfsProductList: Array<NfsProduct>;

    constructor(data: any = {}) {
        this.NfsProductCategoryId = data.nfsProductCategoryId || 0;
        this.KazangProductCategory = data.kazangProductCategory || '';
        this.DisplayText = data.displayText || '';
        this.IconUrl = data.iconUrl || '';
        this.NfsProductList = new Array<NfsProduct>();
        if (data.nfsProductList) {
            data.nfsProductList.forEach(item => {
                this.NfsProductList.push(new NfsProduct(item));
            });
        }
    }
}

export class NfsProduct {
    NfsProductId: number;
    DisplayText: string;
    IconUrl: string;
    IsActive: boolean;
    MinValue: number;
    MaxValue: number;
    AccountReferenceRegex: string;
    constructor(data: any = {}) {
        this.NfsProductId = data.nfsProductId || 0;
        this.DisplayText = data.displayText || '';
        this.IconUrl = data.iconUrl || '';
        this.IsActive = data.isActive || false;
        this.MinValue = data.minValue || 0;
        this.MaxValue = data.maxValue || 0;
        this.AccountReferenceRegex = data.accountReferenceRegex || '';
    }
}

export class NfsInitiateCashInView {
    NfsPaymentGuid: string;
    NfsPaymentStatus: string;
    AccountReference: string;
    AccountName: string;
    AccountAddress: string;
    ConfirmationMessage: string;
    TaxCalculationView: TaxCalculationView;
    constructor(data: any = {}) {
        this.NfsPaymentGuid = data.nfsPaymentGuid || '';
        this.NfsPaymentStatus = data.nfsPaymentStatus || '';
        this.AccountReference = data.accountReference || '';
        this.AccountName = data.accountName || '';
        this.AccountAddress = data.accountAddress || '';
        this.ConfirmationMessage = data.confirmationMessage || '';
        this.TaxCalculationView = new TaxCalculationView(data.taxCalculationView)
    }
}

export class NfsConfirmCashInView {
    NfsPaymentGuid: string;
    NfsPaymentStatus: string;
    AccountReference: string;
    ResponseMessage: string;
    TaxCalculationView: TaxCalculationView;
    constructor(data: any = {}) {
        this.NfsPaymentGuid = data.nfsPaymentGuid || '';
        this.NfsPaymentStatus = data.nfsPaymentStatus || '';
        this.AccountReference = data.accountReference || '';
        this.ResponseMessage = data.responseMessage || '';
        this.TaxCalculationView = new TaxCalculationView(data.taxCalculationView)
    }
}

export class TaxCalculationView {
    Tax: number;
    Fees: number;
    PrincipalAmount: number;
    TotalAmount: number;
    constructor(data: any = {}) {
        this.Tax = data.tax || 0;
        this.Fees = data.fees || 0;
        this.PrincipalAmount = data.principleAmount || 0;
        this.TotalAmount = data.totalAmount || 0;
    }
}

export class NfsPaymentListDetailsView extends PaymentList {
    Items: NfsInformationItem[];
    SumOfPending: number;
    SumOfFailed: number;
    SumOfTotal: number;
    SumOfTotalFee: number;


    public constructor(data: any = {}) {
        super(data);
        this.Items = [];
        this.SumOfPending = data.sumOfPending || 0;
        this.SumOfFailed = data.sumOfFailed || 0;
        this.SumOfTotal = data.sumOfTotal || 0;
        this.SumOfTotalFee = data.sumOfTotalFee || 0;
        if (data.items) {
            data.items.forEach(item => {
                this.Items.push(new NfsInformationItem(item));
            });
        }
    }
}

export default class NfsInformationItem {


    NfsPaymentInformationId: number;
    OperatorId: number;
    Name: null;
    AccountReference: string;
    NfsStatusCode: null;
    Amount: number;
    Fee: number;
    PaymentListId: number;
    EditTempAmount: number = 0;
    EditStatus: boolean = false;
    Error: string;
    Status: string;

    public constructor(item: any) {
        this.NfsPaymentInformationId = item.nfsPaymentInformationId;
        this.OperatorId = item.operatorId;
        this.Name = item.name;
        this.AccountReference = item.accountReference;
        this.NfsStatusCode = item.nfsStatusCode;
        this.Amount = item.amount;
        this.Fee = item.fee;
        this.PaymentListId = item.paymentListId;
        this.Error = item.error;
        this.Status = NfsStatusCodes[item.nfsStatusCode];
    }
}

export class NfsBulkInportRespose {
    Items: NfsInformationItem[];
    public constructor(data: any) {
        this.Items = [];
        if (data.items) {
            data.items.forEach(item => {
                this.Items.push(new NfsInformationItem(item));
            });
        }
    }

}

export class NfsCashInItem {
    ProductId: number;
    AccountReference: string;
    Amount: number;
}

export class NfsCloneObj {
    PaymentListId: number;
    Title: string;
    Message: string;
    BusinessId: number;
}

export class NfsBulkInsertModel {
    PaymentListId: number;
    BusinessId: number;
    Items: NfsCashInItem[];
}


export interface NfsFailedPaymentListItem {
    OperatorID: string;
    Amount: number;
    PhoneOrBank: number;
    Status: string;
    Description: any;
}


export class NfsPaymentSlipDetailsForBusinessView {
    Country: string;
    CustomerSupportInfo: string;
    CompanyUrl: string
    PdfCreateDate: string
    PaymentDate: string
    CompanyName: string
    EmployeeId: string
    EmployeeName: string
    SpennAccountNumber: string
    Amount: number
    Currency: string
    AccountReference: string
    IsBank: boolean
    Fees: number
    Total: number
    OperatorName: string

    public constructor(data: any = {}) {
        this.Country = data.country || '';
        this.CustomerSupportInfo = data.customerSupportInfo || '';
        this.CompanyUrl = data.companyUrl || '';
        this.PdfCreateDate = data.pdfCreateDate || '';
        this.PaymentDate = data['paymentDate'] ? formatDate(data['paymentDate'], 'medium', 'en-US') : '';
        this.CompanyName = data.companyName || '';
        this.EmployeeId = data.employeeId || '';
        this.EmployeeName = data.employeeName || '';
        this.SpennAccountNumber = data.spennAccountNumber || '';
        this.Amount = data.amount || 0;
        this.Fees = data.fees || 0;
        this.Total = data.total || 0;
        this.Currency = data.currency || '';
        this.AccountReference = data.accountReference || '';
        this.OperatorName = data.operatorName || '';
        this.IsBank = data.isBank;
    }
}



