import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from "@angular/material/stepper";

@NgModule({
    imports: [
        MatButtonModule, 
        MatDatepickerModule,
        MatDialogModule, 
        MatIconModule,
        MatInputModule, 
        MatMomentDateModule,
        MatSidenavModule,
        MatTabsModule,
        MatToolbarModule,
        MatCardModule,
        MatSelectModule,
        MatDividerModule,
        MatStepperModule,
    ],
    exports: [
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSidenavModule,
        MatTabsModule,
        MatToolbarModule,
        MatCardModule,
        MatSelectModule,
        MatMomentDateModule,
        MatDividerModule,
        MatStepperModule
    ],
    declarations: [],
    providers: [
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
    ],
})
export class MaterialModule {
    static forTest(): MaterialModule {
        return {
            ngModule: MaterialModule,
            providers: [
                { provide: MatPaginatorIntl, useClass: MatPaginatorIntl }
            ],
        };
    }
}
