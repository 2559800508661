<app-bb-toolbar [title]="title"></app-bb-toolbar>

<app-spinner [spin]="busy"></app-spinner>
<div>
    <div class="topup-container" *ngIf="lookup?.IsVirtualAccountMapped == false">
        <div class="topup-item-panel">
            <div class="topup-item">
                <h5 class="text-spenn" style="text-align: center;">Send funds from Banks & MOMO to Spenn</h5>
                <div style="text-align: center;">Enter the reference details shown below</div>
                <br>
                <div class="btn-box">
                    <button class="spenn-btn" (click)="mapVirtualAccount()"> Generate my reference number</button>
                </div>
            </div>
        </div>
    </div>

    <div class="topup-container" *ngIf="lookup?.IsVirtualAccountMapped == true">
        <div class="topup-item-panel">
            <div class="topup-item">
                <h5 class="text-spenn" style="text-align: center;">Send funds from Banks & MOMO to Spenn</h5>
                <div style="text-align: center;">Enter the reference details shown below</div>
                <br>
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        Receiver:
                    </div>
                    <div>
                        <b>{{virtualAccount?.IFTProviderDisplayName}}</b>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2">
                    <div>
                        Reference Number:
                    </div>
                    <div>
                        <b>{{virtualAccount?.VirtualAccountNumber}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="lookup?.IsVirtualAccountMapped == true">
        <div>
            <div class="new-container" *ngFor="let item of lookup?.BannerInfo">
                <div class="topup-item-panel" (click)="showbanner(item.BannerId)">
                    <div class="topup-item w-100">
                        <div class="row gap-1 align-items-center">
                            <div class="img-block">
                                <img src="./assets/images/IFTKazang/{{item.ImageURL}}.png" alt="" height="50px" width="50px">
                            </div>
                            <div class="w-auto">
                                <div><b>{{item.Title}}</b></div>
                                <div>Send funds from {{item.Title}} to Spenn</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>