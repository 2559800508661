import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyService } from '../services/currency.service';

@Pipe({ name: 'formatMoney' })
export class MoneyFormatter implements PipeTransform {

    /**
     *
     */
    constructor(private currencyService: CurrencyService) {

    }

    transform(money: number): string { 
        return this.currencyService.format(money);
    }
}

@Pipe({ name: 'formatCurrency' })
export class CurrencyFormatter implements PipeTransform {

    /**
     *
     */
    constructor(private currencyService: CurrencyService) {

    }

    transform(flag: boolean): string { 
        return this.currencyService.formatCurrency();
    }
}

