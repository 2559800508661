import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrCodeSpennComponent } from './qr-code-spenn/qr-code-spenn.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/shared/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QRCodeModule } from 'angularx-qrcode';
import { SpinnerComponent } from '../spinner/spinner.component';
import { SharedModule } from '../shared.module';



@NgModule({
  declarations: [
    QrCodeSpennComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    QRCodeModule,
    SharedModule
  ],
  exports: [
    QrCodeSpennComponent
  ]
})
export class BbQrModule { }
