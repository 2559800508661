import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrModule } from "ngx-toastr";
import { ThemeService } from "ng2-charts";
import { NgxPaginationModule } from "ngx-pagination";

import { AppComponent } from "./app.component";
import { PageTemplateComponent } from "./components/template/page-template.component";
import { AppRoutingModule } from "./app.routing";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { NavigationComponent } from "./components/shared/navigation/navigation.component";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { LogoutComponent } from "./components/logout/logout.component";
import { UserLockComponent } from "./components/user-lock/user-lock.component";
import { PageTemplateSerivce } from "./services/page-template.service";
import { UserService } from "./services/user.service";
import { AuthenticationService } from "./services/auth.service";
import { CurrencyService } from "./services/currency.service";
import { BearerAuthInterceptor } from "./helpers/bearer-auth-interceptor";
import { EmptyComponent } from "./components/empty/empty.component";
import { ExternalService } from "./services/external.service";
import { BusinessService } from "./services/business.service";
import { ProfileComponent } from "./components/profile/profile.component";
import { PaymentListService } from "./services/payment-list.service";
import { SharedModule } from "./components/shared/shared.module";
import { ApiKeyService } from "./services/apikey.service";
import { environment } from "../environments/environment";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { StatementComponent } from "./components/statement/statement.component";
import { WithdrawModule } from "./components/withdraw/withdraw.module";
import { NfsService } from "./services/nfs.service";
import { MixpanelService } from "./shared/mixpanel/mixpanel.service";
import { QRCodeModule } from "angularx-qrcode";
import { BbQrModule } from "./components/shared/bb-qr/bb-qr.module";
import { IftService } from "./services/ift.service";
import { CommonModule } from "@angular/common";
import { TopupModule } from "./components/topup/topup.module";
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { ServiceAgreementService } from "./services/service-agreement.service";
import { StateService } from "./services/state.service";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    PageTemplateComponent,
    NavigationComponent,
    LogoutComponent,
    UserLockComponent,
    EmptyComponent,
    ProfileComponent,
    LandingPageComponent,
    StatementComponent,
    TermsConditionsComponent,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    QRCodeModule,
    TopupModule,
    // RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    // ModalModule.forRoot(),
    SharedModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    WithdrawModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    NgxPaginationModule,
    MatMenuModule,
    BbQrModule,
    MatCheckboxModule
  ],
  exports: [
    BbQrModule
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerAuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ms: MixpanelService) => () => ms.init(),
      deps: [MixpanelService],
      multi: true
    },
    PageTemplateSerivce,
    UserService,
    AuthenticationService,
    CurrencyService,
    ExternalService,
    BusinessService,
    PaymentListService,
    ApiKeyService,
    ThemeService,
    NfsService,
    IftService,
    ServiceAgreementService,
    StateService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
