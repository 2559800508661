import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";

import { Subscription } from "rxjs";

import { WithdrawRequest, WithdrawRequestList } from "app/models/withdraw";
import { WithdrawService } from "app/services/withdraw.service";
import { PaginationConfig } from 'app/models/paginationConfig';
import { CreateWithdrawComponent } from "./create-request/create-withdraw.component";
import { NotificationService } from "app/services/notification.service";
import { BusinessService } from "app/services/business.service";
import { Account } from "app/models/business";


@Component({
    selector: 'app-withdraw',
    templateUrl: './withdraw.component.html',
    styleUrls: ['./withdraw.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WithdrawComponent implements OnInit {
    public pageTitle: string = "Withdraw Request";
    public paginationConfig = new PaginationConfig('WithdrawRequests', 'withdraw-requests', 10);
    public withdrawListFiltered: WithdrawRequest[];
    public withdrawList: WithdrawRequestList;
    public account: Account;
    public busy: Subscription = null;
    date: Date = new Date();

    range = new FormGroup({
        start: new FormControl(new Date(this.date.setDate(new Date().getDate() - 7))),
        end: new FormControl(new Date())
    });

    constructor(
        private withdrawService: WithdrawService,
        private notif: NotificationService,
        private dialog: MatDialog,
        private businessService: BusinessService) {
    }

    ngOnInit(): void {
        this.account = this.businessService.GetAccountInLocalStorage();
        
        this.loadWithdrawRequests();
    }

    private loadWithdrawRequests() {
        var startDate = new Date(this.range.controls.start.value).toLocaleDateString();
        var endDate = new Date(this.range.controls.end.value).toLocaleDateString();
        this.busy = this.withdrawService.getWithdrawRequests(startDate, endDate, this.paginationConfig.currentPage, this.paginationConfig.itemsPerPage).subscribe(
          data => {
            this.withdrawList = data;
            this.paginationConfig.totalItems=data.Total;
          },
          err => {
            this.notif.error('Failed to load list');
          });
      }

    dateChange() {
        this.loadWithdrawRequests();
    }

    filterList(startDate: Date, endDate: Date) {
        this.withdrawListFiltered = this.withdrawList.List
            .filter(w => w.CreateDate >= startDate && w.CreateDate <= endDate);
    }

    openCreateRequestDialog() {
        const dialogRef = this.dialog.open(CreateWithdrawComponent, {
        });

        dialogRef.afterClosed().subscribe(result => {
            let withdraw = result as WithdrawRequest;
            if (withdraw != undefined && withdraw != null)
                this.withdrawListFiltered.push(withdraw);
            this.dateChange();
        });
    }
    
    paginationPageChanged(pageNumber: number) {
        this.paginationConfig.currentPage = pageNumber;
        this.loadWithdrawRequests();
      }
}

