<div fxLayout fxLayoutAlign="space-between center" class="spenn-pagination py-2" *ngIf="paginationConfig.totalItems>0">
   <span>Showing <span class="spenn-pagination-number">{{paginationConfig.startItem}}</span> to
      <span class="spenn-pagination-number">{{paginationConfig.endItem}}</span> of {{paginationConfig.totalItems}}
      {{paginationConfig.itemType}}</span>
   <div fxLayout fxLayoutAlign="center center">
      <span class="">Page</span>
      <pagination-controls id="{{paginationConfig.id}}" (pageChange)="pageChanged($event)" maxSize="6"
         directionLinks="false" autoHide="false" responsive="true" screenReaderPaginationLabel="Pagination"
         screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
      </pagination-controls>
   </div>
</div>