<app-spinner [spin]="sub"></app-spinner>
<div class="top-up-container-any-bank">
    <div class="title-box" fxLayout="row" fxLayoutAlign="space-between center" >
        <div><h5>Bank Transfer</h5></div> 
        <div>
          <button class="btn-close" (click)="closeDialog()"></button>
        </div>
    </div>
    
    <div class="subtitle-box" fxLayout="row" fxLayoutAlign="center center">
        <h5>Transfer details</h5>
    </div>

    <div class="items-container">

        <div class="info-items-container" >

            <div *ngFor="let item of infoItemsArray" class="info-item">
                <div class="info-name">{{item.ItemName}}:</div>
                <div fxFlex></div>
                <div class="info-value">{{item.ItemValue}}</div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="no-fee spenn-bg">No fees</div>
            <div class="zap">
                <img src="assets/images/zap.png">Receive money in max 12h<img src="assets/images/zap.png">
            </div>
        </div>

        <topup-insturctions [instructions]="instructionsArray"></topup-insturctions>

    </div>


</div>
