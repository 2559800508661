import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CommonService } from "app/services/common.service";
import { MixpanelUtilService } from "app/services/mixpanel-util.service";
import { QrCodeSpennComponent } from "../bb-qr/qr-code-spenn/qr-code-spenn.component";
@Component({
  selector: "app-bb-toolbar",
  templateUrl: "./bb-toolbar.component.html",
  styleUrls: ["./bb-toolbar.component.scss"],
})
export class BbToolbarComponent {
  @Input() menu = true;
  @Input() title = "";
  @Input() dialogToolbar?: boolean;
  public sidenavToggle = false;
  constructor(
    private commonService: CommonService,
    private mixPanelUtilService: MixpanelUtilService,
    private router: Router,
    public dialog: MatDialog
  ) { }
  toggleRightSidenav() {
    this.commonService.toggleSidenav();
  }

  topUpClick() {
    this.trackEvent();
    this.router.navigate(["/topup"]);
  }

  trackEvent() {
    this.mixPanelUtilService.helpEventLog(true, true);
  }

  qrCodeClick() {
    const dialogRef = this.dialog.open(QrCodeSpennComponent, {
      panelClass: "dialog-wrapper",
      width: "500px",
    });
  }
}
