import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { User, ChangePasswordCommand, ChangePhoneNumberCommand, SBWebQrCodeResponse } from "../models/user";
import { Configuration } from "../app.constants";
import { Router, NavigationEnd, Route } from '@angular/router';
import { Token } from '../models/token';


@Injectable()
export class UserService {

    public user: User;
    public token : Token;
    public constructor(private http: HttpClient,private Router: Router ) { }

    public getUserInfo(): Observable<User> {
        var userFormApi = (this.http
            .get(Configuration.GetUserInfo)
            .flatMap((user: Response) => {
                userFormApi = new User(user);
                return Observable.of(userFormApi);
            }))
        return userFormApi;
    }

    public getUserFromLocalStorage(): User {
        let userFromLocalStorage = localStorage.getItem('bb_user');
        if (userFromLocalStorage) {
            this.user = JSON.parse(userFromLocalStorage);
        }
        else {
            this.user = new User();
        }
        return this.user;
    }

    public changeUserPassword(command: ChangePasswordCommand): Observable<object> {
        return this.http.put(Configuration.ChangePasswordUri, {
            currentPassword: command.CurrentPassword,
            newPassword: command.NewPassword
        })
            .catch(err => { return throwError(err) });
    }

    public initiateChangePhoneNumber(): Observable<any> {
        return this.http.get(Configuration.InitiateChangeNumberUri)
            .catch(err => { return throwError(err) });
    }

    public confirmChangePhoneNumber(command: ChangePhoneNumberCommand): Observable<object> {
        return this.http.post(Configuration.ConfirmChangeNumberUri, command)
            .catch(err => { return throwError(err) });
    }

    public sendOtp(): Observable<any> {
        return this.http.get(Configuration.SendOtp)
            .catch(err => { return throwError(err) });
    }

    public GetQrCode(): Observable<SBWebQrCodeResponse> {
        return this.http.get(Configuration.SBWebGetQrInformation)
            .map(data => { return new SBWebQrCodeResponse(data); })
            .catch(err => { return throwError(err) });
    }

}