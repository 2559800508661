<div fxLayout="row" fxLayout.xs="row" fxLayoutGap="2" id="grad1" fxFlexFill>
    <div fxFlex="60" fxFlexOffset="4" fxFlex.xs="100" fxLayout="column" fxLayoutGap="5" fxLayoutAlign="center start">
        <div fxLayoutAlign="start end">
            <img class="img-logo" src="assets/images/latest_logo/SPENN_Logotype_White_Business.svg">
        </div>
        <div fxFlex="60" fxFlex.sm="60" fxFlex.xs="60" fxLayoutAlign="center start" fxLayout="column">
            <h1 class="heading">Switch. <br /> Save. Grow.</h1>
            <h5 class="sub-heading">With SPENN Business</h5>
            <button mat-flat-button class="btn1" color="primary" (click)="landingButtonClicked()">LOG IN</button>
        </div>

        <div fxFlex="20" fxFlex.sm="none" fxFlex.xs="none" fxLayoutAlign="start start">
            <p class="ele">
                <strong>Need Further Assistance?</strong> <br>
                Contact us Monday to Friday, 8am to 6pm <br>
                - <br>
                Rwanda +250 781 288 255 &nbsp; | &nbsp; Tanzania +255 758 909 999 &nbsp; | &nbsp; Zambia +260 975 944
                968
            </p>
        </div>
    </div>
    <img class="webMoileView" src="assets/images/landing_img3-min.webp" alt="">
</div>