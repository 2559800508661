import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { Papa } from 'ngx-papaparse';

import { PaginationConfig } from 'app/models/paginationConfig';
import { ShareDataService } from 'app/services/share-data.service';
import { FailedPaymentListItem, PaymentListType } from 'app/models/paymentList';
import { CustomerType } from 'app/models/enums/customerType';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-failed-payment-list-item-view',
  templateUrl: './failed-payment-list-item-view.component.html',
  styleUrls: ['./failed-payment-list-item-view.component.scss']
})
export class FailedPaymentListItemViewComponent implements OnInit, OnDestroy {
  public paginationConfig = new PaginationConfig('failed-items', 'failed-item', 10);
  public pageTitle: string = "Error Overview";
  failedItems: Array<FailedPaymentListItem>;
  subscription: Subscription;
  receiverType: CustomerType;
  CustomerType = CustomerType;
  isMessageSupportTransaction: boolean;
  paymentId: string;

  constructor(private dataShareService: ShareDataService,
    private papa: Papa,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnDestroy(): void {
    this.dataShareService.failedItems = [];
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loadFailedItems();
    this.route.paramMap.subscribe(params =>
      {
        this.paymentId = params.get('paymentId');
      }
    )
  }

  paginationPageChanged(pageNumber: number) {
    this.paginationConfig.currentPage = pageNumber;
    this.loadFailedItems();
  }

  loadFailedItems() {
    this.subscription = this.dataShareService._failedItemSubscription.subscribe((data) => {
      if (data) {
        console.log(data);
        let startIndex = (this.paginationConfig.currentPage - 1) * this.paginationConfig.itemsPerPage;
        let endIndex = this.paginationConfig.currentPage * this.paginationConfig.itemsPerPage;
        this.failedItems = data.slice(startIndex, endIndex);
        this.paginationConfig.totalItems = data.length;
      }
    },
      error => {
        console.log(error);
      }
    );
    if (this.failedItems && this.failedItems.length > 0) {
      this.receiverType = this.failedItems[0].RecipientType;
    }



  }

  exportCSV() {

    let columns: Array<string>;
    if (this.receiverType == CustomerType.Customer)
      columns = ["PhoneNumber", "Amount"]
    else
      columns = ["AgentNumber", "Amount"]

    if (this.failedItems[0].TransactionType == PaymentListType.SpennTransaction) {
      columns.push("Message");
    }
    var csv = this.papa.unparse(this.dataShareService.failedItems, {
      skipEmptyLines: true,
      header: true,
      columns: columns
    });

    const a = document.createElement('a');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'error.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  navigateToPayment(){
    this.router.navigate(['/payments', this.paymentId])
  }
}
