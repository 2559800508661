import { Component, OnInit } from '@angular/core'; 

import { AuthenticationService } from '../../services/auth.service'; 

@Component({
  selector: 'user-lock',
  templateUrl: './user-lock.component.html',
  styleUrls: ['./user-lock.component.scss']
})
export class UserLockComponent implements OnInit {

  constructor(private auth: AuthenticationService) { }

  ngOnInit() { 
  }

  logout(){
    this.auth.logout();
  }

}
