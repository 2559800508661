import { Injectable } from "@angular/core";

import { Subject } from "rxjs";

@Injectable()
export class PageTemplateSerivce {

    private pageTitleSource = new Subject<string>();

    pageTitleUpdated$ = this.pageTitleSource.asObservable();

    updatePageTitle(title: string) {
        this.pageTitleSource.next(title);
    }
}