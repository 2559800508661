import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {PageTemplateSerivce} from '../../services/page-template.service';
import { ExternalService } from '../../services/external.service';
import { UserService } from '../../services/user.service';
import { Account, Country } from '../../models/business';
import { User, ChangePasswordCommand, ChangePhoneNumberCommand } from '../../models/user';
import { NotificationService } from '../../services/notification.service';
import { AuthenticationService } from '../../services/auth.service';
import { BusinessService } from 'app/services/business.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',  
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit { 
  public account: Account = new Account();
  public user: User;
  public countries: Country[] = [];
  public countryId: number = null;
  public busy: Subscription;
  public changePasswordCommand: ChangePasswordCommand = new ChangePasswordCommand();
  public submitBtnDisabled: boolean = false;
  public phoneNumberChangeRequested = false;
  public newPhoneNumber: string;
  public otp: string;
  public maskedPhoneNumberAndEmail: string;

  constructor(private pageTemplateService: PageTemplateSerivce,
    private userService: UserService,
    private externalService: ExternalService,
    private notif: NotificationService,
    private router: Router,
    private authService: AuthenticationService,
    private businessService: BusinessService) { }

  ngOnInit() {
    this.pageTemplateService.updatePageTitle("Profile");
    this.busy = this.userService.getUserInfo().subscribe(
      data => {
        this.user = data;

        if(!this.user.IsRequiredPasswordChange) {
          this.busy = this.externalService.getCountries().subscribe(data => {
            this.countries = data;
            var otherCountry = {...new Country (), countryId: 0, name: 'Other' };
            this.countries.push(otherCountry);
          }, error => {
            this.notif.error('Failed to fetch country list');
            console.log(error);
          });
        }        

      }, error => {
        this.notif.error('Could not get user information');
      });
     
  }

  onSubmit() {
    try {
      this.changePasswordCommand.validate();
    } catch(err) {
      this.notif.error(err);
      return ;
    }

    this.submitBtnDisabled = true;

    this.busy = this.userService.changeUserPassword(this.changePasswordCommand).subscribe(
      () => {
        this.notif.success("Password changed successfully");
        this.authService.updateCurrentUserPasswordChangeStatus();
        this.loadAccountBalance();
        this.router.navigate(['payments']);
      }, 
      err => {
        console.log(err);
        this.submitBtnDisabled = false;
      }
    );
  }


  requestPhoneChange(): void {

    var command = new ChangePhoneNumberCommand(this.newPhoneNumber, this.countryId,  this.otp);

    if(this.validatePhoneChangeCommand(command)) {
      this.busy = this.userService.initiateChangePhoneNumber().subscribe(
        (result) => {
          this.phoneNumberChangeRequested = true;
          this.maskedPhoneNumberAndEmail = Object.values(result)[1].toString();
      }, error => {
        this.notif.error('Request for verification code failed');
      });
    }
    

  }

  confirmPhoneChage(): void {
    var command = new ChangePhoneNumberCommand(this.newPhoneNumber.trim(), this.countryId,  this.otp.trim());

    if(this.validatePhoneChangeCommand(command)) {

      this.busy = this.userService.confirmChangePhoneNumber(command).subscribe(
        () => {
          this.notif.success('Phone number changed successfully');
          this.user.TwoFaPhoneNumber = this.newPhoneNumber.trim();
          this.phoneNumberChangeRequested = false;
          this.newPhoneNumber = '';
        },
        error => {
          this.notif.error('Phone number change request failed');
          this.phoneNumberChangeRequested = false;
        });
    }


  }

  validatePhoneChangeCommand(command: ChangePhoneNumberCommand ): boolean {
    if(!command.CountryId || command.CountryId+'' == 'null') {
      this.notif.warning('Please select a country');
      return false;
    }

    
    if(!this.isValidPhoneNumber(command)){
      this.notif.warning('Please give a valid phone number');
      return false;
    }
    
    return true;
  }

  isValidPhoneNumber(command: ChangePhoneNumberCommand): boolean {
    if(command.NewPhoneNumber) {
      let strValue = command.NewPhoneNumber.trim().replace('+', '');
      if(strValue.length < 5 || isNaN(Number(strValue))) {
        return false;
      }
      else {
        command.NewPhoneNumber = '+' + strValue;
        return true;
      }
    }    

    return false;
  }

  cancelPhoneChange(): void {
    this.phoneNumberChangeRequested = false;
    this.newPhoneNumber = '';
  }

  loadAccountBalance() {  
    this.busy = this.businessService.GetCurrentBalance()
      .subscribe(data => {         
          this.account = data;            
        }, error => {
          this.notif.error('Could not load account balance'); 
        }
      );
  }
}
