import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private showServiceAgreement: boolean = false;

  setShowServiceAgreement(value: boolean) {
    this.showServiceAgreement = value;
  }

  getShowServiceAgreement(): boolean {
    return this.showServiceAgreement;
  }
}