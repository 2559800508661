import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { NotificationService } from 'app/services/notification.service';
import { InfoItem } from '../../common/info-item';
import { TopupService } from '../../topup.service';

@Component({
  selector: 'app-any-bank',
  templateUrl: './any-bank.component.html',
  styleUrls: ['./any-bank.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnyBankComponent implements OnInit, OnDestroy {

  infoItemsArray: Array<InfoItem> = new Array<InfoItem>();
  instructionsArray: string[];
  rewardCode: string;
  sub : Subscription = null;


  constructor(private dialogRef: MatDialogRef<AnyBankComponent>, 
    private topupService: TopupService, 
    private notif: NotificationService) { }

  ngOnDestroy(): void {
    if(this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.sub = this.topupService.getRewardCode().subscribe(code => {
      this.rewardCode = code;

      this.getInstructions();
      this.getInfoItems();  
    }, error => {
      this.rewardCode = '[Spenn reward code]';

      this.getInstructions();
      this.getInfoItems();  
      this.notif.error('Could not get reward code of customer');
    });
    
  }

  closeDialog() {
    this.dialogRef.close();
  }

  
  getInfoItems() {
    this.infoItemsArray = new Array<InfoItem>();
    this.infoItemsArray.push({ ItemName: 'Narration', ItemValue: this.rewardCode});
    this.infoItemsArray.push({ ItemName: 'Bank', ItemValue: 'I&M Bank'});
    this.infoItemsArray.push({ ItemName: 'Beneficiary', ItemValue: 'SPENN Rwanda Ltd'});
    this.infoItemsArray.push({ ItemName: 'Account number', ItemValue: '25049787003'});
  }

  getInstructions() {
    this.instructionsArray = [
      'Initiate a bank transfer via your preferred method (branch, online or mobile banking).',
      'Add the bank transfer details and your unique narration as per the above.',
      'Enter the amount to top up.',
      'Verify details and complete the transfer.'
    ]
  }

}
