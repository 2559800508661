import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Observable, throwError } from "rxjs";

import { Configuration } from "../app.constants";
import { ApiKey, ApiKeyList, PermissionGroup } from 'app/models/apikey';

@Injectable({providedIn: 'root'})
export class ApiKeyService{

    constructor(private http: HttpClient) { }

    private permissionGroupList = new Array<PermissionGroup>();  

    public GetApiKeys(page: number = 1, pageSize: number = 10): Observable<ApiKeyList> {
        var params = new HttpParams();
        params = params.set('page', page.toString());
        params = params.set('pageSize', pageSize.toString());
        return this.GetPermissionGroups().concatMapTo(
            this.http.get(Configuration.ApiKeysUri, {params: params})
                .map(data => { return new ApiKeyList(data, this.permissionGroupList, true) })
                .catch(err => { return throwError(err) })
        );
    }

    public CreateApiKey(apiKey: ApiKey) : Observable<ApiKey> {
        var command  = {
            apiKeyName: apiKey.ApiKeyName,
            permissionGroupGuids: apiKey.GetCheckedPermissionGuids()
        };
        return this.http.post(Configuration.ApiKeysUri, command)
            .map(data => { return new ApiKey(data, this.permissionGroupList, true) })
            .catch(err => { return throwError(err) });
    }

    public UpdateApiKey(apiKey: ApiKey) : Observable<ApiKey>{
        var command = {
            permissionGroupGuids: apiKey.GetCheckedPermissionGuids()
        };
        return this.http.put(`${Configuration.ApiKeysUri}/${apiKey.ApiKeyGuid}`, command)
            .map(data => { return new ApiKey(data, this.permissionGroupList, true) })
            .catch(err => { return throwError(err) });
    }

    public RevokeApiKey(apiKey: ApiKey) : Observable<ApiKey>{
        return this.http.put(`${Configuration.ApiKeysUri}/${apiKey.ApiKeyGuid}/revoke`, {})
            .map(data => { return new ApiKey(data, this.permissionGroupList, true) })
            .catch(err => { return throwError(err) });
    }

    public GetPermissionGroupsFromApi(): Observable<PermissionGroup[]> {
        return this.http.get(Configuration.ApiKeyPermissionGroupsUri)
            .map(data => { return PermissionGroup.ParseArray(data) })
            .catch(err => { return throwError(err) });
    }

    public GetPermissionGroups() : Observable<PermissionGroup[]> {
        if(this.permissionGroupList.length != 0) {
            return Observable.of(this.permissionGroupList);
        } else {
            return this.GetPermissionGroupsFromApi().map(groups => {
                this.permissionGroupList = groups;
                PermissionGroup.SortGroups(this.permissionGroupList);
                return groups;
            })
        }
    }
}