import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FlexLayoutModule } from "@angular/flex-layout";

import { ChartsModule } from "ng2-charts";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { NgxPaginationModule } from "ngx-pagination";

import { SpinnerComponent } from "./spinner/spinner.component";
import { BbLineChartComponent } from "./bb-line-chart/bb-line-chart.component";
import { CreatePaymentComponent } from "./create-payment/create-payment.component";
import { ClonePaymentComponent } from "./clone-payment/clone-payment.component";
import { EditPaymentComponent } from "./edit-payment/edit-payment.component";
import { BbToolbarModule } from "./bb-toolbar/bb-toolbar.module";
import {MatRadioModule} from '@angular/material/radio';
import {
  MoneyFormatter,
  CurrencyFormatter,
} from "../../pipes/money-formatter.pipe";
import {
  TimeAgoPipe,
  TimeDotPipe,
  TimeWithDatePipe,
  LocalDateTimePipe,
  DateSlashPipe,
} from "../../pipes/time-ago.pipe";
import { PaginationComponent } from "./pagination/pagination.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule.forRoot(),
    NgxPaginationModule,
    ChartsModule,
    BbToolbarModule,
    MatIconModule,
    MatDialogModule,
    FlexLayoutModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
  ],
  declarations: [
    MoneyFormatter,
    CurrencyFormatter,
    PaginationComponent,
    SpinnerComponent,
    TimeAgoPipe,
    TimeDotPipe,
    TimeWithDatePipe,
    LocalDateTimePipe,
    DateSlashPipe,
    BbLineChartComponent,
    CreatePaymentComponent,
    ClonePaymentComponent,
    EditPaymentComponent,
  ],
  providers: [
    MoneyFormatter,
    CurrencyFormatter,
    TimeAgoPipe,
    TimeDotPipe,
    TimeWithDatePipe,
    LocalDateTimePipe,
    DateSlashPipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  exports: [
    MoneyFormatter,
    CurrencyFormatter,
    TimeAgoPipe,
    TimeDotPipe,
    TimeWithDatePipe,
    LocalDateTimePipe,
    DateSlashPipe,
    PaginationComponent,
    SpinnerComponent,
    BbLineChartComponent,
    BbToolbarModule,
    CreatePaymentComponent,
  ],
})
export class SharedModule { }
