import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { Subscription } from 'rxjs';

import {PageTemplateSerivce} from '../../services/page-template.service';
import { AuthenticationService } from '../../services/auth.service';
import { BusinessService } from '../../services/business.service';
import { User } from '../../models/user';
import { Account } from '../../models/business';
import { PaymentListDetailsComponent } from '../payments/paymentlist-details/paymentlist-details.component';
import { PaymentListStatus } from 'app/models/paymentList';
import { CommonService } from 'app/services/common.service';

declare var CleverPush: any;

@Component({
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss'],
  providers: [PageTemplateSerivce, BusinessService],
  encapsulation: ViewEncapsulation.None
})

export class PageTemplateComponent implements OnInit {
  pageTitle: string;
  currentUser: User;
  public account: Account = new Account();
  public busy: Subscription;
  public paymentListComponent: PaymentListDetailsComponent;
  public showBalance = false;
  @ViewChild('sidenav') public sidenav: MatSidenav;
  public opened = false;

  PaymentListStatus = PaymentListStatus;

  constructor(private pageTemplateService: PageTemplateSerivce,
    private authenticationService: AuthenticationService,
    private businessService: BusinessService,private commonService:CommonService) {
    this.pageTemplateService.pageTitleUpdated$.subscribe(
      title => {
        this.pageTitle = title;
      }
    );

    this.authenticationService.currentUser$.subscribe(
      user => {
        this.currentUser = user;
      }
    );
   }

  ngOnInit() {
    this.currentUser = this.authenticationService.getCurrentUserValue();
  }

  loadAccountBalance() {
    this.busy = this.businessService.GetCurrentBalance().subscribe(
      data => {
        this.account = data;
      }, error => {
        console.log(error);
      }
    );
  }

  onActivate() { 
    if(this.showBalance == false) {
      this.showBalance = true;
      this.loadAccountBalance();
    } 
  }
  onResize(event) {
    let width;

    if(event != undefined){
      width = event.target.innerWidth;
       console.log(event.target.innerWidth);
    }
    else{
       console.log(document.body.clientWidth);
      width = document.body.clientWidth;
    }

    if (width >= 720) {
      this.sidenav.open();
    } 
    else {
      this.sidenav.close();
    }

  }

  ngAfterViewInit(): void {
    this.commonService.setSidenav(this.sidenav);
  }

}
