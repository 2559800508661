
<div class="top-up-container-kazang">
    <div class="title-box" fxLayout="row" fxLayoutAlign="space-between center" >
        <div><h5>Kazang</h5></div> 
        <div>
          <button class="btn-close" (click)="closeDialog()"></button>
        </div>
    </div>
    
    <div class="subtitle-box" fxLayout="row" fxLayoutAlign="center center">
        <h5>Instructions</h5>
    </div>

    <div class="items-container">

        <topup-insturctions [instructions]="instructionsArray"></topup-insturctions>

    </div>


</div>
