import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { topupRoutes } from './topup.routes';
import { SharedModule } from "../shared/shared.module";
import { CrdbComponent } from './modals/CRDB/crdb.component';
import { BankBranchComponent } from './modals/bank-branch/bank-branch.component';
import { SpennAgentComponent } from './modals/spenn-agent/spenn-agent.component';
import { InsturctionsComponent } from './common/insturctions.component';
import { AnyBankComponent } from './modals/any-bank/any-bank.component';
import { PayRwComponent } from './modals/pay-rw/pay-rw.component';
import { KazangComponent } from './modals/kazang/kazang.component';
import { TopupComponent } from './topup.component';
import { BankMomoComponent } from './modals/bank-momo/bankmomo.component';

@NgModule({
  declarations: [
    TopupComponent,
    CrdbComponent,
    BankBranchComponent,
    SpennAgentComponent,
    InsturctionsComponent,
    AnyBankComponent,
    PayRwComponent,
    KazangComponent,
    BankMomoComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SharedModule,
    RouterModule.forChild(topupRoutes)
  ]
})
export class TopupModule { }
