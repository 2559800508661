import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'app/app.constants';
import NfsInformationItem, { NfsPaymentApiView, NfsInitiateCashInView, NfsConfirmCashInView, NfsPaymentListDetailsView, NfsCashInItem, NfsCloneObj, NfsBulkInsertModel, NfsBulkInportRespose, NfsPaymentSlipDetailsForBusinessView } from 'app/models/nfs';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class NfsService {

  constructor(
    private http: HttpClient
  ) { }

  public lookup(): Observable<NfsPaymentApiView> {
    return this.http.get(Configuration.NfsCashInLookup)
      .map(data => { return new NfsPaymentApiView(data) })
      .catch(err => throwError(err));
  }

  public sendOtp(): Observable<any> {
    return this.http.post(Configuration.NfsCashInSendOtp, {})
      .map(data => { return data })
      .catch(err => throwError(err))
  }

  public initiateNfsCashIn(nfsProductId: number, amount: number, accountReference: string, senderReference: string): Observable<NfsInitiateCashInView> {
    let command = {
      NfsProductId: nfsProductId,
      Amount: amount,
      AccountReference: accountReference,
      SenderReference: senderReference
    }
    return this.http.post(Configuration.NfsCashInInitiate, command)
      .map(result => { return new NfsInitiateCashInView(result) })
      .catch(err => throwError(err));
  }

  public confirmNfsCashIn(otp: string, nfsPaymentGuid: string): Observable<NfsConfirmCashInView> {
    let command = {
      NfsPaymentGuid: nfsPaymentGuid,
      Otp: otp
    }
    return this.http.post(Configuration.NfsCashInConfirm, command)
      .map(result => { return new NfsConfirmCashInView(result); })
      .catch(err => throwError(err));
  }

  public getPaymentList(paymentListId: number): Observable<NfsPaymentListDetailsView> {
    return this.http.get(Configuration.NfsPaymentListUri + '/' + paymentListId)
      .map(result => { return new NfsPaymentListDetailsView(result); })
      .catch(err => { return throwError(err) });
  }

  public updateNfsPaymentListItem(paymentListId: number, item: NfsCashInItem): Observable<NfsInformationItem> {
    return this.http.put(Configuration.NfsPaymentListUri + '/' + paymentListId, item)
      .map(result => { return new NfsInformationItem(result); })
      .catch(err => { return throwError(err) });
  }

  public removeNfsPaymentListItem(paymentListId: number): Observable<any> {
    return this.http.delete(Configuration.NfsPaymentListUri + '/' + paymentListId)
      .catch(err => { return throwError(err) });
  }

  public clonePaymentList(item: NfsCloneObj): Observable<any> {
    return this.http.post(Configuration.NfsClonePaymentListUri, item)
      .map(result => { return result; })
      .catch(err => { return throwError(err) });
  }

  public addNfsBulkPaymentListItem(paymentListId: number, input: NfsBulkInsertModel): Observable<NfsBulkInportRespose> {

    return this.http.post(Configuration.NfsUri + '/' + paymentListId + '/bulkAddRecipients', input)
      .map(result => { return new NfsBulkInportRespose(result) })
      .catch(err => { return throwError(err) });
  }

  public processPaymentForNfsBulkPayment(paymentListId: number): Observable<any> {
    return this.http.post(Configuration.NfsUri + '/' + paymentListId + '/user/otp', {})
      .map(data => { return data })
      .catch(err => throwError(err))
  }

  public verifyNfsBulkPaymentOtp(paymentListId: number, code: string): Observable<any> {
    var command = {
      otp: code,
      paymentListId: paymentListId
    };
    return this.http.post(Configuration.NfsUri + '/bulkpayment', command)
      .map(data => { return data })
      .catch(err => { return throwError(err) });
  }

  public getNfsPaymentSlipDetailsForBusiness(id: number): Observable<NfsPaymentSlipDetailsForBusinessView> {

    return this.http.get(Configuration.NfsPaymentSlipDetailsUri + '/' + id)
      .map(data => { return new NfsPaymentSlipDetailsForBusinessView(data) })
      .catch(err => { return throwError(err) });
  }

  public getPaymentSlip(id: number): Observable<any> {

    const httpOptions = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    };
    return this.http.get(Configuration.NfsPaymentSliPdfUri + '/' + id, httpOptions)
      .map((response: any) => {
        if (response.status == 200) {
          let fileName = `Nfs_Payment-Slip_${id}`;
          response.body.fileName = fileName;
          return response.body;
        }
      })
      .catch(err => { return throwError(err) });
  }
}
