<app-bb-toolbar [title]="pageTitle"></app-bb-toolbar>

<div class="container-fluid payment-page faildpayment" ngClass.lt-md="mobile">
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlexAlign="start">
                <button class="btn-transparent my-1 me-md-5 me-2" *ngIf="failedItems" (click)="navigateToPayment()"> &lt; Return to Payment draft</button>
            </div>
            <div fxFlexAlign="end">
                <button mat-raised-button color="primary" *ngIf="failedItems" (click)="exportCSV()"
                    class="spenn-btn mat-button-sm">Download</button>
            </div>
        </div>
        <div>
            <mat-tab-group [selectedIndex]="0">
                <mat-tab label="Preview">
                    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between stretch">
                        <div></div>
                        <div fxLayout="row">
                            <div fxFlexOffset="2px">
                                Please find below description of errors which cause failure during uploading of the
                                recipient's document.
                            </div>
                        </div>
                        <div>
                            <table *ngIf="failedItems" class="table">
                                <thead>
                                    <th>#</th>
                                    <th *ngIf="receiverType==CustomerType.Customer">PhoneNumber</th>
                                    <th *ngIf="receiverType==CustomerType.Agent">AgentNumber</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Description</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of failedItems | paginate: paginationConfig;  let i = index">
                                        <td>{{(paginationConfig.currentPage-1)*paginationConfig.itemsPerPage+(i+1)}}
                                        </td>
                                        <td *ngIf="item.RecipientType==CustomerType.Customer">{{item.PhoneNumber}}</td>
                                        <td *ngIf="item.RecipientType==CustomerType.Agent">{{item.AgentNumber}}</td>
                                        <td>{{item.Amount}}</td>
                                        <td class="text-danger">{{item.Status}}</td>
                                        <td>
                                            <div innerHTML="{{ item.Description }}"></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div>

            </div>

            <app-pagination [paginationConfig]="paginationConfig" (pageChangedCallback)="paginationPageChanged($event)">
            </app-pagination>
        </div>
    </div>
</div>