export class PaginationConfig {
    id: string;
    itemsPerPage: number;
    currentPage: number;
    totalItems: number;
    itemType: string;

    public get startItem(): number {
        return ((this.currentPage - 1) * this.itemsPerPage) + 1;
    }

    public get endItem(): number {
        return Math.min(this.totalItems, this.currentPage * this.itemsPerPage);
    }

    public constructor(itemType?: string, id?: string, itemsPerPage?: number) {
        this.id = id && id || "pagination";
        this.itemsPerPage = itemsPerPage && itemsPerPage || 10;
        this.currentPage = 1;
        this.totalItems = 0;
        this.itemType = itemType && itemType || "items";
    }
}