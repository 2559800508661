import { Injectable } from "@angular/core";

import { Currency } from 'app/models/business';

@Injectable()
export class CurrencyService {
    private CurrentCurrency: Currency;

    public constructor() {
    }  

    public format(amount: number, showCurrency: boolean = false) {
        if (!amount) amount = 0;
        let currency = this.getCurrentCurrency() || new Currency();
        var number = parseFloat(amount+'')
            .toFixed(currency.MaxDecimalNumber)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if(showCurrency)
            number = currency.DisplayCode + ' ' + number;
        return number;
    }

    public formatCurrency() {
        let currency = this.getCurrentCurrency();
        return currency ? currency.DisplayCode : '';
    }

    getCurrentCurrency() : Currency {
        if(this.CurrentCurrency != null) 
            return this.CurrentCurrency;
        var currentUser = JSON.parse(localStorage.getItem('bb_user'));
        if(currentUser.SelectedBusiness && currentUser.SelectedBusiness.Currency)
            return currentUser.SelectedBusiness.Currency;
        else
            return new Currency();
    }
    
    toFixedMaxDecimal(amount: number) : number {
        var currency = this.getCurrentCurrency();
        return parseFloat(parseFloat(amount+'').toFixed(currency.MaxDecimalNumber));
    }
}