import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { NotificationService } from '../services/notification.service';
import { AuthenticationService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private notify: NotificationService,
    private Router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          err instanceof HttpErrorResponse &&
          err.error instanceof Blob &&
          err.error.type === 'application/json'
        ) {
          const jsonError = new Response(err.error).json().then((info) => {
            this.notify.error(`${info.Message}`);
          });
        } else {
          if (err.status === 401) {
            // auto logout if 401 response returned from api
            console.log(
              `Error occurred with HttpStatus- ${err.status}, StatusText- ${err.statusText}`
            );
            this.authenticationService.logout();
          } else if (err.status === 403) {
            this.notify.error(
              'You are not authorized to perform this operation!'
            );
          } else if (err.error.ErrorCode == 10052998) {
            this.Router.navigate(['/', 'AccessDenied']);
          } else if (err.error && err.error.Message) {
            this.notify.error(`${err.error.Message}`);
            const error = err.error.Message;
            return throwError(error);
          } else {
            this.notify.error(
              `Error occurred with HttpStatus- ${err.status}, StatusText- ${err.statusText}`
            );
          }
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
