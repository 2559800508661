<app-spinner [spin]="busy"></app-spinner>
<div class="top-up-container-spenn-agent">
    <div class="title-box" fxLayout="row" fxLayoutAlign="space-between center" >
        <div><h5>Spenn Agent</h5></div> 
        <div>
          <button class="btn-close" (click)="closeDialog()"></button>
        </div>
    </div>

    <div class="spenn-agent-content-box" fxLayout="column" fxLayoutAlign="space-between center">

        <div>
            <img [src]='imageBase64'>
        </div>

        <div>
            <span>Show the above QRCode to the SPENN Agent </span>
        </div>

    </div>

</div>
