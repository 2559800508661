import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "app/app.constants";
import { Observable, throwError } from "rxjs";


@Injectable()
export class ServiceAgreementService {
    constructor(
        private http: HttpClient
      ) { }
    
    public IsEligibleForAgreement(): Observable<any>{
        return this.http.get(Configuration.IsEligibleForAgreement)
        .catch(err => { return throwError(err) });
    }
    
    public DeclineAgreement(): Observable<any>{
        return this.http.put(Configuration.DeclineAgreement, null)
        .catch(err => {return throwError(err)}); 
    }

    public AcceptAgreement(): Observable<any>{
        return this.http.put(Configuration.AcceptAgreement, null)
        .catch(err => {return throwError(err)}); 
    }

    public DownloadAgreement(): Observable<any>{
        const httpOptions = {
            responseType: 'blob' as 'json',
            observe: 'response' as 'body'
          };
        return this.http.get(Configuration.DownloadAgreement, httpOptions)
        .map((response: any) => {
            if (response.status == 200) {
              let fileName = response.headers.get('x-file-name');
              response.body.fileName = fileName;
              return response.body;
            }
          })
        .catch((error: HttpErrorResponse) => {
            return Observable.throw(error);
        });
    }
}