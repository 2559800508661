import { formatDate } from '@angular/common';
import { CustomerType } from './enums/customerType';

export enum PaymentListStatus {
    Draft = 0,
    Pending,
    Completed
}

export enum PaymentListType {
    SpennTransaction = 0,
    AirtimeTransaction,
    NfsCashInTransaction,
    BankMomoTransaction,
}

export enum TransactionType {
    Transfer = 1,
    AirTime = 8,
    NonUserTransfer = 9,
    PartnerDeposit = 13,
    Salary = 18
}

export enum NfsStatusCodes {
    None = 0,
    Pending,
    Ok,
    Failed,
    ValidationFailed,
    Review
}

export enum MoneyTransferStatusCodes {
    None = 0,
    SpennTransactionInitiated = 1,
    SpennTransactionFailed,
    SpennFeeTransactionInitiated,
    SpennFeeTransactionFailed,
    ProviderPaymetCompleted,
    ProviderPaymetFailed,
    Completed,
    Failed,
    Review,
    SpennTransactionRefundInitiated,
    SpennTransactionRefundFailed,
    SpennFeeTransactionRefundInitiated,
    SpennFeeTransactionRefundFailed,
    CompletedWithRefund,
    Retry
}

export class PaymentList {
    Id: number;
    Title: string;
    Message: string;
    BusinessId: number;
    PaymentListStatus: PaymentListStatus;
    PaymentListType: PaymentListType;
    RecipientType: CustomerType;
    IsSalary: boolean;
    ItemCount: number;
    CreatedBy: string;
    CreateDate: string;
    InitiatedBy: string;
    InitiateDate: string;
    CountOfFailed: number;
    CountOfOk: number;
    CountOfPending: number;
    SumOfOk: number;
    CountOfSpennRecipients: number;
    CountOfNonUserRecipients: number;
    TotalAmount: number = 0;
    CountOfPendingNonUserFundSend: number;
    UiTitles: PaymentListTypeUiTitles = new PaymentListTypeUiTitles();

    public constructor(data: any = {}) {
        this.Id = data.paymentListId || '';
        this.Title = data.title || '';
        this.Message = data.message || '';
        this.ItemCount = data.itemCount ? data.itemCount : 0;
        this.BusinessId = data.businessId || '';
        this.CreateDate = data['createDate'] ? formatDate(data['createDate'], 'medium', 'en-US') : '';
        this.InitiateDate = data['initiateDateTime'] ? formatDate(data['initiateDateTime'], 'medium', 'en-US') : '';
        this.TotalAmount = data.totalAmount ? data.totalAmount : 0;
        this.CreatedBy = data.createdBy;
        this.InitiatedBy = data.initiatedBy;
        this.PaymentListStatus = data.paymentListStatus;
        this.PaymentListType = data.paymentListType || PaymentListType.SpennTransaction;
        this.RecipientType = data.recipientType;
        this.IsSalary = data.isSalary || false;
        this.CountOfFailed = data.countOfFailed || 0;
        this.CountOfOk = data.countOfOk || 0;
        this.CountOfPending = data.countOfPending || 0;
        this.SumOfOk = data.sumOfOk || 0;
        this.CountOfSpennRecipients = data.countOfSpennRecipients || 0;
        this.CountOfPendingNonUserFundSend = data.countOfPendingNonUserFundSend || 0;
        this.CountOfNonUserRecipients = data.countOfNonUserRecipients || 0;
        this.UiTitles.setPaymentListType(this.PaymentListType, this.IsSalary);
    }

    public validateInput(): void {
        if (this.Title == null || this.Title.trim().length == 0)
            throw new Error("Title is required");
        if (this.Title.trim().length > 100)
            throw new Error("Title should contain maximum of 100 characters");
        if ((this.PaymentListType == PaymentListType.SpennTransaction || this.PaymentListType == PaymentListType.NfsCashInTransaction) && (this.Message == null || this.Message.trim().length == 0))
            throw new Error("Message is required");
        if ((this.PaymentListType == PaymentListType.SpennTransaction || this.PaymentListType == PaymentListType.NfsCashInTransaction) && (this.Message.trim().length > 100))
            throw new Error("Message should contain maximum of 100 characters");
        if (this.BusinessId == null || this.BusinessId == 0)
            throw new Error("Business Id is not set");
        console.log(CustomerType.Customer, "CustomerType.Customer");
        console.log(this.RecipientType, "this.RecipientType");

        if (this.isCloneList() == false && this.PaymentListType != PaymentListType.NfsCashInTransaction && this.PaymentListType != PaymentListType.BankMomoTransaction && this.RecipientType != CustomerType.Customer && this.RecipientType != CustomerType.Agent)
            throw new Error("Please select a recipient type");
    }

    public isCloneList(): boolean {
        return this.Id > 0;
    }

    public static ConvertToArray(data): Array<PaymentList> {
        let arr = Array<PaymentList>();
        data.forEach(item => {
            arr.push(new PaymentList(item));
        });
        return arr;
    }
}

export class PaymentListDetails extends PaymentList {
    Items: PaymentListItem[];
    SumOfPending: number;
    SumOfFailed: number;

    public constructor(data: any = {}) {
        super(data);
        this.Items = [];
        this.SumOfPending = data.sumOfPending || 0;
        this.SumOfFailed = data.sumOfFailed || 0;

        if (data.items) {
            data.items.forEach(item => {
                this.Items.push(new PaymentListItem(item));
            });
        }
    }
}

export class PaymentTypeViewObj {
    TypeId: number;
    Name: string;
    public constructor(data: any = {}) {
        this.Name = data.name;
        this.TypeId = data.typeId;
    }
}

export class PaymentListItem {
    Id: number;
    Phone: string;
    RewardCode: string;
    AgentNumber: string;
    Name: string;
    CustomerGuid: string;
    Status: string;
    EditStatus: boolean;
    Amount: number = 0;
    EditTempAmount: number = 0;
    ThirdPartyProviderName: string;
    ThirdPartyTransactionStatus: number = 0;
    TransactionType: TransactionType;
    ExternalTransactionGuid: string;
    Message: string;

    public constructor(data: any = {}) {
        this.Id = data.paymentListItemId || 0;
        this.CustomerGuid = data.spennCustomerGuid || '';
        this.Phone = data.recipientPhoneNumber || '';
        this.Name = data.recipientName || '';
        this.RewardCode = data.recipientRewardCode || '';
        this.Amount = data.amount || 0;
        this.AgentNumber = data.recipientAgentNumber ? data.recipientAgentNumber : '';
        this.Status = data.status ? data.status : null;
        this.EditStatus = false;
        this.EditTempAmount = this.Amount;
        this.ThirdPartyProviderName = data.thirdPartyProviderName || '';
        this.ThirdPartyTransactionStatus = data.thirdPartyTransactionStatus || 0;
        this.TransactionType = data.transactionType;
        this.ExternalTransactionGuid = data.externalTransactionGuid || '';
        this.Message = data.message || '';
    }

    public static GetFromCommand(command: PaymentListItemCommand, data: any): PaymentListItem {
        var item = new PaymentListItem();
        item.CustomerGuid = command.ExternalCustomerGuid;
        item.Amount = command.Amount;
        item.Name = command.Name;
        item.Phone = command.PhoneNumber;
        item.RewardCode = command.RewardCode;
        item.AgentNumber = command.AgentNumber;
        item.ThirdPartyProviderName = command.ThirdPartyOperatorName;
        item.Id = Number(data.paymentListItemId);
        item.Message = command.Message;
        return item;
    }

    public shortCustomerGuid(): string {
        return this.CustomerGuid.substring(0, 6);
    }
}

export class PaymentListItemShort {
    RewardCode: string;
    PhoneNumber: string;
    AgentNumber: string;
    Amount: number;
    Message: string;

    public constructor(data: any) {
        this.RewardCode = data?.RewardCode;
        this.PhoneNumber = data?.PhoneNumber;
        this.AgentNumber = data?.AgentNumber;
        this.Amount = data?.Amount;
        this.Message = data?.Message
    }
}

export class BulkPaymentListItemsCommand {
    Items: PaymentListItemShort[];
    BusinessId: number;
    IsSalary: boolean;
    PaymentType: number;

    public constructor(items: PaymentListItemShort[], businessId: number, isSalary: boolean, paymentType) {
        this.Items = items;
        this.BusinessId = businessId;
        this.IsSalary = isSalary;
        this.PaymentType = paymentType;
    }
}

export class BulkAddPaymentListItemsResponse {
    SuccessfulItems: PaymentListItem[];
    FailedItems: FailedReceiverInfoView[];

    public constructor(successfulItems, failedItems) {
        this.SuccessfulItems = successfulItems;
        this.FailedItems = failedItems;
    }
}

export class FailedReceiverInfoView {
    RewardCode: string;
    PhoneNumber: string;
    AgentNumber: string;
    Amount: number;
    Error: string;

    public constructor(data: any) {
        this.RewardCode = data?.rewardCode;
        this.PhoneNumber = data?.phoneNumber;
        this.AgentNumber = data?.agentNumber;
        this.Amount = data?.amount;
        this.Error = data?.error;
    }
}

export class PaymentListItemCommand {
    ExternalCustomerGuid: string;
    RewardCode: string;
    PhoneNumber: string;
    AgentNumber: string;
    Name: string;
    Amount: number = 0;
    ThirdPartyOperatorName: string;
    TransactionType: TransactionType;
    Message: string;


    public constructor(data: any = {}) {
        this.ExternalCustomerGuid = '';
        this.PhoneNumber = '';
        this.Name = '';
        this.Amount = 0;
        this.AgentNumber = '';
        this.RewardCode = '';
        this.Message = '';
    }

    public validate(): void {
        if (this.TransactionType === TransactionType.Transfer) {
            if (this.ExternalCustomerGuid == null || this.ExternalCustomerGuid.trim().length == 0)
                throw new Error("Spenn customer id is required");
            if (this.RewardCode == null || this.RewardCode.trim().length == 0)
                throw new Error("Spenn customer referral code is required");
            if (this.Name == null || this.Name.trim().length == 0)
                throw new Error("Spenn customer name is required");
        }


        if (this.PhoneNumber == null || this.PhoneNumber.trim().length == 0)
            throw new Error("Spenn customer phone number is required");
        this.validateAmount();
    }

    public validateAmount(): void {
        if (this.Amount == null)
            throw new Error("Amount is required");
        if (!(this.Amount > 0.0))
            throw new Error("Amount should be positive");
    }
}

export class PaymentListCollection {
    List: Array<PaymentList>;
    TotalItems: number;

    public constructor(data: any = {}) {
        this.List = PaymentList.ConvertToArray(data.list);
        this.TotalItems = data.totalItems;
    }

    public sortByInitiateDate() {
        this.List = this.List.sort((x, y) => {
            if (new Date(x.InitiateDate) < new Date(y.InitiateDate)) {
                return 1;
            }
            else
                return -1;
        });
    }

    public sortByCreateDate() {
        this.List = this.List.sort((x, y) => {
            if (new Date(x.CreateDate) < new Date(y.CreateDate)) {
                return 1;
            }
            else
                return -1;
        });
    }
}

export class PaymentListTypeUiTitles {
    Title: string;
    DraftTitle: string;
    DraftSecondaryTitle: string;
    PendingTitle: string;
    PendingSecondaryTitle: string;
    ProcessingTitle: string;
    ProcessingSecondaryTitle: string;
    ConfirmTitle: string;
    CompletedTitle: string;
    CompletedSecondaryTitle: string;
    PhonePlaceholder: string;
    AgentNumberPlaceHolder: string;

    setPaymentListType(type: PaymentListType, isSalary: boolean = false): void {
        if (type == PaymentListType.AirtimeTransaction) {
            this.Title = "airtime";
            this.DraftTitle = "Airtime draft";
            this.DraftSecondaryTitle = "New airtime to";
            this.PendingTitle = "Ready to send airtime";
            this.PendingSecondaryTitle = "Confirm airtime to";
            this.ProcessingTitle = "Sending airtime to";
            this.ProcessingSecondaryTitle = "Processing airtime purchase to";
            this.ConfirmTitle = "Send airtime to";
            this.CompletedTitle = "Airtime sent to";
            this.CompletedSecondaryTitle = "Successfully sent to";
            this.PhonePlaceholder = "Phone number";
        }
        else if (type == PaymentListType.NfsCashInTransaction) {
            this.Title = "nfs";
            this.DraftTitle = "Nfs draft";
            this.DraftSecondaryTitle = "New Nfs to";
            this.PendingTitle = "Ready to send Nfs";
            this.PendingSecondaryTitle = "Confirm Nfs to";
            this.ProcessingTitle = "Send Nfs to";
            this.ProcessingSecondaryTitle = "Complete Nfs payment to";
            this.ConfirmTitle = "Send Nfs to";
            this.CompletedTitle = "Nfs sent to";
            this.CompletedSecondaryTitle = "Successfully sent nfs to";
            this.PhonePlaceholder = "Phone number";
        }
        else if (type == PaymentListType.BankMomoTransaction) {
            this.Title = "moneytransfer";
            this.DraftTitle = "Money transfer draft";
            this.DraftSecondaryTitle = "New money transfer to";
            this.PendingTitle = "Ready to send money transfer";
            this.PendingSecondaryTitle = "Confirm money transfer to";
            this.ProcessingTitle = "Send money transfer to";
            this.ProcessingSecondaryTitle = "Complete money transfer payment to";
            this.ConfirmTitle = "Send money transfer to";
            this.CompletedTitle = "Money transfer sent to";
            this.CompletedSecondaryTitle = "Successfully sent money transfer to";
            this.PhonePlaceholder = "Phone number";
        }
        else if (isSalary) {
            this.Title = "salary";
            this.DraftTitle = "Salary draft";
            this.DraftSecondaryTitle = "New salary to";
            this.PendingTitle = "Ready to send salary";
            this.PendingSecondaryTitle = "Confirm salary to";
            this.ProcessingTitle = "Processing to";
            this.ProcessingSecondaryTitle = "Processing salary to";
            this.ConfirmTitle = "Send salary to";
            this.CompletedTitle = "Sent salary to";
            this.CompletedSecondaryTitle = "Successfully sent salary to";
            this.PhonePlaceholder = "SPENN phone number";
        } else {
            this.Title = "payment";
            this.DraftTitle = "Draft";
            this.DraftSecondaryTitle = "New payment to";
            this.PendingTitle = "Ready to pay";
            this.PendingSecondaryTitle = "Confirm payment to";
            this.ProcessingTitle = "Processing to";
            this.ProcessingSecondaryTitle = "Processing payment to";
            this.ConfirmTitle = "Pay to";
            this.CompletedTitle = "Paid to";
            this.CompletedSecondaryTitle = "Successfully paid to";
            this.PhonePlaceholder = "SPENN phone number";
        }
        this.AgentNumberPlaceHolder = "SPENN Agent Number";
    }
}

export class SalaryPaymentSlipDetailsForBusinessView {
    Country: string;
    CustomerSupportInfo: string;
    CompanyUrl: string
    PdfCreateDate: string
    PaymentDate: string
    CompanyName: string
    EmployeeId: string
    EmployeeName: string
    SpennAccountNumber: string
    Amount: number
    Currency: string

    public constructor(data: any = {}) {
        this.Country = data.country || '';
        this.CustomerSupportInfo = data.customerSupportInfo || '';
        this.CompanyUrl = data.companyUrl || '';
        this.PdfCreateDate = data.pdfCreateDate || '';
        this.PaymentDate = data['paymentDate'] ? formatDate(data['paymentDate'], 'medium', 'en-US') : '';
        this.CompanyName = data.companyName || '';
        this.EmployeeId = data.employeeId || '';
        this.EmployeeName = data.employeeName || '';
        this.SpennAccountNumber = data.spennAccountNumber || '';
        this.Amount = data.amount || 0;
        this.Currency = data.currency || '';
    }
}

export class PaymentSlipPdfView {
    Url: string;
    SasToken: string;
    Validity: string
    public constructor(data: any = {}) {
        this.Url = data.url || '';
        this.SasToken = data.sasToken || '';
        this.Validity = data['validity'] ? formatDate(data['validity'], 'medium', 'en-US') : '';
    }
}

export interface FailedPaymentListItem {
    PhoneNumber: string;
    Amount: number;
    AgentNumber: number;
    RewardCode: string;
    Status: string;
    Description: any;
    RecipientType: CustomerType;
    Message: string;
    TransactionType: number;
}
