<app-spinner [spin]="busy"></app-spinner>
<div fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" class="pad-heder">
        <div fxFlex="80" fxLayoutAlign="start center">
            <h5 class="mb-0 text-darkder text-bold" color="primary">{{currentUserQr.AccountName}}</h5>
        </div>
        <div fxFlex fxLayoutAlign="end center">
            <a class="close_btn" mat-dialog-close>
                <mat-icon class="md-30">clear</mat-icon>
            </a>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<mat-card class="mat-elevation-z0">
    <mat-card-content>
        <div fxLayout="column" fxLayoutGap="20px" fxFill>
            <div fxLayout="column" fxLayoutAlign="center center">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <p class="mb-0 px-4 text-center mat-body">
                        Phone Number: <b>{{currentUserQr.PhoneNumber}}</b>
                    </p>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <qrcode [qrdata]="currentUserQr.QrText" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <button class="spenn-btn mr-2 d-block w-90" (click)="downloadQRCode()">Download</button>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions>

    </mat-card-actions>
</mat-card>