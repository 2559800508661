import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

import { map, mergeMap } from 'rxjs/operators';

import { AuthenticationService } from 'app/services/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';


declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'app works!';

  constructor(private titleService: Title,
    router: Router, private authService: AuthenticationService,private observer: BreakpointObserver) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join(' | ');
        titleService.setTitle(title);
      }
    });
  }
  ngOnInit(): void {
    this.authService.tokenRecivedStateChanged$
      .pipe(
        mergeMap(() => this.authService.getUserPermissions()
          .pipe(map(res => res))
        ))
      .subscribe(response => {
        console.log(response);
        this.authService.setPermissionToToken(response);
      });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  
}
