import { TransactionType } from './paymentList';
import { SpennCustomer } from './spennCustomer';

export class ReceiverInfo {
    public TransactionType: TransactionType;
    public Customer: SpennCustomer;
    public ReceiverCustomerGuid: string;
    public PhoneNumber: string;
  
    constructor(data: any = {}) {
      this.TransactionType = data.transactionType;
      this.ReceiverCustomerGuid = data.receiverCustomerGuid;
      if (data.customer === null || data.customer === undefined) {
        this.Customer = new SpennCustomer();
        this.Customer.Name = 'NA';
        this.PhoneNumber = data.phoneNumber;
        return;
      }
      this.Customer = new SpennCustomer(data.customer);
      this.PhoneNumber = this.Customer.Phone;

    }
  }
