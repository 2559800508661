import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { Papa } from 'ngx-papaparse';

import { PaginationConfig } from 'app/models/paginationConfig';
import { ShareDataService } from 'app/services/share-data.service';
import { NfsFailedPaymentListItem } from 'app/models/nfs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nfs-failed-payment-list-item',
  templateUrl: './nfs-failed-payment-list-item.component.html',
  styleUrls: ['./nfs-failed-payment-list-item.component.scss']
})
export class NfsFailedPaymentListItemComponent implements OnInit, OnDestroy {
  public paginationConfig = new PaginationConfig('failed-items', 'failed-item', 10);
  public pageTitle: string = "Error Overview";
  failedItems: Array<NfsFailedPaymentListItem>;
  subscription: Subscription;
  paymentId: string;
  constructor(private dataShareService: ShareDataService,
    private papa: Papa,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnDestroy(): void {
    this.dataShareService.nfsFailedItems = [];
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loadFailedItems();

    this.route.paramMap.subscribe(params =>
      {
        this.paymentId = params.get('paymentId');
      }
    )
  }

  paginationPageChanged(pageNumber: number) {
    this.paginationConfig.currentPage = pageNumber;
    this.loadFailedItems();
  }

  loadFailedItems() {
    this.subscription = this.dataShareService._nfsfailedItemSubscription.subscribe((data) => {
      if (data) {
        let startIndex = (this.paginationConfig.currentPage - 1) * this.paginationConfig.itemsPerPage;
        let endIndex = this.paginationConfig.currentPage * this.paginationConfig.itemsPerPage;
        this.failedItems = data.slice(startIndex, endIndex);
        this.paginationConfig.totalItems = data.length;
      }
    },
      error => {

      }
    );

  }

  exportCSV() {
    let columns: Array<string>;
    columns = ['OperatorID', 'PhoneOrBank', 'Amount']
    var csv = this.papa.unparse(this.dataShareService.nfsFailedItems, {
      skipEmptyLines: true,
      header: true,
      columns: columns
    });

    const a = document.createElement('a');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'error.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  navigateToPayment(){
    this.router.navigate(['/payments', this.paymentId])
  }
}
