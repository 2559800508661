import { MoneyTransferStatusCodes, NfsStatusCodes, PaymentList } from "./paymentList";

export interface MoneyTransferFeeCalculateCommand {
    UtilityCode: string;
    UtilityRef: string;
    Amount: number;
    ExternalReference: string;
}

export interface MoneyTransferCommand extends MoneyTransferFeeCalculateCommand {
    Message: string;
    Otp: string;
}

export class MoneyTrnasferServiceList{
    public ListOfMOneyTransferService:Array<MoneyTransferServiceView>;

    constructor(data:any ={}) {
        this.ListOfMOneyTransferService = new Array<MoneyTransferServiceView>();
        if(data)
        {
            data.forEach(item => {
                this.ListOfMOneyTransferService.push(new MoneyTransferServiceView(item));
            });
        }
    }
}

export class MoneyTransferServiceView {
    public MoneyTransferServiceId: number;
    public Name: string;
    public Description: string;
    public IconIdentifier: string;
    public ShortCode: string;
    public ListOfMoneyTransferSubServices: Array<MoneyTransferSubServiceView>;

    constructor(data: any = {}) {
        this.MoneyTransferServiceId = data.moneyTransferServiceId || 0;
        this.Name = data.name || '';
        this.Description = data.description || '';
        this.IconIdentifier = data.iconIdentifier || '';
        this.ShortCode = data.shortCode || '';
        this.ListOfMoneyTransferSubServices = Array<MoneyTransferSubServiceView>();

        if (data.listOfMoneyTransferSubServices) {
            data.listOfMoneyTransferSubServices.forEach(item => {
                this.ListOfMoneyTransferSubServices.push(new MoneyTransferSubServiceView(item));
            });
        }
    }
}

export class MoneyTransferSubServiceView {
    public MoneyTransferSubServiceId: number;
    public MoneyTransferServiceId: number;
    public Name: string;
    public Description: string;
    public IconIdentifier: string;
    public ShortCode: string;
    public ListOfMoneyTransferProviders: Array<MoneyTransferProviderView>;

    constructor(data: any = {}) {
        this.MoneyTransferSubServiceId = data.moneyTransferSubServiceId || 0;
        this.MoneyTransferServiceId = data.moneyTransferServiceId || 0;
        this.Name = data.name || '';
        this.Description = data.description || '';
        this.IconIdentifier = data.iconIdentifier || '';
        this.ShortCode = data.shortCode || '';
        this.ListOfMoneyTransferProviders = Array<MoneyTransferProviderView>();

        if (data.listOfMoneyTransferProviders) {
            data.listOfMoneyTransferProviders.forEach(item => {
                this.ListOfMoneyTransferProviders.push(new MoneyTransferProviderView(item));
            });

        }
    }
}

export class MoneyTransferProviderView {
    public MoneyTransferProviderId: number;
    public MoneyTransferSubServiceId: number;
    public Name: string;
    public Description: string;
    public IconIdentifier: string;
    public ShortCode: string;
    public Regex: string;
    public HasLookUp: boolean;
    public IsActive: boolean;
    public MinValue: number;
    public MaxValue: number;
    public IsDescriptionRequired: boolean;

    constructor(data: any = {}) {
        this.MoneyTransferProviderId = data.moneyTransferProviderId || 0;
        this.MoneyTransferSubServiceId = data.moneyTransferSubServiceId || 0;
        this.Name = data.name || '';
        this.Description = data.description || '';
        this.IconIdentifier = data.iconIdentifier || '';
        this.ShortCode = data.shortCode || '';
        this.Regex = data.regex || '';
        this.HasLookUp = data.hasLookUp || false;
        this.IsActive = data.isActive || false;
        this.MinValue = data.minValue || 0;
        this.MaxValue = data.maxValue || 0;
        this.IsDescriptionRequired = data.isDescriptionRequired || false;
    }
}

export class MoneyTransferLookupResponseView {
    public Provider: string;
    public Message: string;
    public MoneyTransferLookupMessage: MoneyTransferLookupMessage
    public MaximumAmount?: number;
    public MinimumAmount?: number;
    constructor(data: any = {}) {
        this.Provider = data.provider || '';
        this.Message = data.message || '';
        this.MaximumAmount = data.maximumAmount || null;
        this.MinimumAmount = data.minimumAmount || null;
        this.MoneyTransferLookupMessage = new MoneyTransferLookupMessage(data.data)
    }
}

export class MoneyTransferLookupMessage {

    public Name: string;
    public Amount?: number;
    public Institution: string;
    public Type: string;
    public Description: string;

    constructor(data: any = {}) {
        this.Name = data.name || '';
        this.Amount = data.amount || null;
        this.Institution = data.institution || '';
        this.Type = data.type || '';
        this.Description = data.description || '';
    }
}

export class MoneyTransferTaxCalculationView {
    public Tax?: number;
    public Fees?: number;
    public PrincipleAmount: number;
    public TotalAmount: number;

    constructor(data: any = {}) {
        this.Tax = data.tax || null;
        this.Fees = data.fees || null;
        this.PrincipleAmount = data.principleAmount || 0;
        this.TotalAmount = data.totalAmount || 0;
    }
}

export class MoneyTransferResponseView {
    public Reference: string;
    public TransId: string;
    public ResultCode: string;
    public Result: string;
    public Message: string;
    public Data: any;

    constructor(data: any = {}) {
        this.Reference = data.reference || '';
        this.TransId = data.transId || '';
        this.ResultCode = data.resultCode || '';
        this.Result = data.result || '';
        this.Message = data.message || '';
        this.Data = data.data || null
    }
}

export class AddMoneyTransferBulkPaymentListItemsCommand{
    public BusinessId: number;
    public PaymentListId: number;
    public Items: AddMoneyTransferItemDto[];
}

export class AddMoneyTransferItemDto{
    public ProductCode: string;
    public AccountReference: string;
    public ProductType: string;
    public Amount: number;
}

export class MoneyTransferPaymentDetailsView extends PaymentList {
    Items: MoneyTransferPaymentListItem[];
    SumOfPending: number;
    SumOfFailed: number;
    SumOfTotal: number;
    SumOfTotalFee: number;


    public constructor(data: any = {}) {
        super(data);
        this.Items = [];
        this.SumOfPending = data.sumOfPending || 0;
        this.SumOfFailed = data.sumOfFailed || 0;
        this.SumOfTotal = data.sumOfTotal || 0;
        this.SumOfTotalFee = data.sumOfTotalFee || 0;
        if (data.items) {
            data.items.forEach(item => {
                this.Items.push(new MoneyTransferPaymentListItem(item));
            });
        }
    }
}

export default class MoneyTransferPaymentListItem {
    MoneyTransferPaymentInformationId: number;
    OperatorCode: string;
    Name: null;
    AccountReference: string;
    NfsStatusCode: null;
    Amount: number;
    Fee: number;
    PaymentListId: number;
    EditTempAmount: number = 0;
    EditStatus: boolean = false;
    Error: string;
    Status: string;

    public constructor(item: any) {
        this.MoneyTransferPaymentInformationId = item.moneyTransferPaymentInformationId;
        this.OperatorCode = item.operatorCode;
        this.Name = item.name;
        this.AccountReference = item.accountReference;
        this.NfsStatusCode = item.nfsStatusCode;
        this.Amount = item.amount;
        this.Fee = item.fee;
        this.PaymentListId = item.paymentListId;
        this.Error = item.error;
        this.Status = MoneyTransferStatusCodes[item.nfsStatusCode];
    }
}
