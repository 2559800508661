import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private isMobileResolution: boolean;
    private sidenav: MatSidenav;
    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }
    public toggleSidenav() {
        return this.sidenav.toggle();
    }



    constructor() {
       
      }
}
