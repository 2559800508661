import { formatDate } from '@angular/common';

export class RequestMoney {
    public RequestGuid: string;
    public RequestStatus: string;
    public TimestampCreated: string;
    public CustomerName: string;
    public Currency: string;
    public PhoneNumber: string;
    public Message: string;
    public Amount: number;
    public TransactionStatus: string;

    public constructor(data: any = {}) {
        this.RequestGuid = data.requestGuid || '';
        this.RequestStatus = data.requestStatus || '';
        this.TimestampCreated = data['timestampCreated'] ? formatDate(data['timestampCreated'], 'medium', 'en-US') : '';
        this.CustomerName = data.customerName || '';
        this.Currency = data.currency || '';
        this.PhoneNumber = data.phoneNumber || '';
        this.Message = data.message || '';
        this.Amount = data.amount || 0;
        this.TransactionStatus = data.transactionStatus || '';
    }
}

export class RequestMoneyList {
    public List: Array<RequestMoney>;
    public Total: number;

    public constructor(data: any = {}) {
        this.List = new Array<RequestMoney>();
        this.Total = data.total || 0;
        if (data.data) {
            data.data.forEach(item => {
                this.List.push(new RequestMoney(item));
            })
        }
    }
}

export class SbRequestMoneyList {
    public List: Array<SbRequestMoney>;
    public Total: number;

    public constructor(data: any = {}) {
        this.List = new Array<SbRequestMoney>();
        this.Total = data.total || 0;
        if (data.data) {
            data.data.forEach(item => {
                this.List.push(new SbRequestMoney(item));
            })
        }
    }
}

export class SbRequestMoney extends RequestMoney {
    public PictureUrl: string;
    public constructor(data: any = {}) {
        super(data);
        this.PictureUrl = data.pictureUrl
    }
}

export class GetRequestMoneyCommand {
    public FromDate: Date;
    public ToDate: Date;
    public Page: number;
    public PageSize: number;
    public Sort: RequestMoneySortDescriptor[];

    public constructor() {
        this.Sort = new Array<RequestMoneySortDescriptor>();
        this.Page = 1;
        this.PageSize = 10;
    }
}

export class RequestMoneySortDescriptor {
    public Field: string;
    public Dir: string;

    public constructor(field: string = '', dir: string = '') {
        this.Field = field;
        this.Dir = dir;
    }
}

export class RequestReverseCommand {
    public RequestMoneyGuid: string;
    public constructor() {
    }
}

export class RequestMoneyView {
    public RequestGuid: string;
    public RequestStatus: string;

    public constructor(data: any = {}) {
        this.RequestGuid = data.requestId || '';
        this.RequestStatus = data.status || '';

    }
}

export class SBHandleRequestView {
    public RequestId: string;
    public Status: string;
    public Timestamp: Date;

    public constructor(data: any = {}) {
        this.RequestId = data.requestId || '';
        this.Status = data.status || '';
        this.Timestamp = data.timestamp;

    }
}