import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'app/app.constants';
import { MoneyTransferCommand, MoneyTransferFeeCalculateCommand, MoneyTrnasferServiceList, MoneyTransferLookupResponseView, MoneyTransferResponseView, MoneyTransferServiceView, MoneyTransferTaxCalculationView, AddMoneyTransferBulkPaymentListItemsCommand, MoneyTransferPaymentDetailsView } from 'app/models/money-transfer';
import { NfsBulkInportRespose, NfsCloneObj, NfsPaymentListDetailsView } from 'app/models/nfs';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MoneyTransferService {

  constructor(private http: HttpClient) { }

  InitialLookup(): Observable<MoneyTrnasferServiceList> {
    return this.http.get(Configuration.MoneyTransferInitialLookup)
      .map((response) => {
        return new MoneyTrnasferServiceList(response);
      })
      .catch(error => {
        return throwError(error)
      });
  }

  Lookup(utilityCode: string, utilityRef: string, externalReference: string): Observable<MoneyTransferLookupResponseView> {
    return this.http.get(`${Configuration.MoneyTransferLookup}/?utilityCode=${utilityCode}&utilityRef=${utilityRef}&externalReference=${externalReference}`)
      .map((response) => {
        return new MoneyTransferLookupResponseView(response);
      })
      .catch(error => {
        return throwError(error)
      });
  }

  CalculateFees(utilityCode: string, utilityRef: string, externalReference: string, amount: number)
    : Observable<MoneyTransferTaxCalculationView> {
    let request: MoneyTransferFeeCalculateCommand = {
      UtilityCode: utilityCode,
      UtilityRef: utilityRef,
      ExternalReference: externalReference,
      Amount: amount
    };
    return this.http.post(Configuration.MoneyTransferCalculateFees, request)
      .map((response) => {
        return new MoneyTransferTaxCalculationView(response);
      })
      .catch(error => {
        return throwError(error)
      });
  }

  Process(utilityCode: string, utilityRef: string, externalReference: string, amount: number, message: string, otp: string)
    : Observable<MoneyTransferResponseView> {
    let request: MoneyTransferCommand = {
      UtilityCode: utilityCode,
      UtilityRef: utilityRef,
      ExternalReference: externalReference,
      Amount: amount,
      Message: message,
      Otp: otp
    };
    return this.http.post(Configuration.MoneyTransferProcess, request)
      .map((response) => {
        return new MoneyTransferResponseView(response);
      })
      .catch(error => {
        return throwError(error)
      });
  }

  AddBulkPaymentItems(data: AddMoneyTransferBulkPaymentListItemsCommand)
    : Observable<NfsBulkInportRespose> {
    
    return this.http.post(Configuration.MoneyTransferAddBulkPayment, data)
      .map((response) => {
        return new NfsBulkInportRespose(response);
      })
      .catch(error => {
        return throwError(error)
      });
  }

  public verifyNfsBulkPaymentOtp(paymentListId: number, code: string): Observable<any> {
    var command = {
      otp: code,
      paymentListId: paymentListId
    };
    return this.http.post(Configuration.MoneyTransferBaseUrl + '/bulkpayment', command)
      .map(data => { return data })
      .catch(err => { return throwError(err) });
  }

  public getPaymentList(paymentListId: number): Observable<MoneyTransferPaymentDetailsView> {
    return this.http.get(Configuration.MoneyTransferGetPaymentDetail + '/' + paymentListId)
      .map(result => { return new MoneyTransferPaymentDetailsView(result); })
      .catch(err => { return throwError(err) });
  }

  public removeMoneyTransferPaymentListItem(paymentListId: number): Observable<any> {
    return this.http.delete(Configuration.MoneyTransferBaseUrl + '/' + paymentListId)
      .catch(err => { return throwError(err) });
  }

  public clonePaymentList(item: NfsCloneObj): Observable<any> {
    return this.http.post(Configuration.MoneyTransferClonePaymentListUri, item)
      .map(result => { return result; })
      .catch(err => { return throwError(err) });
  }

  public updateMoneyTransferPaymentListItem(command: any): Observable<any> {
    return this.http.put(Configuration.MoneyTransferBaseUrl + '/updateitem', command )
      .catch(err => { return throwError(err) });
  }
}
