import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

import * as moment from 'moment';

function utcToLocalDateTimeString(value:string):string {
    let localDatetimeString = moment
    .utc(value)
    .local()
    .toString();
    return localDatetimeString;
}

@Pipe({ name:'timeAgo', pure:false })
export class TimeAgoPipe implements PipeTransform {
    private readonly millisecondsInADay: number = 60 * 60 * 24 * 1000;

    constructor() {}

    transform(value:string) {
        value=utcToLocalDateTimeString(value);
        let date = new Date(value);
        let now = new Date();
        now.setHours(0,0,0,0);
        date.setHours(0,0,0,0);

        if(now.getTime() == date.getTime()) return "Today";
        else if(now.getTime() - date.getTime() == this.millisecondsInADay) return "Yesterday";
        return formatDate(value,'d MMMM y', 'en-US');
    }
}

@Pipe({ name:'timeDotted', pure:false })
export class TimeDotPipe implements PipeTransform {
    constructor() {}

    transform(value:string) {
        let localDate=utcToLocalDateTimeString(value);
        return formatDate(localDate,'MM.dd.yyyy', 'en-US');
    }
}

@Pipe({ name:'dateSlash', pure:false })
export class DateSlashPipe implements PipeTransform {

    transform(value:string) {
        const localDate=utcToLocalDateTimeString(value);
        return formatDate(localDate,'dd/MM/yyyy', 'en-US');
    }
}

@Pipe({ name:'timeDate', pure:false })
export class TimeWithDatePipe implements PipeTransform {
    constructor() {}

    transform(value:string) {
        let localDate=utcToLocalDateTimeString(value);
        return formatDate(localDate,'hh:mm a, MMMM dd, yyyy', 'en-US');
    }
}

@Pipe({ name:'localDateTime', pure:false })
export class LocalDateTimePipe implements PipeTransform {
    constructor() {}

    transform(value:string) {
        let localDate=utcToLocalDateTimeString(value);
        return formatDate(localDate,'MMM d, y, h:mm:ss a', 'en-US');
    }
}