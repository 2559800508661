import { Component, OnInit, OnDestroy, ViewEncapsulation, Inject } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'; 

import { TopupService } from '../../topup.service';
import { NotificationService } from 'app/services/notification.service'; 


@Component({
  templateUrl: './bank-branch.component.html',
  styleUrls: ['./bank-branch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankBranchComponent implements OnInit, OnDestroy {

  rewardCode: string = '';
  sub : Subscription = null;

  constructor(private dialogRef: MatDialogRef<BankBranchComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: { bankName: string},
    private topupService: TopupService,
    private notif: NotificationService) { }


  ngOnDestroy(): void {
    if(this.sub){
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.sub = this.topupService.getRewardCode()
    .subscribe(code => {
      this.rewardCode = code;
    },
    error => {
      this.rewardCode = '[code]'
      this.notif.error('Could not get code. Please try again');
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
