import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { MoneyTransferStatusCodes, NfsStatusCodes, PaymentListDetails, PaymentListStatus, PaymentListType, PaymentTypeViewObj } from 'app/models/paymentList';
import { PaymentListService } from 'app/services/payment-list.service';
import { User } from 'app/models/user';
import { AuthenticationService } from 'app/services/auth.service';
import { MixpanelUtilService } from 'app/services/mixpanel-util.service';
import { NfsService } from 'app/services/nfs.service';
import { NfsPaymentListDetailsView } from 'app/models/nfs';
import { MoneyTransferService } from 'app/services/money-transfer.service';
import MoneyTransferPaymentListItem, { MoneyTransferPaymentDetailsView } from 'app/models/money-transfer';

@Component({
    selector: 'app-statement',
    templateUrl: './statement.component.html',
    styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit {
    PaymentListStatus = PaymentListStatus;
    paymentListType = PaymentListType;

    public paymentList: PaymentListDetails;
    nfsPaymentDetails: NfsPaymentListDetailsView;
    public moneyTransferDetails: MoneyTransferPaymentDetailsView;
    public paymentListId: number = 0;
    public currentUser: User;
    public bulkPaymentType: PaymentTypeViewObj;

    public busy: Subscription;
    public totalSuccessNFsFees: number = 0;
    public totalSuccessNFsAmount: number = 0;

    constructor(private activeRoute: ActivatedRoute,
        private paymentListService: PaymentListService,
        private authenticationService: AuthenticationService,
        private mixPanelUtilService: MixpanelUtilService,
        private nfsService: NfsService,
        private moneyTransferService: MoneyTransferService) {

    }

    ngOnInit() {
        this.mixPanelUtilService.exploreCompletedPaymentEventLog(false, false, true);
        this.activeRoute.params.subscribe(params => {
            this.paymentListId = params.id;
            this.paymentListService.GetBulkPaymentType(this.paymentListId).subscribe(
                data => {
                    this.bulkPaymentType = data;
                    if (this.bulkPaymentType.TypeId == PaymentListType.NfsCashInTransaction) {
                        this.loadPaymentListNFs();
                    } 
                    else if (this.bulkPaymentType.TypeId == PaymentListType.BankMomoTransaction) {
                        this.loadPaymentListMoneyTransfer();
                    } 
                    else {
                        this.loadPaymentList();
                    }
                    this.mixPanelUtilService.printStatementEventLog(false,true);
                    console.log(this.paymentList);
                    console.log(this.nfsPaymentDetails);
                }
            );

        });
        this.currentUser = this.authenticationService.getCurrentUserValue();
    }

    public loadPaymentListMoneyTransfer(): void {
        this.moneyTransferService.getPaymentList(this.paymentListId).subscribe(
            data => {
                this.moneyTransferDetails = data;
                if (data != null) {
                    data.Items.forEach(i => {
                        if (i.NfsStatusCode == MoneyTransferStatusCodes.Completed) {
                            this.totalSuccessNFsAmount += i.Amount;
                            this.totalSuccessNFsFees += i.Fee;
                        }
                    })
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    public loadPaymentListNFs(): void {
        this.nfsService.getPaymentList(this.paymentListId).subscribe(
            data => {
                this.nfsPaymentDetails = data;
                if (data != null) {
                    data.Items.forEach(i => {
                        if (i.NfsStatusCode == NfsStatusCodes.Ok) {
                            this.totalSuccessNFsAmount += i.Amount;
                            this.totalSuccessNFsFees += i.Fee;
                        }
                    })
                }
            },
            error => {
                console.log(error);
            }
        );
    }


    public loadPaymentList(): void {
        this.busy = this.paymentListService.getPaymentList(this.paymentListId).subscribe(
            data => {
                this.paymentList = data;
            },
            error => {
                console.log(error);
            });
    }

    public print(): boolean {
        this.mixPanelUtilService.printStatementEventLog(true,false);
        window.print();
        return false;
    }
}