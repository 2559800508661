// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  server: {
    IdentityServer: { domain: "$SPENN_IDENTITYSERVER_URL" },
    SpennBusinessApi: { domain: "$SPENN_BUSINESSAPI_URL" }
  },
  bbAuth: {
    clientID: "SpennBusiness",
    clientSecret: "1234",
    audience: "SpennBusiness",
    redirectUrl: "$SPENN_REDIRECT_URL"
  },
  mixPanel: {
    projectToken: "$SPENN_BUSINESS_PROJECT_TOKEN"
  },
  production: true,
  maxNumberOfItemForPdfButton: 1000,
};
