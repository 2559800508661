<app-bb-toolbar [title]="pageTitle"></app-bb-toolbar>

<div class="container-fluid payment-page" ngClass.lt-md="mobile">
    <app-spinner [spin]="busy"></app-spinner>
    <div fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="info-panel">
            <div>
                <img class="sidebar-icon" src="assets/images/warning.png" alt="withdraw">
                <span >Bank withdrawal request can take upto 48 hours during weekdays and 72 hours during weekends</span>     
            </div>
            <div>
                <button [matMenuTriggerFor]="withdrawMenu" class="btn btn-default spenn-btn dropdown-toggle"><span>Withdraw Money</span></button>
                <mat-menu #withdrawMenu="matMenu" yPosition="below" >
                    <button class="withdraw-menu-bank-transfer" mat-menu-item (click)="openCreateRequestDialog()">Bank Transfer Request</button>
                </mat-menu>
            </div>
        </div>
        
        <div fxLayout="row" fxLayoutAlign="space-between center" class="header-div">
            <div class="text-spenn">
                <h6>Recent Transactions</h6>
            </div>
            <div>
                <mat-form-field appearance="none">
                    <mat-date-range-input [formGroup]="range" [rangePicker]="dateRangePicker">
                        <input matStartDate formControlName="start">
                        <input matEndDate formControlName="end">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #dateRangePicker>
                        <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDateRangePickerApply
                                (click)="dateChange()">Apply</button>
                        </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>

        <div *ngFor="let item of withdrawList?.List| paginate: paginationConfig; let i = index" fxLayout="row" fxLayoutGap="10px" class="withdraw-list-item-box">
            <div fxFlex="4" fxLayout="column" fxLayoutAlign="center center" class="withdraw-icon-box">
                <div class="image small-image">
                    <img src="assets/images/withdraw.png" alt="withdraw">
                  </div>
                <span class="withdraw-red-arrow"><</span>
            </div>
            <div fxFlex="flex">
                <h6 class="item-title">{{item.Title}}</h6>
                <div>Authorizer: {{item.Authorizer}}</div>
                <div>Status: <span class="text-spenn">{{item.WithdrawRequestStatus}}</span></div>
            </div>
            <div fxFlex="15" fxLayout="row" fxLayoutAlign="space-between center">
                <div><h6>Total</h6></div>
                <div class="red-text"><h6>- {{account?.Currency?.DisplayCode}} {{item.Amount}}</h6></div>
            </div>
        </div>
    
    </div>
    <app-pagination [paginationConfig]="paginationConfig" (pageChangedCallback)="paginationPageChanged($event)">
    </app-pagination>
</div>
