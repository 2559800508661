import { Component } from '@angular/core';

import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent  { 
  
  landingButtonClicked() {
    var randomString = this.getRandomString(10);
    const pkceChallenge = require("pkce-challenge").default;
    const challenge = pkceChallenge(50);
    
    localStorage.setItem('code_verifier', challenge.code_verifier);
   // window.location.href = `${environment.server.IdentityServer.domain}/OAuth/Authorize?client_id=${environment.bbAuth.clientID}&redirect_uri=${environment.bbAuth.redirectUrl}?codeCheckLp=${randomString}&audience=${environment.bbAuth.audience}&response_type=code`;
    window.location.href = `${environment.server.IdentityServer.domain}/OAuth/Authorize?client_id=${environment.bbAuth.clientID}&redirect_uri=${environment.bbAuth.redirectUrl}&audience=${environment.bbAuth.audience}&response_type=code&code_challenge=${challenge.code_challenge}&code_challenge_method=S256`;
  }
  getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

}
