import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { Subscription } from 'rxjs';

import { InfoItem } from '../../common/info-item';
import { TopupService } from '../../topup.service';
import { NotificationService } from 'app/services/notification.service';


@Component({
  templateUrl: './crdb.component.html',
  styleUrls: ['./crdb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CrdbComponent implements OnInit, OnDestroy {

  infoItemsArray: Array<InfoItem> = new Array<InfoItem>();
  instructionsArray: string[];
  rewardCode: string = '';
  sub : Subscription = null;

  datePipe: DatePipe = new DatePipe('en-US');

  constructor(private dialogRef: MatDialogRef<CrdbComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { crdbType: string}, 
    private topupService: TopupService, 
    private notif: NotificationService) {
  }

  ngOnDestroy(): void {
    if(this.sub) {
      this.sub.unsubscribe();
    }    
  }

  ngOnInit(): void {
    this.sub = this.topupService.getRewardCode().subscribe(code => {
      this.rewardCode = code;
      this.getInstructions();
      this.getInfoItems();  
    }, 
    error => {
      this.rewardCode = '[Spenn reward code]'
      this.getInstructions();
      this.getInfoItems();  
      this.notif.error('Could not get reward code of customer');
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getInfoItems() {
    this.infoItemsArray = new Array<InfoItem>();
    this.infoItemsArray.push({ ItemName: 'Narration', ItemValue: this.rewardCode });
    
    if(this.data.crdbType.toLowerCase() == 'agent') {
      this.infoItemsArray.push({ ItemName: 'Agent', ItemValue: 'Agent Number'});
    } else {
      this.infoItemsArray.push({ ItemName: 'Branch', ItemValue: 'Branch Name'});
    }
    this.infoItemsArray.push({ ItemName: 'Date', ItemValue: this.datePipe.transform(new Date(), 'dd.MM.yyyy')});
    this.infoItemsArray.push({ ItemName: 'Account number', ItemValue: '0150027133400'});
    this.infoItemsArray.push({ ItemName: 'Account name', ItemValue: 'I&M Bank (T) Ltd'});
    this.infoItemsArray.push({ ItemName: 'Customer name', ItemValue: 'Your name'});
    this.infoItemsArray.push({ ItemName: 'Amount', ItemValue: 'Follow instructions'});
    this.infoItemsArray.push({ ItemName: 'Signature', ItemValue: 'Your signature'});
  }

  getInstructions() {
    this.instructionsArray = [
      'Fill in the deposit slip with the details above. Input narration in the empty space',
      'Hand the deposit slip and money to the teller. Keep the receipt for your own records'
    ]
  }
}

