export class DashboardEvents {
    public static readonly TapDashBoard: string = "Tap Dashboard";
    public static readonly RecentPayments: string = "Recent Payments";
    public static readonly Help: string = "Help";
    public static readonly Outgoings: string = "Outgoings";
}

export class PaymentEvents {
    public static readonly TapPayment: string = "Tap Payment";
    public static readonly PaymentTabs: string = "Payment Tabs";
    public static readonly CreatePayment: string = "Create Payment";
    public static readonly AddRecipient: string = "Add Recipient";
    public static readonly ImportRecipient: string = "Import Recipient";
    public static readonly FinishDraft: string = "Finish Draft";
    public static readonly CompletePayment: string = "Complete Payment";
    public static readonly ExploreCompletedPayment: string = "Explore Completed Payment";
    public static readonly AccessPaySlip: string = "Access PaySlip";
    public static readonly DuplicateList: string = "Duplicate List";
    public static readonly PrintStatement: string = "Print Statement";
}

export class TransactionEvents {
    public static readonly TapTransaction: string = "Tap Transaction";
    public static readonly ExploreTransaction: string = "Explore Transaction";
    public static readonly GenerateAccountStatement: string = "Generate Account Statement";
}

export class TransferEvents {
    public static readonly TapInmTransfer: string = "Tap I&M Transfer";
    public static readonly InmCashout: string = "I&M Cashout";
    public static readonly OtherBankTransfer: string = "Other Bank Transfer";
    public static readonly AddBankAccount: string = "Add Bank Account";
    public static readonly TransferRequest: string = "Transfer Request";
    public static readonly ConfirmTransferRequest: string = "Confirm Transaction Request";
    public static readonly CompleteTransferRequest: string = "Complete Transfer Request";
    public static readonly NfsBankTransfer: string = "NFS Bank Transfer";
    public static readonly CreateNfsBankTransfer: string = "Create NFS Bank Transfer";
    public static readonly NfsBankTransferConfirmTransaction: string = "NFS Bank Transfer Confirm Transaction";
    public static readonly CompleteNfsBankTransfer: string = "Complete NFS Bank Transfer";
    public static readonly NfsMomoTransfer: string = "NFS Momo Transfer";
    public static readonly CreateNfsMomoTransfer: string = "Create NFS Momo Transfer";
    public static readonly NfsMomoTransferConfirmTransaction: string = "NFS Momo Transfer Confirm Transaction";
    public static readonly CompleteNfsMomoTransfer: string = "Complete NFS Momo Transfer";
    public static readonly SelcomMomoTransfer: string = "Selcom Momo Transfer";
    public static readonly CreateSelcomMomoTransfer: string = "Create Selcom Momo Transfer";
    public static readonly SelcomMomoTransferConfirmTransaction: string = "Selcom Momo Transfer Confirm Transaction";
    public static readonly CompleteSelcomMomoTransfer: string = "Complete Selcom Momo Transfer";
}

export class RequestEvents {
    public static readonly TapRequests = "Requests";
    public static readonly SearchRequests = "Search Requests";
    public static readonly RefundRequest = "Refund Requests";
    public static readonly RequestTransfer = "Request Transfer";
    public static readonly ApproveRequest = "Approve Request";
    public static readonly CancelRequest = "Cancel Request";

}

export class ApiKeyEvents {
    public static readonly TapApiKey = "API Key";
    public static readonly CreateApiKey = "Create API";
    public static readonly RevokingApiKey = "Revoking API";
    public static readonly UpdateApiKey = "Update API";
}