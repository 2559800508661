export class SpennCustomer {
    public Phone: string;
    public Name: string;
    public CustomerGuid: string;
    public RewardCode: string;

    constructor(data: any = {}) {
        this.Phone = data.phoneNumber;
        this.Name = data.name;
        this.CustomerGuid = data.customerGuid;
        this.RewardCode = data.rewardCode;
    }
}