export class DashboardEventProperties {

    static TapDashboardEvent = class {
        public static readonly Dashboard: string = "Dashboard";
        public static readonly BusinessBalance: string = "BusinessBalance";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static RecentPaymentsEvent = class {
        public static readonly PendingTab: string = "PendingTab";
        public static readonly CompletedTab: string = "CompletedTab";
        public static readonly IncomingTab: string = "IncomingTab";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static HelpEvent = class {
        public static readonly HelpButton: string = "HelpButton";
        public static readonly HowToTopUpButton: string = "HowToTopUpButton";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static OutgoingsEvent = class {
        public static readonly ApplyOutgoings: string = "ApplyOutgoings";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }
}

export class PaymentEventProperties {
    public static TapPaymentEvent = class {
        public static readonly Payment: string = "Payment";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static PaymentTabsEvent = class {
        public static readonly AllTab: string = "AllTab";
        public static readonly CompletedTab: string = "CompletedTab";
        public static readonly PendingTab: string = "PendingTab";
        public static readonly DraftTab: string = "DraftTab";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CreatePaymentEvent = class {
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly TransactionType: string = "TransactionType";
        public static readonly Recipient: string = "Recipient";
        public static readonly CreateNewPayment: string = "CreateNewPayment";
    }

    public static AddRecipientEvent = class {
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly PhoneNumber: string = "PhoneNumber";
        public static readonly Amount: string = "Amount";
    }

    public static ImportRecipientEvent = class {
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly PhoneNumber: string = "PhoneNumber";
        public static readonly AgentNumber: string = "AgentNumber";
        public static readonly Amount: string = "Amount";
    }

    public static FinishDraftEvent = class {
        public static readonly PaymentId: string = "PaymentId";
        public static readonly DeleteDraft: string = "DeleteDraft";
        public static readonly EditDraft: string = "EditDraft";
        public static readonly AddToPending: string = "AddToPending";
        public static readonly PayNow: string = "PayNow";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CompletePaymentEvent = class {
        public static readonly ConfirmPayment: string = "ConfirmPayment"
        public static readonly NumberOfRecipients: string = "NumberOfRecipints";
        public static readonly Amount: string = "Amount";
        public static readonly TypeOfPayment: string = "TypeOfPayment";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static ExploreCompletedPaymentEvent = class {
        public static readonly ExploreList: string = "ExploreList";
        public static readonly DuplicateList: string = "DuplicateList";
        public static readonly Statement: string = "Statement";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static AccessPaySlipEvent = class {
        public static readonly PrintPaySlip: string = "PrintPaySlip";
        public static readonly DownloadPaySlip: string = "DownloadPaySlip";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static DuplicateListEvent = class {
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly DuplicateList: string = "DuplicateList";
        public static readonly PaymentTitle: string = "PaymentTitle";
        public static readonly RecipientMessage: string = "RecipientMessage";
    }

    public static PrintStatementEvent = class {
        public static readonly DownloadStatement: string = "DownloadStatement";
        public static readonly ViewStatement: string = "ViewStatement";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }
}

export class TransactionEventProperties {
    public static TapTransactionEvent = class {
        public static readonly Transaction: string = "Transaction";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static ExploreTransactionEvent = class {
        public static readonly ExploreTransaction: string = "ExploreTransaction";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static GenerateAccountStatementEvent = class {
        public static readonly StartDate: string = "StartDate";
        public static readonly EndDate: string = "EndDate";
        public static readonly GenerateStatement: string = "GenerateStatement";
        public static readonly DownloadStatement: string = "DownloadStatement";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }
}

export class TransferEventProperties {
    public static TapInmTransferEvent = class {
        public static readonly InmTransfer = "I&MTransfer";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static InmCashoutEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Amount: string = "Amount";
        public static readonly Message: string = "Message";
    }

    public static OtherBankTransferEvent = class {
        public static readonly OtherBankTransfer: string = "OtherBankTransfer";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static AddBankAccountEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly SelectBankName: string = "SelectBankName";
        public static readonly AccountName: string = "AccountName";
        public static readonly AccountNumber: string = "AccountNumber";
    }

    public static TransferRequestEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static ConfirmTransactionRequestEvent = class {
        public static readonly ConfirmTransaction: string = "ConfirmTransaction";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CompleteTransferRequestEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Amount: string = "Amount";
        public static readonly BankName: string = "BankName";
    }

    public static NfsBankTransferEvent = class {
        public static readonly NfsBankTransfer: string = "NFSBankTransfer";
        public static readonly Amount: string = "Amount";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CreateNfsBankTransferEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static NfsBankTransferConfirmTransactionEvent = class {
        public static readonly ConfirmTransaction: string = "ConfirmTransaction";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CompleteNfsBankTransferEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Amount: string = "Amount";
        public static readonly BankName: string = "BankName";
    }

    public static NfsMomoTransferEvent = class {
        public static readonly NfsMomoTransfer: string = "NFSMomoTransfer";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CreateNfsMomoTransferEvent = class {
        public static readonly Date: string = "Date";
        public static readonly Amount: string = "Amount";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static NfsMomoTransferConfirmTransactionEvent = class {
        public static readonly ConfirmTransaction: string = "ConfirmTransaction";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CompleteNfsMomoTransferEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Amount: string = "Amount";
        public static readonly MomoName: string = "MomoName";
    }

    public static SelcomMomoTransferEvent = class {
        public static readonly SelcomMomoTransfer: string = "SelcomMomoTransfer";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CreateSelcomMomoTransferEvent = class {
        public static readonly Date: string = "Date";
        public static readonly Amount: string = "Amount";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static SelcomMomoTransferConfirmTransactionEvent = class {
        public static readonly ConfirmTransaction: string = "ConfirmTransaction";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CompleteSelcomMomoTransferEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Amount: string = "Amount";
        public static readonly MomoName: string = "MomoName";
    }
}

export class RequestsEventProperties {
    public static TapRequestEvent = class {
        public static readonly Requests: string = "Requests";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static SearchRequestEvent = class {
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Date: string = "Date";
        public static readonly StartDate: string = "StartDate";
        public static readonly EndDate: string = "EndDate";
    }

    public static RequestTransferEvent = class {
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Amount: string = "Amount";
    }

    public static ApproveRequestEvent = class {
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly Approved: string = "Approved";
        public static readonly Declined: string = "Declined";
        public static readonly Canceled: string = "Canceled";
    }
}

export class ApiKeyEventProperties {
    public static TapApiKeyEvent = class {
        public static readonly ApiKey: string = "APIKey";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static CreateApiEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
        public static readonly ApiKeyName: string = "APIKeyName";
        public static readonly Permissions: string = "Permissions";
    }

    public static RevokingApiEvent = class {
        public static readonly Date: string = "Date";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }

    public static UpdateApiEvent = class {
        public static readonly Date: string = "Date";
        public static readonly Permissions: string = "Permissions";
        public static readonly CustomerGuid: string = "CustomerGuid";
    }
}