import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration } from "app/app.constants";
import { IFTProviderView, IFTVirtualAccountMappingDto } from "app/models/ift";
import { Observable, throwError } from "rxjs";

@Injectable()
export class IftService {
    constructor(
        private http: HttpClient
      ) { }
    
    public GetIFTLookUp(): Observable<IFTProviderView>{
        return this.http.get(Configuration.IFTLookUp)
        .map(data => new IFTProviderView(data))
        .catch(err => { return throwError(err) });
    }
    
    public MapVirtualAccount(): Observable<IFTVirtualAccountMappingDto>{
        return this.http.post(Configuration.MapVirtualAccount, null)
        .map(data => new IFTVirtualAccountMappingDto(data))
        .catch(err => {return throwError(err)}); 
    }
}