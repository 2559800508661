import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
 
import { Configuration } from 'app/app.constants';
import { BankTransferAccount, BankTransferRequest, OtherBankDetail, WithdrawRequest, WithdrawRequestList } from 'app/models/withdraw';
import { BusinessService } from './business.service';
import { UserService } from './user.service';

@Injectable()
export class WithdrawService {

  constructor(private http: HttpClient, private businessService: BusinessService, private userService: UserService) { }

  createWithDrawRequest(amount: string, message: string, otp: string): Observable<any> {
    let command = {
      BusinessAccountGuid: this.businessService.GetAccountInLocalStorage().AccountId,
      Email: this.userService.getUserFromLocalStorage().Email,
      Amount: amount,
      Message: message,
      Otp: otp
    };

    return this.http.post(Configuration.CreateWithdrawRequest, command)
      .map(data => new WithdrawRequest(data))
      .catch(err => throwError(err));
  }

  createOtherBankWithDrawRequest(amount: string, message: string, otp: string, otherBankAccountGuid: string): Observable<any> {
    let command = {
      BusinessAccountGuid: this.businessService.GetAccountInLocalStorage().AccountId,
      Email: this.userService.getUserFromLocalStorage().Email,
      Amount: amount,
      Message: message,
      Otp: otp,
      OtherBankAccountGuid: otherBankAccountGuid
    };

    return this.http.post(Configuration.CreateOtherBankWithdrawRequest, command)
      .map(data => new WithdrawRequest(data))
      .catch(err => throwError(err));
  }
  getWithdrawRequests(startDate: string, endDate: string, pageNumber: number, pageSize: number): Observable<WithdrawRequestList> {
    let businessAccountGuid = this.businessService.GetAccountInLocalStorage().AccountId;
    var command = {
      businessAccountGuid: businessAccountGuid,
      startDate: startDate,
      endDate: endDate,
      page: pageNumber,
      pageSize: pageSize
    }
    return this.http.post(Configuration.GetWithdrawList, command)
      .map(data => {return new WithdrawRequestList(data)})
      .catch(error => throwError(error));
  }

  private mapToObjArray(list: any): Array<WithdrawRequest> {
    let array = list as Array<any>
    return array.map(a => new WithdrawRequest(a));
  }

  getBankListForTransfer(): Observable<Array<OtherBankDetail>> {
    return this.http.get(Configuration.ApiRoot + '/api/BankAccount/GetOtherBanks')
      .map(data => this.mapToObjBankListArray(data))
      .catch(error => throwError(error));
  }

  private mapToObjBankListArray(list: any): Array<OtherBankDetail> {
    let array = list as Array<any>
    return array.map(a => new OtherBankDetail(a));
  }

  getBankAccountsForTransfer(): Observable<Array<BankTransferAccount>> {
    return this.http.get(Configuration.ApiRoot + '/api/BankAccount/GetOtherBankAccounts')
      .map(data => this.mapToObjAccountsArray(data))
      .catch(error => throwError(error));
  }

  private mapToObjAccountsArray(list: any): Array<BankTransferAccount> {
    let array = list as Array<any>
    return array.map(a => new BankTransferAccount(a));
  }

  addOtherBankAccount(bankId: string, accountName: string, accountNumber: string, otp:string): Observable<any> {
    let command = {
      BankId: bankId,
      AccountName: accountName,
      AccountNumber: accountNumber,
      Otp: otp
    };

    return this.http.post(Configuration.ApiRoot + '/api/BankAccount', command)
      .catch(err => { return throwError(err) });
  }

  checkIfExistingRequest():Observable<object>{
    return this.http.get(`${Configuration.CheckWithdrawRequestPending}`)
          .catch(error => throwError(error));
  }

}
