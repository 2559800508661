import {formatDate} from '@angular/common';

export class PermissionGroup {
    PermissionGroupName: string;
    PermissionGroupGuid: string;
    PermissionGroupDisplayName: string;
    Checked: boolean;

    public constructor(data: any = {}) {
        this.PermissionGroupGuid = data.permissionGroupGuid || '';
        this.PermissionGroupName = data.permissionGroupName || '';
        this.PermissionGroupDisplayName = data.permissionGroupDisplayName || '';
        this.Checked = data.Checked || false;
    }

    public static ParseArray(data: any): PermissionGroup[] {
        var list = new Array<PermissionGroup>();
        data.forEach(item => {
            list.push(new PermissionGroup(item));
        });
        return list;
    }

    public static DeepClonePermissionGroups(groups: PermissionGroup[]): PermissionGroup[] {
        return groups.map(x => Object.assign(new PermissionGroup(), JSON.parse(JSON.stringify(x))));
    }

    public static SortGroups(permissionGroups: PermissionGroup[]) : void {
        permissionGroups = permissionGroups.sort((x, y) => {
            if(x.PermissionGroupDisplayName < y.PermissionGroupDisplayName) {
                return -1;
            }
            else
                return 1;
        });
    }
}

export const MaskedApiKeySecret = "***********************";

export class ApiKey {
    ApiKeySecret: string;
    ApiKeyName: string;
    ApiKeyGuid: string;
    IsActive: boolean;
    CreateDate: string;
    RevokeDate: string;
    PermissionGroups: PermissionGroup[];

    public constructor(data: any = {}, permissionGroups: PermissionGroup[] = [], checkAll: boolean = false) {
        this.ApiKeySecret = data.apiKey || MaskedApiKeySecret;
        this.ApiKeyName = data.apiKeyName || '';
        this.ApiKeyGuid = data.apiKeyGuid || '';
        this.IsActive = data.isActive || false;
        this.CreateDate = data['createDate'] ? formatDate(data['createDate'].replace('Z',''),'medium', 'en-US') : '';
        this.RevokeDate = data['revokeDate'] ? formatDate(data['revokeDate'].replace('Z',''),'medium', 'en-US') : '';
        this.PermissionGroups = new Array<PermissionGroup>();

        if(data.permissionGroups) {
            data.permissionGroups.forEach(item => {
                this.PermissionGroups.push(new PermissionGroup(item));
            });
        }

        if(checkAll) 
            this.PermissionGroups.forEach(group => group.Checked = true);

        // merge permission groups
        this.PermissionGroups = this.PermissionGroups.concat(
            PermissionGroup.DeepClonePermissionGroups(
                permissionGroups.filter((item) => this.PermissionGroups
                    .find(x => x.PermissionGroupGuid == item.PermissionGroupGuid) == null)));

        PermissionGroup.SortGroups(this.PermissionGroups);
    }

    public GetPermissionGroupNames(): string {
        var concatenatedNames = this.PermissionGroups.filter(x => x.Checked).map(x => x.PermissionGroupDisplayName).join(', ');
        return (concatenatedNames.length == 0 ? 'None' : concatenatedNames);
    }

    public GetCheckedPermissionGuids(): string[] {
        return this.PermissionGroups.filter(x => x.Checked).map(x => x.PermissionGroupGuid);
    }

    public DeepClone(): ApiKey {
        return Object.assign(new ApiKey(), JSON.parse(JSON.stringify(this)));
    }
}

export class ApiKeyList {
    public List: Array<ApiKey>;
    public Total: number;

    public constructor(data: any = {}, permissionGroups: PermissionGroup[] = [], checkAll: boolean = false) {
        this.List = new Array<ApiKey>();
        this.Total = data.total || 0;

        if(data.data) {
            data.data.forEach(item => 
                this.List.push(new ApiKey(item, permissionGroups, checkAll))
            );
        }
    }
}