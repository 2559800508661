import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pay-rw',
  templateUrl: './pay-rw.component.html',
  styleUrls: ['./pay-rw.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayRwComponent implements OnInit {

  instructionsArray: string[];
  constructor(private dialogRef: MatDialogRef<PayRwComponent>) { }

  ngOnInit(): void {
    this.getInstructions();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getInstructions() {
    this.instructionsArray = [
      'Dial *508# or open Pay.rw app',
      'Select SPENN',
      'Enter the receiver\'s SPENN number',
      'Enter amount to send',
      'Select payment method where applicable',
      'Confirm payment (momo or card) following instructions'
    ]
  }


}
