import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Account } from 'app/models/business';
import { User } from 'app/models/user';
import { BusinessService } from 'app/services/business.service';
import { UserService } from 'app/services/user.service';
import { Subscription } from 'rxjs';
import { NotificationService } from 'app/services/notification.service';
import { WithdrawService } from 'app/services/withdraw.service';
import { BankTransferAccount, WithdrawRequest } from 'app/models/withdraw';

@Component({
  templateUrl: './create-withdraw.component.html',
  styleUrls: ['./create-withdraw.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateWithdrawComponent implements OnInit, OnDestroy {

  title: string = 'Request for Transfer';
  stage: RequestStage = RequestStage.Create;
  account: Account;
  user: User;
  busy: Subscription = null;
  errorMsg: string = null;
  withdraw: WithdrawRequest;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  maskedPhoneNumberAndEmail:string
  amount: string = '';
  message: string = '';
  otp: string = '';
  totalAmount:number;

  constructor(private dialogRef: MatDialogRef<CreateWithdrawComponent>, 
    private businessService: BusinessService, 
    private userService: UserService, 
    private withdrawService: WithdrawService,
    private notif: NotificationService,
    @Inject(MAT_DIALOG_DATA) public accountDetails: BankTransferAccount) { }

  ngOnInit(): void {
    this.busy = this.businessService.GetCurrentBalance().subscribe(acc => {
      this.account = acc;      
    },
    err=>{
      console.log(err);
      
    });    
    this.user = this.userService.getUserFromLocalStorage();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      if(s) {
        s.unsubscribe();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(this.withdraw);
  }

  onCreateContinue() {
    if(this.validateAmountAndMessage()){
      this.busy = this.withdrawService.checkIfExistingRequest()
                    .subscribe(
                      response=>{
                        this.stage = RequestStage.Confirm;
                        this.title = 'Confirm Transaction'; 
                        this.totalAmount = parseFloat(this.amount) + parseFloat(this.accountDetails.TransferFee.toString()); 
                      },
                      error=>{
                        console.log(error);
                      }
                    )};
  }

  onConfirmContinue() {
    if(this.totalAmount > this.account.Balance) {
      this.errorMsg = 'Amount cannot be greater than current balance';
      return false;
    }
    this.sendOtp();
    this.stage = RequestStage.Otp;
    this.title = 'Confirm Transfer';    
  }

  onOtpConfirm() {
    if(!this.validateOtp())
    return;

    if(this.accountDetails)
    {
      this.busy = this.withdrawService.createOtherBankWithDrawRequest(this.amount, this.message, this.otp, this.accountDetails.OtherBankAccountGuid).subscribe(
        sucess => {
          this.withdraw = sucess;
          this.stage = RequestStage.Final;
          this.title = 'Transfer Request Created'
        },
        error => {
          //this.notif.error('Failed to create withdraw request');
          console.log('Failed: ' + error);
        }
      )
    }
    else{
      this.busy = this.withdrawService.createWithDrawRequest(this.amount, this.message, this.otp).subscribe(
        sucess => {
          this.withdraw = sucess;
          this.stage = RequestStage.Final;
          this.title = 'Withdraw request created'
        },
        error => {
          this.notif.error('Failed to create withdraw request');
          console.log('Failed: ' + error);
        }
      )
    }

  }

  validateOtp(): boolean {
    if(!this.otp) {
      this.errorMsg = 'Please provide the otp';
      return false;
    }
    this.errorMsg = null;
    return true;
  }

  validateAmountAndMessage(): boolean {
    let amountNumber = +this.amount.trim();
    if(isNaN(amountNumber) || amountNumber <= 0){
      this.errorMsg = 'Please provide a valid number for amount';
      return false;
    }
    if(amountNumber > this.account.Balance) {
      this.errorMsg = 'Amount cannot be greater than current balance';
      return false;
    }

    if(this.message == ''){
      this.errorMsg = 'Please provide a message';
      return false;
    }

    this.amount = amountNumber.toString();
    this.errorMsg = null;
    return true;
  }

  getMaskedPhoneNumber() : string {    
    return this.maskedPhoneNumberAndEmail;
  }

  sendOtp() {    
    this.busy = this.userService.sendOtp().subscribe(
      result=>{        
        this.maskedPhoneNumberAndEmail = Object.values(result)[1].toString();   
      }, 
      error => {
        this.notif.error('Could not send OTP');
        console.log(error);
      }, () => {
        this.otp = '';
        this.busy = null;
      });
  }

  isCreate(): boolean {
    return this.stage == RequestStage.Create;
  }
  isConfirm(): boolean {
    return this.stage == RequestStage.Confirm;
  }
  isOtp(): boolean {
    return this.stage == RequestStage.Otp;
  }
  isFinal(): boolean {
    return this.stage == RequestStage.Final;
  }


}

export enum RequestStage {
  Create,
  Confirm,
  Otp,
  Final
}