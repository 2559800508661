<div class='bb-toolbar-wrap' [ngClass]="{'dialog-toolbar': dialogToolbar, 'z-index-toolbar' : !dialogToolbar}">
    <mat-toolbar class="bb-toolbar">
        <div class="bb-toolbar-container" fxLayout fxLayoutAlign="space-between center" fxFlex.gt-lg="80" fxFlex.lg="80"
            fxFlex.md="72" fxFlex.sm="60" fxFlex.xs="50">
            <div fxFlex fxLayoutAlign="start center">
                <button fxHide fxShow.lt-md *ngIf="menu" mat-icon-button (click)="toggleRightSidenav()">
                    <mat-icon class="md-24">menu</mat-icon>
                </button>
                <span class="bb-bar-title">{{title}}</span>
            </div>
            <button [matMenuTriggerFor]="helpMenu" class="button help" fxLayoutAlign="space-between center">
                <img src="assets/images/help.png">
                <span>Help</span>
            </button>
            <button fxLayoutAlign="center" (click)="qrCodeClick()">
                <img src="assets/images/qr_code_scanner_FILL0_wght400_GRAD0_opsz48.svg" class="user-icon-img">
            </button>
            <mat-menu #helpMenu>
                <div class="help-menu">
                    <span>RESOURCES</span>
                    <a class="text-spenn" (click)="topUpClick()">How to Top up</a>
                </div>
            </mat-menu>
        </div>
    </mat-toolbar>
</div>