import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { NotificationService } from 'app/services/notification.service';
import { TopupService } from '../../topup.service';

@Component({
  templateUrl: './spenn-agent.component.html',
  styleUrls: ['./spenn-agent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpennAgentComponent implements OnInit, OnDestroy {

  busy: Subscription = null;

  constructor(private dialogRef: MatDialogRef<SpennAgentComponent>, 
    private topupService: TopupService,
    private notif: NotificationService) { }
    
  ngOnDestroy(): void {
    if(this.busy) {
      this.busy.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getQrCodeImage();
  }

  imageBase64: any;

  closeDialog() {
    this.dialogRef.close();
  }

  getQrCodeImage() {
    this.busy = this.topupService.getQrCodeImageBase64()
    .subscribe((imageData: any) => { 
      this.imageBase64 = imageData; 
    }, error => {
      this.notif.error('Could not get QR Code. Please try again');
    });
  }



}
